import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { useEffect, useState } from 'react';

export function useKeyboard() {
  const [isOpen, setIsOpen] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    if (!Capacitor.isPluginAvailable('Keyboard')) {
      return;
    }

    const showCallback = (ki: KeyboardInfo) => {
      const keyboardHeight = ki.keyboardHeight;
      setIsOpen(true);
      setKeyboardHeight(keyboardHeight);
    };

    const hideCallback = () => {
      setIsOpen(false);
      setKeyboardHeight(0);
    };

    Keyboard.addListener('keyboardDidShow', showCallback);
    Keyboard.addListener('keyboardDidHide', hideCallback);

    return () => {
      Keyboard.removeAllListeners();
    };
  }, [setIsOpen, setKeyboardHeight]);

  return {
    isOpen,
    keyboardHeight,
  };
}

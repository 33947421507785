import { IonSpinner } from '@ionic/react';
import { compile } from 'path-to-regexp';
import React, { PropsWithChildren, useContext } from 'react';
import { useParams } from 'react-router';

import Layout from '../pages/Layout';
import { AuthContext } from '../providers/AuthProvider';
import Redirect from './Redirect';

import './AuthComponent.css';

interface AuthComponentProperties extends PropsWithChildren {
  /** Whether the auth result should be interpreted in reverse, e.g. when the user is authenticated, treat it like he's not */
  reverse?: boolean;
  /** Whether to show a loading state when the auth state is undefined */
  showLoadingText?: boolean;
  /** Path to where the user should be redirected when he's not authenticated */
  redirectTo?: string;
}

const AuthComponent: React.FC<AuthComponentProperties> = ({
  reverse,
  children,
  showLoadingText,
  redirectTo,
}) => {
  const params = useParams();
  const { isAuthenticated } = useContext(AuthContext);

  let loggedIn = isAuthenticated;

  if (reverse && loggedIn !== undefined) {
    loggedIn = !loggedIn;
  }

  if (loggedIn === undefined && showLoadingText) {
    return (
      <Layout skipAuth={true}>
        <div className='auth-loading-container'>
          <p className='mb-3'>Lädt...</p>
          <IonSpinner />
        </div>
      </Layout>
    );
  } else if (loggedIn === true) {
    return <>{children}</>;
  } else if (loggedIn === false && redirectTo) {
    const pathTo = compile(redirectTo, { encode: encodeURIComponent });

    return <Redirect to={pathTo(params)} />;
  }

  return <></>;
};

export default AuthComponent;

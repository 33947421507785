import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { ExamBookingInfo, RequestTime } from '../interfaces/exam-booking-info';

export const getExamBookingInfo = async (identifier: string) => {
  const res = await makeApiRequest<ExamBookingInfo>('GET', `v1/courses/${identifier}/exam-booking`);

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const addExamBooking = async (
  identifier: string,
  exam_place_id: number,
  exam_date: Date,
  exam_time: RequestTime,
  temp_license_wanted?: boolean
) => {
  const res = await makeApiRequest('POST', `v1/courses/${identifier}/exam-booking`, undefined, {
    exam_place_id,
    exam_date,
    exam_time,
    temp_license_wanted,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

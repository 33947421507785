export enum TripAvailability {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export interface Trip {
  id: number;
  // label: string;
  pdf_path?: string;
  // start_harbour: string;
  start_date: string;
  end_date: string;
  // price_ek: number;
  // price_dk: number;
  // miles: number;
  availability: TripAvailability;
  trip_request: boolean;
}

export interface TripRegion {
  name: string;
  picture_link?: string;
  starting_price: number;
  trips: Trip[];
}

export interface Trips {
  mt_trips: TripRegion[];
  pt_trips: TripRegion[];
  mtpt_trips: TripRegion[];
}

import { createEvent } from '../api/account/account';
import { CourseType } from '../api/interfaces/course';

export interface CourseCardLink {
  url: string;
  courseIdentifier?: CourseType;
  type?: 'internal' | 'upsell' | 'upgrade';
}

export const handleCourseLinkClick = (link: CourseCardLink) => {
  switch (link.type) {
    case 'upsell':
      createEvent('upsell_clicked', { courseIdentifier: link.courseIdentifier });
      break;
    case 'upgrade':
      createEvent('extension_clicked', { courseIdentifier: link.courseIdentifier });
      break;
    default:
      break;
  }
};

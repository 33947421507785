import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import {
  NavigationTrainingExercise,
  NavigationTrainingOverview,
} from '../interfaces/navigation-training';

export const getNavigationTrainingExercises = async (identifier: string) => {
  const res = await makeApiRequest<NavigationTrainingOverview[]>(
    'GET',
    `v1/courses/${identifier}/navigation-training`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getNavigationTrainings = async (identifier: string, exerciseId: number) => {
  const res = await makeApiRequest<NavigationTrainingExercise>(
    'GET',
    `v1/courses/${identifier}/navigation-training/${exerciseId}`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { CourseType } from '../../../../api/interfaces/course';
import B1Button from '../../../../components/buttons/B1Button';
import CustomLink from '../../../../components/custom-link/CustomLink';
import Layout from '../../../Layout';

import './ExamBookingUBI.scss';

const ExamBookingUBI: React.FC = () => {
  return (
    <Layout
      contentClassName='exam-booking-ubi'
      backButton='/course/ubi'
      currentCourseType={CourseType.UBI}
    >
      <div className='exam-booking-container'>
        <h1>UBI-Prüfung buchen</h1>

        <div className='simple-card card-light-gray mb-1'>
          <h4 className='mb-4'>Anmeldung zur Prüfung</h4>
          <p className='mb-1 brand-blue'>
            <strong>Mindestens 14 Tage vor dem Prüfungstermin</strong> musst Du Dich für die Prüfung
            anmelden.
          </p>
          <p className='mb-3'>Dabei ist folgendes einzureichen:</p>
          <div className='exam-info-card-body'>
            <ul className='mb-4'>
              <li>
                Antrag auf Zulassung UBI
                <div className='download-applications-buttons'>
                  <B1Button
                    className='button-sm mr-2'
                    href='https://www.sportbootfuehrerscheine.org/downloads/zulassungsantraege/antrag-zulassung-ubi/'
                    downloadAndOpen
                  >
                    PDF beim DSV
                  </B1Button>
                  <B1Button
                    className='button-sm'
                    href='https://www.dmyv.de/fileadmin/content/pdf/downloads/fuehrerschein/ubi/Antrag_auf_Zulassung_zur_Pruefung_UBI_2022_3.pdf'
                    downloadAndOpen
                  >
                    PDF beim DMYV
                  </B1Button>
                </div>
              </li>
              <li>
                Reisepass oder Personalausweis in Kopie (
                <span className='brand-blue'>Original in der Prüfung vorlegen!</span>)
              </li>
              <li>
                ggf. bereits vorhandene Funkscheine in Kopie (
                <span className='brand-blue'>Original in der Prüfung vorlegen!</span>)
              </li>
              <li>Lichtbild (35×45 mm)</li>
              <li>Bei Minderjährigen: Einverständniserklärung eines Erziehungsberechtigten</li>
              <li>Gebühren: Rund 127,88 €, abhängig vom Prüfungsausschuss</li>
            </ul>
          </div>
        </div>

        <div className='simple-card card-light-gray mb-5'>
          <h4 className='mb-4'>Standort und Termin der Prüfung</h4>
          <div className='exam-info-card-body'>
            <h5 className='mb-3'>DSV-Prüfung:</h5>
            <B1Button
              className='button-block mb-3'
              href='https://www.sportbootfuehrerscheine.org/pruefungen/pruefungstermine/'
              external
            >
              Prüfung beim DSV buchen
            </B1Button>
            <p className='mb-3'>
              Dort wählst Du in der Leiste als Befähigungsnachweis „UBI“ aus und gibst Deine
              Postleitzahl ein. Du findest nun eine Liste der Termine und kannst auf dem Button
              „Onlineantrag“ die Buchung durchführen. Trotz Online-Buchung müssen die oben stehenden
              Unterlagen verschickt werden.
            </p>
            <h5 className='mb-3'>DMYV-Prüfung:</h5>
            <p>
              Das Buchen der Prüfung beim DMYV ist etwas komplizierter. Wähle{' '}
              <CustomLink href='https://www.dmyv.de/fuehrerschein/-funk/pruefungen/pruefungsausschuesse'>
                hier
              </CustomLink>{' '}
              einen passenden Prüfungsausschuss aus, gehe dann auf dessen Website und nutze dort den
              jeweiligen Button, um die Termine anzeigen zu lassen. Teilweise kannst Du Dich hier
              auch online anmelden, teilweise geht dies leider nicht. Ein Anruf beim jeweiligen
              Prüfungsausschuss ist vor der Prüfung immer empfehlenswert.
            </p>
          </div>
        </div>

        <h3 className='mb-4'>UBI-Prüfung FAQ</h3>

        <Accordion className='b1-accordion' defaultActiveKey={undefined}>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Wie läuft die Prüfung ab?</Accordion.Header>
            <Accordion.Body>
              <p className='mb-3'>
                Die UBI-Prüfung besteht aus einem theoretischen, einem mündlichen und einem
                praktischen Teil.
              </p>
              <p className='mb-5'>
                In der Theorie muss zuerst ein Fragebogen bearbeitet werden. Hier muss man 30
                beantworten, wobei immer 1 Antwort korrekt ist und 6 Fehler gemacht werden dürfen.
                Der Fragenkatalog besteht aus insgesamt 130 UBI-Prüfungsfragen. Alle Fragen und auch
                die Prüfungsbogen findest Du bei uns unter „UBI Prüfungsvorbereitung“.
              </p>

              <h5 className='mb-3'>Die mündliche Prüfung besteht aus zwei Teilen:</h5>
              <ol className='mb-5'>
                <li>
                  <p className='mb-3'>Textaufnahme</p>
                  <p>
                    Bei der <strong>Textaufnahme</strong> liest der Prüfer einen Text vor, den Du
                    mitschreiben musst. Für Schiffsnamen, Ortsnamen und komplizierte Wörter benutzt
                    der Prüfer die internationale Buchstabiertafel, um die Wörter zu buchstabieren.
                  </p>
                </li>
                <li>
                  <p className='mb-3'>Textabgabe</p>
                  <p>
                    Bei der <strong>Textabgabe</strong> liest Du dem Prüfer einen Text vor, der Dir
                    vorgelegt wird. Die Wörter, die Du anhand der internationalen Buchstabiertafel
                    buchstabieren musst, sind hier im Text markiert.
                  </p>
                </li>
              </ol>

              <h5 className='mb-3'>Praxisprüfung</h5>
              <p className='mb-3'>
                Bei der Praxisprüfung musst Du praktische Aufgaben am Funkgerät lösen sowie das
                Sprechfunkgerät korrekt bedienen können. Diese praktische Prüfung ist aufgeteilt in
                5 Bereiche:
              </p>
              <ul className='mb-5'>
                <li>Grundeinstellung einer Sprechfunkstelle</li>
                <li>Gerätekunde</li>
                <li>
                  Praktische Funkabwicklung in den Rängen Notverkehr, Dringlichkeitsverkehr und
                  Notverkehr
                </li>
                <li>Praktische Funkabwicklung im Routineverkehr</li>
                <li>Sonstige Fertigkeiten</li>
              </ul>

              <p className='mb-3'>
                Inhaber des SRC müssen nur eine vereinfachte Ergänzungsprüfung ablegen – auch wenn
                das SRC am selben Tag erworben wird. In theoretischen Prüfung gibt es einen
                verkürzten Fragebogen (10 Fragen aus einem Fragenkatalog von 79 Fragen). Hier darst
                Du max. 2 Fehler machen.
              </p>

              <p>
                Der praktische Teil beschränkt sich auf zwei bis drei Übungen. Das Übermitteln und
                die Aufnahme einer Meldung sowie das Bedienen des Sprechfunkgerätes entfallen hier.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Woraus besteht die Praxisprüfung genau?</Accordion.Header>
            <Accordion.Body>
              <p className='mb-5'>
                Die Praxisprüfung besteht aus 2 großen Bereichen, den Pflichtaufgaben und den
                sonstigen Fertigkeiten.
              </p>

              <h5 className='mb-3'>I: Pflichtaufgaben</h5>
              <p className='mb-3'>Die Pflichtaufgaben sind wiederum untergliedert in 4 Teile:</p>
              <ul className='mb-5'>
                <li>
                  <p className='mb-3'>Grundeinstellung einer Sprechfunkstelle</p>
                  <ul>
                    <li>Binnenfunkmodus einstellen (bei Kombianlage)</li>
                    <li>Anrufkanal wählen (Funkbenutzungspflicht)</li>
                    <li>Lautstärke und Squelch richtig einstellen</li>
                  </ul>
                </li>
                <li>
                  <p className='mb-3'>Gerätekunde</p>
                  <ul>
                    <li>Erklärung der Distress-Taste</li>
                    <li>Erklärung von SCAN bzw. Kanalsuchlauf</li>
                    <li>Erklärung von DW (Dual Watch) bzw. Zweikanalüberwachung</li>
                    <li>Erklärung von HI/LO bzw. Sendeleistungsumschaltung</li>
                  </ul>
                </li>
                <li>
                  <p className='mb-3'>Praktische Funkabwicklung Rang</p>
                  <ul>
                    <li>
                      Abgabe <strong>einer</strong> Not-, oder Dringlichkeits-, oder
                      Sicherheitsmeldung an NIF (mit korrekter Kanal- auswahl aus Handbuch) und bei
                      Bedarf vorher im Verkehrskreis Schiff-Schiff.
                    </li>
                  </ul>
                </li>
                <li>
                  <p className='mb-3'>Praktische Funkabwicklung Routine</p>
                  <ul>
                    <li>
                      Abgabe <strong>einer</strong> Routinemeldung an RVZ oder Schleuse oder Hafen
                      oder Schiffsfunkstelle (mit korrekter Kanalauswahl).
                    </li>
                  </ul>
                </li>
              </ul>

              <h5 className='mb-3'>II: Sonstige Fertigkeiten</h5>
              <p className='mb-3'>
                Neben den Pflichtaufgaben werden auch zwei sonstige Fertigkeiten abgefragt. Diese
                können sein:
              </p>
              <ul className='mb-5'>
                <li>Weiterleiten einer Notmeldung / beobachteten Notlage (Mayday Relay)</li>
                <li>Notverkehr beenden</li>
                <li>Aufhebung einer Dringlichkeits- oder Sicherheitsmeldung</li>
                <li>Bestätigung einer empfangenen Notmeldung</li>
                <li>Funkstille gebieten</li>
              </ul>

              <p className='mb-3'>Das Prüfungsprotokoll zur Praxisprüfung findest Du hier:</p>
              <B1Button
                href='https://cdn.bootsschule1.app/courses/UBI/Pruefungsbuchung/Praxispruefung_infos.pdf'
                downloadAndOpen
              >
                Prüfungsprotokoll
              </B1Button>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>Wie lange dauert die Prüfung?</Accordion.Header>
            <Accordion.Body>
              <ul className='mb-4'>
                <li>
                  Für die Theoriefragen hast Du 60 Minuten Zeit (Ergänzungsprüfung: 30 Minuten)
                </li>
                <li>Die mündliche Prüfung (Textaufnahme und Textabgabe) ist 2x 5 Minuten lang</li>
                <li>Die praktische Prüfung geht insgesamt 15 Minuten.</li>
              </ul>

              <p>
                Reserviere Dir jedoch am besten den ganzen Prüfungstag. Du wirst einen Großteil des
                Tages mit Warten verbringen, weil außer Dir noch weitere Kandidaten die Prüfung
                ablegen.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='3'>
            <Accordion.Header>Wann falle ich durch?</Accordion.Header>
            <Accordion.Body>
              <ul className='mb-5'>
                <li>
                  Theorieprüfung: Die komplette theoretische Prüfung umfasst{' '}
                  <strong>22 Prüfungsfragen</strong>, von denen Du{' '}
                  <strong>17 korrekt beantworten</strong> musst, damit die Prüfung als bestanden
                  gilt. Bei der Ergänzungsprüfung musst Du 8 der 10 Prüfungsfragen korrekt
                  beantworten.
                </li>
                <li>
                  Bei der mündlichen Prüfung dürfen keine Fehler bei den buchstabierten Wörtern
                  gemacht werden.
                </li>
                <li>
                  <p className='mb-3'>
                    Die Praxisprüfung ist aufgeteilt in die folgenden 5 Bereiche:
                  </p>
                  <ul>
                    <li>Grundeinstellung einer Sprechfunkstelle</li>
                    <li>Gerätekunde</li>
                    <li>
                      Praktische Funkabwicklung in den Rängen Notverkehr, Dringlichkeitsverkehr und
                      Notverkehr
                    </li>
                    <li>Praktische Funkabwicklung im Routineverkehr</li>
                    <li>Sonstige Fertigkeiten</li>
                  </ul>
                </li>
              </ul>
              <p>
                Dabei bilden die sonstigen Fertigkeiten einen Block und die anderen 4 Bereiche einen
                anderen Block, die sogenannten Pflichtaufgaben. Bei den sonstigen Fertigkeiten
                werden 2 Aufgaben gestellt, wovon mindestens eine richtig gestellt werden muss. Bei
                den Pflichtaufgaben müssen alle gestellten Aufgaben spätestens mit dem zweiten
                Versuch korrekt gelöst werden.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='4'>
            <Accordion.Header>Welche Kosten kommen noch auf mich zu?</Accordion.Header>
            <Accordion.Body>
              <p>
                Auf Dich kommt nur noch die Prüfungsgebühr zu, diese beträgt für das UBI 108,71 €.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='5'>
            <Accordion.Header>
              Was passiert, wenn ich die Prüfung wiederholen muss?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Wenn Du nur den praktischen Teil oder nur den theoretischen Teil der Prüfung nicht
                bestehst, musst Du{' '}
                <strong>den nicht bestandenen Teil innerhalb von 6 Monaten</strong> wiederholen.
                Versäumst Du diese Frist, muss die gesamte Prüfung erneut abgelegt werden.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='6'>
            <Accordion.Header>Kann die Prüfung auch online absolviert werden?</Accordion.Header>
            <Accordion.Body>
              <p>
                Nein, die UBI-Prüfung ist ebenso wie die Prüfung zum Sportbootführerschein eine
                amtliche Prüfung, und diese wird unter Aufsicht von amtlich bestellten Prüfern
                abgelegt.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='7'>
            <Accordion.Header>
              Wie lange dauert es, bis ich das Funkzeugnis in der Hand halte?
            </Accordion.Header>
            <Accordion.Body>
              <p>Die Funkzeugnisse werden nach der bestandenen Prüfung ausgehändigt.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </Layout>
  );
};

export default ExamBookingUBI;

import { QuestionEvaluationStatus, QuestionType, QuizQuestion } from '../api/interfaces/quiz';
import { SmartmodeQuestion, isSmartmodeQuestion } from '../api/interfaces/smartmode';
import { isAnswerCorrect } from '../utils/quiz-util';

export interface SmartmodeQuizQuestion extends QuizQuestion, SmartmodeQuestion {}

type Quiz<T> = {
  questions: T[];
  loaded: boolean;
};

type QuizAction<T> =
  | { type: 'SET_QUESTIONS'; questions: T[] }
  | { type: 'SET_CHECKED_ANSWER'; questionIndex: number; checkedAnswer: number }
  | {
      type: 'SET_ANSWER_EVALUATION_STATUS';
      questionIndex: number;
      status: QuestionEvaluationStatus;
    }
  | { type: 'SET_TEXT_ANSWER'; questionIndex: number; textAnswer: string }
  | {
      type: 'SET_TEXT_ANSWER_RESPONSE';
      questionIndex: number;
      userPoints: number;
      totalPoints: number;
      responseText: string;
      responsePictureLink?: string;
    }
  | { type: 'RESET_ANSWERS' }
  | { type: 'RESET_WRONG_ANSWERS' }
  | { type: 'SHUFFLE_ANSWERS' }

  // Smartmode-Quiz-Actions
  | { type: 'UPDATE_QUESTION'; questionIndex: number; correct: boolean }
  | { type: 'MOVE_QUESTION'; questionIndex: number; correct: boolean };

export const QUIZ_REDUCER = <T extends S, S extends QuizQuestion>(
  state: Quiz<S>,
  action: QuizAction<T>
): Quiz<S> => {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return { ...state, questions: action.questions, loaded: true };
    case 'SET_CHECKED_ANSWER':
      return {
        ...state,
        questions: state.questions.map((question, index) => {
          if (index === action.questionIndex) {
            if (question.type === QuestionType.SC) {
              return {
                ...question,
                checkedAnswers: [action.checkedAnswer],
                evaluationStatus: 'not-evaluated',
              };
            } else if (question.type === QuestionType.MC) {
              return {
                ...question,
                checkedAnswers: question.checkedAnswers
                  ? question.checkedAnswers.includes(action.checkedAnswer)
                    ? question.checkedAnswers.filter((a) => a !== action.checkedAnswer)
                    : [...question.checkedAnswers, action.checkedAnswer]
                  : [action.checkedAnswer],
                evaluationStatus: 'not-evaluated',
              };
            }
          }
          return question;
        }),
      };
    case 'SET_ANSWER_EVALUATION_STATUS':
      return {
        ...state,
        questions: state.questions.map((question, index) => {
          if (index === action.questionIndex) {
            return { ...question, evaluationStatus: action.status };
          }
          return question;
        }),
      };
    case 'SET_TEXT_ANSWER':
      return {
        ...state,
        questions: state.questions.map((question, index) => {
          if (index === action.questionIndex) {
            return {
              ...question,
              textAnswerInput: action.textAnswer,
              evaluationStatus: 'not-evaluated',
            };
          }
          return question;
        }),
      };
    case 'SET_TEXT_ANSWER_RESPONSE':
      return {
        ...state,
        questions: state.questions.map((question, index) => {
          if (index === action.questionIndex) {
            return {
              ...question,
              textQuestionAnswer: {
                answer: action.responseText,
                picture_link: action.responsePictureLink,
                user_points: action.userPoints,
                total_points: action.totalPoints,
              },
              evaluationStatus: 'evaluated',
            };
          }
          return question;
        }),
      };
    case 'RESET_ANSWERS':
      return {
        ...state,
        questions: state.questions.map((question) => {
          return {
            ...question,
            checkedAnswers: undefined,
            textAnswerInput: undefined,
            textQuestionAnswer: undefined,
            evaluationStatus: 'not-evaluated',
          };
        }),
      };
    case 'RESET_WRONG_ANSWERS':
      return {
        ...state,
        questions: state.questions.map((question) => {
          if (isAnswerCorrect(question)) {
            return question;
          } else {
            return {
              ...question,
              checkedAnswers: undefined,
              textAnswerInput: undefined,
              textQuestionAnswer: undefined,
              evaluationStatus: 'not-evaluated',
            };
          }
        }),
      };
    case 'SHUFFLE_ANSWERS':
      return {
        ...state,
        questions: state.questions.map((question) => {
          const answers = [...(question.choice_answers ?? [])];

          if (question.checkedAnswers === undefined || question.checkedAnswers.length === 0) {
            answers.sort(() => Math.random() - 0.5);
          }

          return { ...question, choice_answers: answers };
        }),
      };

    // Smartmode-Quiz-Actions
    case 'UPDATE_QUESTION': {
      return {
        ...state,
        questions: [
          ...state.questions.map((question, index) => {
            if (isSmartmodeQuestion(question) && index === action.questionIndex) {
              return {
                ...question,
                level: action.correct ? question.level + 1 : 0,
              };
            }
            return question;
          }),
        ],
      };
    }
    case 'MOVE_QUESTION': {
      const questions = [...state.questions];
      const question = questions.splice(action.questionIndex, 1).map((q) => {
        const answers = [...(q.choice_answers ?? [])];

        q.checkedAnswers = undefined;
        q.textAnswerInput = undefined;
        q.textQuestionAnswer = undefined;
        q.evaluationStatus = 'not-evaluated';
        answers.sort(() => Math.random() - 0.5);

        return { ...q, choice_answers: answers };
      });

      if (action.correct) {
        if (isSmartmodeQuestion(question[0]) && question[0].level < 3) {
          questions.splice(questions.length, 0, ...question);
        }
      } else {
        questions.splice(action.questionIndex + 8, 0, ...question);
      }

      return {
        ...state,
        questions: questions,
      };
    }

    default:
      return state;
  }
};

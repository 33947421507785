import React from 'react';
import { useParams } from 'react-router';

import { CourseType } from '../../api/interfaces/course';
import Layout from '../Layout';
import RadioTextRecordings from './radio-text-recordings/RadioTextRecordings';
import RadioTextSubmissions from './radio-text-submissions/RadioTextSubmissions';
import RadioTexts from './radio-texts/RadioTexts';
import VocabularyTrainer from './vocabulary-trainer/VocabularyTrainer';

import './OralExamPractice.scss';

const OralExamPractice: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>();

  return (
    <Layout
      contentClassName='oral-exam-practice'
      backButton={`/course/${identifier}`}
      currentCourseType={identifier}
    >
      <div className='oral-exam-practice-container'>
        {identifier.toUpperCase() === CourseType.SRC && (
          <>
            <VocabularyTrainer identifier={identifier} />
            <RadioTexts identifier={identifier} />
          </>
        )}
        {identifier.toUpperCase() === CourseType.UBI && (
          <>
            <RadioTextRecordings identifier={identifier} />
            <RadioTextSubmissions identifier={identifier} />
          </>
        )}
      </div>
    </Layout>
  );
};

export default OralExamPractice;

import React from 'react';

import './ProgressBar.scss';

interface ProgressBarProps {
  className?: string;
  progress: number;
  showProgressNumber?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ className, progress, showProgressNumber }) => {
  return (
    <>
      <div className={'progressbar' + (className ? ' ' + className : '')}>
        <div className='progress' style={{ width: progress * 100 + '%' }} />
      </div>
      {showProgressNumber && (
        <p className='description progress-number'>{(progress * 100).toFixed(0)}%</p>
      )}
    </>
  );
};

export default ProgressBar;

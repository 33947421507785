import React, { Dispatch } from 'react';

import {
  RegistrationDataAction,
  RegistrationDataStateType,
} from '../reducers/registration-data-reducer';

export const initialRegistrationDataState: RegistrationDataStateType = {
  code: null,
  data: {
    code: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    street: '',
    house_nbr: '',
    zip: '',
    place: '',
    country_code: '',
    nationality_code: '',
    date_of_birth: null,
    birth_place: '',
    sex: null,
    phone_number: '',
    has_driver_license: false,
  },
};

export const RegistrationDataStateContext = React.createContext<RegistrationDataStateType>(
  initialRegistrationDataState
);
export const RegistrationDataDispatchContext = React.createContext<
  Dispatch<RegistrationDataAction>
>(() => null);

export const useRegistrationDataState = (): [
  RegistrationDataStateType,
  Dispatch<RegistrationDataAction>
] => {
  return [
    React.useContext(RegistrationDataStateContext),
    React.useContext(RegistrationDataDispatchContext),
  ];
};

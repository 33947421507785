import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Collapse from 'react-bootstrap/Collapse';

import Course, { Chapter } from '../../../api/interfaces/course';
import { getCourseIcon, getShortCourseName } from '../../../utils/course-util';
import Lesson from './Lesson';

import './Sidebar.scss';

interface SidebarProps {
  course?: Course;
  selectedChapter?: number;
  modal?: boolean;
  onChapterSelected: (chapter: Chapter) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ course, selectedChapter, modal, onChapterSelected }) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const [openLesson, setOpenLesson] = useState<string | string[]>('0');
  const [manuallyClosed, setManuallyClosed] = useState(false);

  useEffect(() => {
    if (course && selectedChapter) {
      for (let i = 0; i < course.lessons.length; i++) {
        const lesson = course.lessons[i];
        const chapter = lesson.chapters.find((c) => c.id === selectedChapter);

        if (chapter) {
          setOpenLesson(String(i));
          break;
        }
      }
    }
  }, [course, selectedChapter]);

  useEffect(() => {
    const resizeHandler = () => {
      if (!modal) {
        if (window.innerWidth < 992) {
          setSidebarOpen(false);
        } else {
          if (!manuallyClosed) {
            setSidebarOpen(true);
          }
        }
      }
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [manuallyClosed, modal]);

  const toggleSidebar = () => {
    setManuallyClosed(sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className='learnmode-sidebar-container'>
      <Collapse in={sidebarOpen} dimension='width' timeout={5000}>
        <div>
          <div className='learnmode-sidebar-collapse'>
            {!modal && (
              <div className='learnmode-sidebar-header'>
                <img src={getCourseIcon(course?.identifier)} />
                <h1>{getShortCourseName(course?.identifier)}</h1>
              </div>
            )}
            <Accordion
              defaultActiveKey={'0'}
              activeKey={openLesson}
              className='learnmode-sidebar-lessons custom-scrollbar'
              onSelect={(key) => setOpenLesson(key ?? [])}
            >
              {course?.lessons.map((lesson, id) => (
                <Lesson
                  key={id}
                  lessonId={id}
                  lesson={lesson}
                  selectedChapter={selectedChapter}
                  onChapterSelected={onChapterSelected}
                />
              ))}
            </Accordion>
          </div>
        </div>
      </Collapse>
      {!modal && (
        <img
          className='toggle-sidebar'
          src='assets/icons/close-sidebar.svg'
          onClick={toggleSidebar}
        />
      )}
    </div>
  );
};

export default Sidebar;

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { CourseDocument } from '../interfaces/course';

export const getCourseDocuments = async (identifier: string) => {
  const res = await makeApiRequest<CourseDocument[]>('GET', `v1/courses/${identifier}/documents`);

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

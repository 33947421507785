import React from 'react';

import { useIonRouter } from '@ionic/react';
import { updateChapterProgress } from '../../../api/courses/learn-mode';
import Course, { Chapter } from '../../../api/interfaces/course';
import B1Button from '../../../components/buttons/B1Button';
import { isApiError } from '../../../utils/api-util';

interface NextButtonProps {
  identifier: string;
  course?: Course;
  chapter?: Chapter;
  chapterQuestions: number;
  isQuiz?: boolean;
}

const NextButton: React.FC<NextButtonProps> = ({
  identifier,
  course,
  chapter,
  chapterQuestions,
  isQuiz,
}) => {
  const ionRouter = useIonRouter();

  if (!course || !chapter) return <></>;

  const updateProgress = async (navigateTo: string) => {
    const res = await updateChapterProgress(course.identifier, chapter.id);

    if (isApiError(res)) {
      // TODO: Show error message
      console.error('Error while updating chapter progress:', res);
      return;
    }

    ionRouter.push(navigateTo);
  };

  if (chapterQuestions > 0 && !isQuiz) {
    return (
      <B1Button href={`/course/${identifier}/learnmode/${chapter?.id}/quiz`}>
        Zu den Fragen ({chapterQuestions})
      </B1Button>
    );
  } else {
    for (let i = 0; i < course.lessons.length; i++) {
      const lesson = course.lessons[i];
      const chapterIndex = lesson.chapters.indexOf(chapter);

      if (chapterIndex === -1) continue;

      const nextChapter = lesson.chapters[chapterIndex + 1];

      if (nextChapter) {
        return (
          <B1Button
            click={() => updateProgress(`/course/${identifier}/learnmode/${nextChapter.id}`)}
          >
            Nächstes Kapitel
          </B1Button>
        );
      }

      const nextLesson = course.lessons[i + 1];

      if (nextLesson && nextLesson.chapters.length > 0) {
        return (
          <B1Button
            click={() =>
              updateProgress(`/course/${identifier}/learnmode/${nextLesson.chapters[0].id}`)
            }
          >
            Nächste Lektion
          </B1Button>
        );
      }
    }
  }

  return (
    <B1Button click={() => updateProgress(`/course/${identifier}`)}>
      Zurück zur Kursübersicht
    </B1Button>
  );
};

export default NextButton;

import { ResponsiveType } from 'react-multi-carousel';

export const responsiveCarouselTypes: ResponsiveType = {
  d9: {
    breakpoint: { max: 3000, min: 1975 },
    items: 8,
    slidesToSlide: 3,
    partialVisibilityGutter: 20,
  },
  d8: {
    breakpoint: { max: 1975, min: 1775 },
    items: 7,
    slidesToSlide: 3,
    partialVisibilityGutter: 20,
  },
  d7: {
    breakpoint: { max: 1775, min: 1575 },
    items: 6,
    slidesToSlide: 3,
    partialVisibilityGutter: 20,
  },
  d6: {
    breakpoint: { max: 1575, min: 1375 },
    items: 5,
    slidesToSlide: 3,
    partialVisibilityGutter: 20,
  },
  d5: {
    breakpoint: { max: 1375, min: 1175 },
    items: 4,
    slidesToSlide: 2,
    partialVisibilityGutter: 20,
  },
  d4: {
    breakpoint: { max: 1175, min: 975 },
    items: 3,
    slidesToSlide: 2,
    partialVisibilityGutter: 20,
  },
  d3: {
    breakpoint: { max: 975, min: 775 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 20,
  },
  d2: {
    breakpoint: { max: 775, min: 400 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 20,
  },
  d1: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 20,
  },
};

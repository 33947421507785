import { redeemCode } from '../api/codes';
import Code from '../api/interfaces/code';
import { isApiError } from './api-util';

export const sendRedeemCodeRequest = async (code?: Code) => {
  if (code) {
    const result = await redeemCode(code.code);

    if (isApiError(result)) {
      if (result.statusCode === 400) {
        return 'Code ungültig, bereits eingelöst oder abgelaufen.';
      }

      console.error(result);
    } else if (result) {
      return true;
    }
  }

  return 'Unbekannter Fehler beim Einlösen des Codes.';
};

interface PasswordCriterion {
  regex: RegExp;
  name: string;
  fulfilled: boolean;
}

export const PASSWORD_CRITERIA: PasswordCriterion[] = [
  {
    regex: /.{6,}/,
    name: 'Mind. 6 Zeichen',
    fulfilled: false,
  },
  {
    regex: /[A-Z]{1,}/,
    name: '1 Großbuchstabe',
    fulfilled: false,
  },
  {
    regex: /[^A-Za-z0-9]{1,}/,
    name: '1 Sonderzeichen',
    fulfilled: false,
  },
  {
    regex: /\d{1,}/,
    name: '1 Zahl',
    fulfilled: false,
  },
];

export const getPasswordSecurityBarLength = (passwordCriteria: PasswordCriterion[]) => {
  const fulfilled = passwordCriteria.filter((c) => c.fulfilled).length;
  const all = passwordCriteria.length;

  return (fulfilled / all) * 100 + '%';
};

export const getPasswordSecurityBarColor = (passwordCriteria: PasswordCriterion[]) => {
  const fulfilled = passwordCriteria.filter((c) => c.fulfilled).length;
  const all = passwordCriteria.length;

  if (all === fulfilled) {
    return '#0fa958';
  } else if (fulfilled > all / 2) {
    return '#ffc409';
  } else {
    return '#ff0000';
  }
};

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { TextQuestionAnswer } from '../interfaces/quiz';

export const checkQuestionAnswer = async (
  identifier: string,
  questionId: number,
  answer: string
) => {
  const res = await makeApiRequest<TextQuestionAnswer>(
    'POST',
    `v2/courses/${identifier.toLowerCase()}/check-answer`,
    undefined,
    {
      question_id: questionId,
      answer: answer,
    }
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

import { isApiError } from '../utils/api-util';
import { makeApiRequest } from './api';
import { CourseType } from './interfaces/course';
import SupportElementGroup from './interfaces/support-element';

export const getSupportElements = async (identifier?: CourseType | string) => {
  const res = await makeApiRequest<SupportElementGroup[]>(
    'GET',
    'v1/support' + (identifier ? '/' + identifier.toLowerCase() : '')
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const sendSupportMessage = async (message: string) => {
  const res = await makeApiRequest('POST', 'v1/support', undefined, { text: message });

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const sendFeedback = async (text?: string, positive?: boolean) => {
  const res = await makeApiRequest('POST', 'v2/support/feedback', undefined, {
    text,
    positive,
  });

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

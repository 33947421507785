import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { getRadioTextSubmissions } from '../../../api/courses/radio';
import { RadioTextSubmission } from '../../../api/interfaces/radio-course';
import B1Markdown from '../../../components/markdown/B1Markdown';
import NavigationCircles from '../../../components/navigation-circles/NavigationCircles';
import { isApiError } from '../../../utils/api-util';

const Submission: React.FC<{ submission: RadioTextSubmission }> = ({ submission }) => {
  return (
    <>
      <div className='simple-card card-white exercise-text'>
        <h3>Aufgabe {submission.nr}</h3>
        <B1Markdown>{submission.question_text}</B1Markdown>
      </div>

      <Accordion
        key={submission.id}
        className='b1-accordion b1-accordion-open-white'
        defaultActiveKey={undefined}
      >
        <Accordion.Item eventKey={'0'}>
          <Accordion.Header>Lösung</Accordion.Header>
          <Accordion.Body>
            <B1Markdown>{submission.answer_text}</B1Markdown>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const RadioTextSubmissions: React.FC<{ identifier: string }> = ({ identifier }) => {
  const [radioTextSubmissions, setRadioTextSubmissions] = useState<RadioTextSubmission[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    getRadioTextSubmissions(identifier).then((res) => {
      if (isApiError(res)) {
        console.error(res);
      } else {
        setRadioTextSubmissions(res);
      }
    });
  }, [identifier]);

  return (
    <div className='radio-text-submissions'>
      <h1>Textabgabe</h1>
      <div className='simple-card card-white'>
        <p className='mb-3'>
          Bei der <strong>Textabgabe</strong> liest Du dem Prüfer einen Text vor, der Dir vorgelegt
          wird. Die Wörter, die Du anhand der internationalen Buchstabiertafel buchstabieren musst,
          sind im Text markiert.
        </p>

        <p>
          Du findest hier Beispiele von Funktexten, die bei der Textabgabe verwendet werden können.
          Lies diese Texte laut vor und buchstabiere die unterstrichenen Wörter. Darunter findest Du
          dann immer den ausgeschriebenen Funktext inkl. buchstabierter Wörter als Lösung, mit dem
          Du Deinen Text vergleichen kannst.
        </p>
      </div>

      <NavigationCircles
        list={radioTextSubmissions}
        currentId={radioTextSubmissions[selectedIndex]?.id}
        onClick={(index) => setSelectedIndex(index)}
      />

      {radioTextSubmissions[selectedIndex] && (
        <Submission submission={radioTextSubmissions[selectedIndex]} key={selectedIndex} />
      )}
    </div>
  );
};

export default RadioTextSubmissions;

import { useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import Course from '../../api/interfaces/course';
import Learnmode from './Learnmode';
import ChapterContent from './components/ChapterContent';

const ChapterContentPage: React.FC = () => {
  const { identifier, chapterId: chapterIdParam } = useParams<{
    identifier: string;
    chapterId?: string;
  }>();
  const [chapterId, setChapterId] = useState<number>();
  const [course, setCourse] = useState<Course>();

  useIonViewWillEnter(() => {
    if (chapterIdParam && !isNaN(Number(chapterIdParam))) {
      setChapterId(Number(chapterIdParam));
    }
  }, [chapterIdParam]);

  const handleCourseLoaded = (course: Course) => {
    if ((!chapterIdParam || isNaN(Number(chapterIdParam))) && course.lessons.length > 0) {
      setChapterId(course.lessons[0].chapters[0].id);
    }

    setCourse(course);
  };

  return (
    <Learnmode onCourseLoaded={handleCourseLoaded}>
      {chapterId !== undefined && (
        <ChapterContent course={course} identifier={identifier} chapterId={chapterId} />
      )}
    </Learnmode>
  );
};

export default ChapterContentPage;

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';

export const requestPasswordReset = async (email: string) => {
  const res = await makeApiRequest('POST', 'v1/account/password/request-reset', undefined, {
    email: email,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const resetPassword = async (token: string, password: string) => {
  const res = await makeApiRequest('POST', 'v1/account/password/reset', undefined, {
    token: token,
    password: password,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
  const res = await makeApiRequest('POST', 'v1/account/password/change', undefined, {
    old_password: oldPassword,
    password: newPassword,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

import { RouterOptions, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';

import { changeContactData, getContactData } from '../../api/account/contact-data';
import { updateLicense } from '../../api/account/license';
import B1Button from '../../components/buttons/B1Button';
import AddressForm from '../../components/forms/address-form/AddressForm';
import { useRegistrationDataState } from '../../contexts/registration-data-context';
import { ContactData, RegistrationData } from '../../reducers/registration-data-reducer';
import { isApiError } from '../../utils/api-util';
import { sendRedeemCodeRequest } from '../../utils/code-util';
import Layout from '../Layout';

// import '../../LoginRegister.scss';
// import './RegisterAddress.scss';

const AddressChange: React.FC = () => {
  const [registrationData, dispatchRegistrationData] = useRegistrationDataState();
  const ionRouter = useIonRouter();
  const [mode, setMode] = useState<'address' | 'driver_license'>('address');

  useIonViewWillEnter(() => {
    getContactData().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      dispatchRegistrationData({ type: 'SET_CONTACT_DATA', contactData: res });
    });
  }, []);

  const submitAddress = async () => {
    const data = { ...registrationData.data };

    Object.keys(data).forEach((key) => {
      const x = key as keyof RegistrationData;

      const isContactField = (field: keyof RegistrationData): field is keyof ContactData => {
        return (
          field === 'street' ||
          field === 'house_nbr' ||
          field === 'zip' ||
          field === 'place' ||
          field === 'country_code' ||
          field === 'phone_number'
        );
      };

      if (!isContactField(x)) {
        delete data[x];
      }
    });

    const registrationResult = await changeContactData(data);

    if (isApiError(registrationResult)) {
      console.error(registrationResult);
      return;
    }

    if (registrationData.code) {
      if (registrationData.code.courses.find((course) => course.driver_license_needed)) {
        setMode('driver_license');
        return;
      }

      handleRedeemCode();
    } else {
      ionRouter.push('/');
    }
  };

  const handleDriverLicenseButtonClick = async (hasDriverLicense: boolean) => {
    const res = await updateLicense(hasDriverLicense);

    if (isApiError(res)) {
      alert('Fehler beim Speichern der Führerschein-Daten.');
      console.error(res);
    }

    handleRedeemCode();
  };

  const handleRedeemCode = async () => {
    if (registrationData.code) {
      const redeemCodeResult = await sendRedeemCodeRequest(registrationData.code);

      if (typeof redeemCodeResult === 'string') {
        alert(redeemCodeResult);

        ionRouter.push('/account/redeem-code');
      } else {
        ionRouter.push('/', 'forward', 'push', { from: 'redeem-code' } as RouterOptions);
      }
    } else {
      ionRouter.push('/');
    }
  };

  return (
    <Layout backButton='/account/redeem-code'>
      <div className='register-page register-page-address register-page-driver-license'>
        {mode === 'address' && (
          <>
            <h2 className='mb-3'>Überprüfe deine Kontaktdaten</h2>

            <AddressForm onSubmit={submitAddress} />
          </>
        )}
        {mode === 'driver_license' && (
          <>
            <h2>Bist du im Besitz eines KFZ-Führerscheins?</h2>
            <div className='driver-license-buttons'>
              <B1Button
                className='button-block button-green'
                click={() => handleDriverLicenseButtonClick(true)}
              >
                Ja
              </B1Button>
              <B1Button
                className='button-block button-red'
                click={() => handleDriverLicenseButtonClick(false)}
              >
                Nein
              </B1Button>
            </div>
            <p>
              Solltest du (noch) keinen KFZ-Führerschein besitzen, <br />
              benötigst du ein Führungszeugnis für die <br />
              Prüfungsanmeldung.
            </p>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AddressChange;

import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';

import { CourseType } from '../../../../api/interfaces/course';
import CustomLink from '../../../../components/custom-link/CustomLink';
import useWindowSize from '../../../../hooks/window-size-hook';
import MAP_SETTINGS from '../../../../resources/map-settings';
import Layout from '../../../Layout';

import './ExamBookingBSP.scss';

const MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
const BSP_MARKER_ICON = 'assets/map-icons/boat-1.svg';
const BSP_MARKERS = [
  {
    coords: { lat: 47.6567328999999, lng: 9.4649538 },
    location: 'Friedrichshafen',
  },
  {
    coords: { lat: 48.7303646, lng: 9.10794179999999 },
    location: 'Stuttgart-Vaihingen',
  },
  {
    coords: { lat: 47.745237, lng: 8.9669098 },
    location: 'Radolfzell',
  },
];

const ExamBookingBSP: React.FC = () => {
  const [, setMap] = React.useState<google.maps.Map | null>(null);
  const [infoWindowOpen, setInfoWindowOpen] = React.useState<number>();
  const { width } = useWindowSize();

  const { isLoaded } = useJsApiLoader({
    id: 'practical-training-locations-map',
    googleMapsApiKey: MAPS_API_KEY,
    language: 'de',
    region: 'DE',
  });

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  return (
    <Layout
      contentClassName='exam-booking-bsp'
      backButton='/course/bsp'
      currentCourseType={CourseType.BSP}
    >
      <div className='exam-booking-container'>
        <h1>Prüfung buchen</h1>

        <div className='map-container'>
          {isLoaded && (
            <GoogleMap
              mapContainerClassName='exam-booking-map'
              center={{ lat: 48.35, lng: 9.2159318 }}
              zoom={(width ?? 0) < 576 ? 5 : 6}
              onLoad={onLoad}
              onUnmount={onUnmount}
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: true,
                styles: MAP_SETTINGS.styles,
              }}
            >
              {BSP_MARKERS.map((marker, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: marker.coords.lat,
                    lng: marker.coords.lng,
                  }}
                  icon={BSP_MARKER_ICON}
                  title={marker.location}
                  onClick={() => setInfoWindowOpen(index)}
                >
                  {infoWindowOpen === index && (
                    <InfoWindow onCloseClick={() => setInfoWindowOpen(undefined)}>
                      <center>
                        <h6>Prüfung verfügbar in {marker.location}</h6>
                      </center>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </GoogleMap>
          )}
        </div>

        <div className='simple-card card-light-gray'>
          <p className='mb-3'>
            Die Prüfung für das Bodenseeschifferpatent für Motorboote kann entweder in
            Friedrichshafen, Stuttgart-Vaihingen oder Radolfzell abgelegt werden.
          </p>
          <ol className='mb-4'>
            <li>Öffne die jeweilige Seite</li>
            <li>Gib Deine persönlichen Daten an</li>
            <li>
              Unter der Kategorie „Motorboot Kat. A” setze das Häkchen bei „Theoretische Prüfung”
            </li>
            <li>Wähle den Prüfungsort und das Datum aus</li>
            <li>Schicke den Online-Antrag ab</li>
          </ol>

          <p className='mb-1'>
            Für eine Prüfung in Friedrichshafen oder Stuttgart-Vaihingen{' '}
            <CustomLink href='https://schifffahrtsamt.bodenseekreis.de/PEinzel2.aspx'>
              hier klicken
            </CustomLink>
            .
          </p>
          <p className='mb-4'>
            Für eine Prüfung in Radolfzell{' '}
            <CustomLink href='https://sfaonline.lrakn.de/PEinzel2.aspx'>hier klicken</CustomLink>.
          </p>

          <p className='mb-3'>Was muss ich zur Prüfung mitbringen?</p>
          <ul>
            <li>
              Ein <strong>Lichtbild</strong> (35 x 45 mm oder 36 x 47 mm). Bitte versieh dieses auf
              der Rückseite mit Deinem Namen!
            </li>
            <li>
              <strong>Das ärztliche Zeugnis</strong>. Inhaber des Sportbootführerscheins Binnen oder
              See sind von der Vorlage des ärztlichen Zeugnisses befreit, sofern der Führerschein
              nicht älter als ein Jahr ist.
              <ul>
                <li>
                  <CustomLink href='https://www.bodenseekreis.de/fileadmin/02_verkehr_wirtschaft/schifffahrt/downloads/bodenseeschifferpatent/aerztliches_zeugnis_mit_tauglichkeitskriterien.pdf'>
                    Ärztliches Zeugnis für den Prüfungsstandort Friedrichshafen oder
                    Stuttgart-Vaihingen
                  </CustomLink>
                </li>
              </ul>
            </li>
            <li>
              Kopien von Vorder-/Rückseite Deines Sportbootführerscheins sind bei der theoretischen
              Prüfung mitzubringen
            </li>
            <li>Personalausweis/Reisepass</li>
            <li>Kugelschreiber</li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default ExamBookingBSP;

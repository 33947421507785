import { ExamPaper } from '../../api/interfaces/exam-paper';

export interface ExamSimulatorCourse {
  identifier: string;
  examPapers?: ExamPaper[];
  examPapersDocument?: string;
}

type ExamSimulatorData = {
  courses: ExamSimulatorCourse[];
};
type ExamSimulatorAction =
  | { type: 'RESET_COURSES' }
  | { type: 'ADD_EXAM_PAPERS'; identifier: string; examPapers: ExamPaper[] }
  | { type: 'SET_EXAM_PAPERS_DOCUMENT'; identifier: string; examPapersDocument?: string };

export const examSimulatorReducer = (
  state: ExamSimulatorData,
  action: ExamSimulatorAction
): ExamSimulatorData => {
  switch (action.type) {
    case 'RESET_COURSES':
      return { ...state, courses: [] };
    case 'ADD_EXAM_PAPERS':
      if (!state.courses.find((c) => c.identifier === action.identifier)) {
        return {
          ...state,
          courses: [
            ...state.courses,
            { identifier: action.identifier, examPapers: action.examPapers },
          ],
        };
      }

      return {
        ...state,
        courses: state.courses.map((c) => {
          if (c.identifier === action.identifier) {
            return { ...c, examPapers: action.examPapers };
          }

          return c;
        }),
      };
    case 'SET_EXAM_PAPERS_DOCUMENT':
      if (!state.courses.find((c) => c.identifier === action.identifier)) {
        return {
          ...state,
          courses: [
            ...state.courses,
            { identifier: action.identifier, examPapersDocument: action.examPapersDocument },
          ],
        };
      }

      return {
        ...state,
        courses: state.courses.map((c) => {
          if (c.identifier === action.identifier) {
            return { ...c, examPapersDocument: action.examPapersDocument };
          }

          return c;
        }),
      };
    default:
      return state;
  }
};

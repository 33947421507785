import { Capacitor } from '@capacitor/core';
import React, { useEffect, useRef } from 'react';

import { useKeyboard } from '../../hooks/keyboard-hook';
import { FeedbackClass } from './forms';

import './B1Input.scss';
import './Forms.scss';

interface InputProps {
  ref?: React.RefObject<HTMLInputElement>;
  className?: string;
  id?: string;
  name?: string;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  placeholder?: string;
  value?: string | number | readonly string[];
  defaultValue?: string | number | readonly string[];
  autoComplete?: string;
  min?: string | number;
  max?: string | number;
  step?: string | number;
  readonly?: boolean;
  disabled?: boolean;
  onInput?: React.FormEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  feedback?: string;
  feedbackClass?: FeedbackClass;
}

const B1Input: React.FC<InputProps> = (inputProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isOpen } = useKeyboard();
  const [isFocused, setIsFocused] = React.useState(false);
  const classes = ['b1-form-element'];

  if (inputProps.className) {
    classes.push(inputProps.className);
  }

  if (inputProps.feedbackClass) {
    classes.push(inputProps.feedbackClass);
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);

    if (inputProps.onFocus) {
      inputProps.onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);

    if (inputProps.onBlur) {
      inputProps.onBlur(e);
    }
  };

  useEffect(() => {
    if (isOpen && isFocused && wrapperRef.current && Capacitor.getPlatform() === 'ios') {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isOpen, isFocused]);

  return (
    <div className={classes.join(' ')} ref={wrapperRef}>
      {inputProps.label && <label htmlFor={inputProps.id}>{inputProps.label}</label>}
      <input
        ref={inputProps.ref}
        id={inputProps.id}
        name={inputProps.name}
        type={inputProps.type}
        placeholder={inputProps.placeholder}
        value={inputProps.value}
        defaultValue={inputProps.defaultValue}
        autoComplete={inputProps.autoComplete}
        min={inputProps.min}
        max={inputProps.max}
        step={inputProps.step}
        readOnly={inputProps.readonly}
        disabled={inputProps.disabled}
        onInput={inputProps.onInput}
        onChange={inputProps.onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {inputProps.feedback && <span className='form-feedback'>{inputProps.feedback}</span>}
    </div>
  );
};

export default B1Input;

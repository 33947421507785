import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import {
  RouteInfo,
  getConfig,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import React, { FormEvent, useContext, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import FormCheck from 'react-bootstrap/FormCheck';
import { Link } from 'react-router-dom';

import B1Button from '../../components/buttons/B1Button';
import B1Input from '../../components/forms/B1Input';
import { FeedbackClass } from '../../components/forms/forms';
import MigrationModal from '../../components/modal/migration-modal/MigrationModal';
import { AuthContext } from '../../providers/AuthProvider';
import { formatDuration } from '../../utils/misc-util';
import Layout from '../Layout';

import '../LoginRegister.scss';
import './Login.scss';

const Login: React.FC = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [feedbackClass, setFeedbackClass] = useState<FeedbackClass>();
  const [migrationModalOpen, setMigrationModalOpen] = useState(false);

  const ionRouter = useIonRouter();
  const locationState = ionRouter.routeInfo as RouteInfo<{ from?: string }>;

  useIonViewDidEnter(() => {
    getConfig()?.set('swipeBackEnabled', false);
  }, []);

  useIonViewWillLeave(() => {
    getConfig()?.set('swipeBackEnabled', true);
  }, []);

  async function submitLogin(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const status = await Network.getStatus();

    if (!status.connected) {
      setFeedback('Du bist offline, bitte überprüfe deine Internetverbindung');
      setFeedbackClass('warning');
      return;
    }

    if (!email || !password) {
      setFeedback('Bitte Zugangsdaten eingeben');
      setFeedbackClass('warning');
      return;
    }

    const loginResult = await login(email, password, stayLoggedIn);

    if (typeof loginResult === 'boolean') {
      if (loginResult) {
        if (Capacitor.getPlatform() === 'ios') {
          await SavePassword.promptDialog({
            username: email,
            password: password,
          });
        }

        setEmail('');
        setPassword('');
        setStayLoggedIn(false);
        setFeedback('');
        setFeedbackClass(undefined);

        ionRouter.push('/', 'forward', 'replace', { unmount: true });
      } else {
        setFeedback('Fehlerhafte Zugangsdaten');
        setFeedbackClass('warning');
      }
    } else {
      switch (loginResult.statusCode) {
        case 401:
          setFeedback('Fehlerhafte Zugangsdaten');
          setFeedbackClass('warning');
          break;
        case 403:
          setFeedback('E-Mail-Adresse wurde nicht bestätigt');
          setFeedbackClass('warning');
          break;
        case 404:
          setFeedback('Benutzer nicht gefunden');
          setFeedbackClass('warning');
          break;
        case 418:
          setMigrationModalOpen(true);
          break;
        case 429:
          setFeedback(
            'Zu viele Anfragen, versuche es erneut in ' +
              formatDuration(loginResult.retryAfter ?? 0)
          );
          setFeedbackClass('warning');
          break;
        default:
          setFeedback('Fehlerhafte Zugangsdaten');
          setFeedbackClass('warning');
          break;
      }
    }
  }

  const getLocationFromText = () => {
    switch (locationState.routeOptions?.from) {
      case 'logout':
        return (
          <div className='mb-4 b1-alert b1-alert-transparent'>
            <img src='assets/icons/check-icon-filled-2.svg' />
            <p>Du wurdest erfolgreich ausgeloggt.</p>
          </div>
        );
      case 'delete-account':
        return (
          <div className='mb-4 b1-alert b1-alert-transparent'>
            <img src='assets/icons/check-icon-filled-2.svg' />
            <p>Dein Account wurde erfolgreich gelöscht.</p>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <Layout>
      <div className='login-page login-page-login'>
        <Carousel
          controls={false}
          indicators={false}
          interval={2000}
          slide
          touch={false}
          pause={false}
        >
          <Carousel.Item>
            <h1 className='emoji'>🎓</h1>
          </Carousel.Item>
          <Carousel.Item>
            <h1 className='emoji'>🛥</h1>
          </Carousel.Item>
          <Carousel.Item>
            <h1 className='emoji'>🧭</h1>
          </Carousel.Item>
          <Carousel.Item>
            <h1 className='emoji'>🤓</h1>
          </Carousel.Item>
        </Carousel>
        <h2 className='mb-3'>Login</h2>
        {getLocationFromText()}
        <form className='b1-form' onSubmit={submitLogin}>
          <B1Input
            id='email'
            type='email'
            placeholder='Bitte E-Mail-Adresse eingeben'
            autoComplete='email'
            label='E-Mail-Adresse'
            value={email}
            onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
            feedbackClass={feedbackClass}
          ></B1Input>
          <B1Input
            id='password'
            type='password'
            placeholder='Bitte Passwort eingeben'
            autoComplete='current-password'
            label='Passwort'
            value={password}
            onInput={(e) => setPassword((e.target as HTMLInputElement).value)}
            feedback={feedback}
            feedbackClass={feedbackClass}
          ></B1Input>
          <FormCheck
            id='stay_logged_in'
            checked={stayLoggedIn}
            onChange={(e) => setStayLoggedIn(e.currentTarget.checked)}
            label='Angemeldet bleiben'
          />
          <B1Button type='submit' className='button-block'>
            Anmelden
          </B1Button>
        </form>
        <Link to='/login/forgot-password' className='forgot-password'>
          Passwort vergessen?
        </Link>
        <div className='third-party-login-separator'>oder</div>
        <B1Button href='/login/code' className='button-block button-reverse'>
          Aktivierungscode einlösen
        </B1Button>
      </div>
      <MigrationModal open={migrationModalOpen} setOpen={setMigrationModalOpen} email={email} />
    </Layout>
  );
};

export default Login;

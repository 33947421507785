import { isApiError } from '../utils/api-util';
import { makeApiRequest } from './api';
import { Story } from './interfaces/story';

export const getStories = async () => {
  const res = await makeApiRequest<Story[]>('GET', 'v1/stories');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const addStoryPageView = async (id: number) => {
  const res = await makeApiRequest('POST', `v1/stories/${id}`);

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const addStoryPageLinkView = async (id: number) => {
  const res = await makeApiRequest('POST', `v1/stories/${id}/link`);

  if (isApiError(res)) {
    return res;
  }

  return true;
};

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import Knot from '../interfaces/knot';

export const getKnots = async (identifier: string) => {
  const res = await makeApiRequest<Knot[]>('GET', `v1/courses/${identifier}/knots`);

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

import React, { useRef, useState } from 'react';
import VerificationInput from 'react-verification-input';

import './Code.scss';

interface CodeProps {
  initialCode?: string;
  fields: number;
  valid: boolean | undefined;
  submitCode: (code: string) => Promise<boolean>;
}

const Code: React.FC<CodeProps> = ({ initialCode, fields, valid, submitCode }) => {
  const [loading, setLoading] = useState(false);
  const inputField = useRef<HTMLInputElement>(null);

  const onCodeComplete = async (code: string) => {
    setLoading(true);
    const submitResult = await submitCode(code);
    setLoading(false);

    if (!submitResult) {
      setTimeout(() => {
        inputField.current?.focus();
      });
    }
  };

  return (
    <VerificationInput
      ref={inputField}
      length={fields}
      autoFocus
      onComplete={onCodeComplete}
      placeholder=''
      validChars='A-Za-z0-9'
      inputProps={{ disabled: loading || valid }}
      value={initialCode}
      classNames={{
        container: 'code-container',
        character: 'code-character',
        characterSelected: 'code-character-selected',
        characterInactive: 'code-character-inactive',
      }}
    />
  );
};

export default Code;

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import UserCourse from '../interfaces/user-course';

export const getUserCourses = async () => {
  const res = await makeApiRequest<UserCourse[]>('GET', 'v1/courses');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

import { TCountryCode, countries } from 'countries-list';

export const getCountryOptions = () => {
  const options = [];

  for (const countryCode in countries) {
    options.push({
      value: countryCode,
      label: countries[countryCode as TCountryCode].native,
    });
  }

  // sort options with DE, AT and CH on top
  options.sort((a, b) => {
    if (a.value === 'DE') return -1;
    if (b.value === 'DE') return 1;
    if (a.value === 'AT') return -1;
    if (b.value === 'AT') return 1;
    if (a.value === 'CH') return -1;
    if (b.value === 'CH') return 1;
    if (a.value === 'LU') return -1;
    if (b.value === 'LU') return 1;
    return 0;
  });

  return options;
};

export const getPhoneNumberPrefixes = () => {
  const options = [];

  for (const countryCode in countries) {
    const country = countries[countryCode as TCountryCode];

    for (const phonePrefix of country.phone) {
      options.push({
        value: countryCode,
        prefix: phonePrefix,
        // label: `+${phonePrefix} (${country.native})`,
        label: `+${phonePrefix}`,
      });
    }
  }

  // sort options with DE, AT, CH, LU on top
  options.sort((a, b) => {
    if (a.value === 'DE') return -1;
    if (b.value === 'DE') return 1;
    if (a.value === 'AT') return -1;
    if (b.value === 'AT') return 1;
    if (a.value === 'CH') return -1;
    if (b.value === 'CH') return 1;
    if (a.value === 'LU') return -1;
    if (b.value === 'LU') return 1;

    return a.prefix - b.prefix;
  });

  return options;
};

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useIonRouter } from '@ionic/react';
import React, { useEffect } from 'react';

const AppUrlListener: React.FC = () => {
  const ionRouter = useIonRouter();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const slug = event.url.split('.app').pop();

      if (slug) {
        ionRouter.push(slug);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;

import { ContactData, PersonalData } from '../../reducers/registration-data-reducer';
import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';

export const getContactData = async () => {
  const res = await makeApiRequest<ContactData>('GET', 'v1/account/contact-data');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const changeContactData = async (addressData: ContactData) => {
  const res = await makeApiRequest('POST', 'v1/account/contact-data', undefined, addressData);

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const changePersonalData = async (personalData: PersonalData) => {
  const res = await makeApiRequest('POST', 'v1/account/personal-data', undefined, personalData);

  if (isApiError(res)) {
    return res;
  }

  return true;
};

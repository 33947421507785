import { Duration } from 'luxon';
import React, { useContext, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { BiChevronDown, BiChevronUp, BiSolidChevronRight } from 'react-icons/bi';

import { Chapter, Lesson as LessonInterface } from '../../../api/interfaces/course';
import useWindowSize from '../../../hooks/window-size-hook';

import './Lesson.scss';

interface LessonProps {
  lessonId: number;
  lesson: LessonInterface;
  selectedChapter?: number;
  onChapterSelected: (chapter: Chapter) => void;
}

const Lesson: React.FC<LessonProps> = ({
  lessonId,
  lesson,
  selectedChapter,
  onChapterSelected,
}) => {
  const { activeEventKey } = useContext(AccordionContext);
  const { width } = useWindowSize();
  const decoratedOnClick = useAccordionButton(String(lessonId));
  const open = String(lessonId) === activeEventKey;
  const lessonContainer = useRef<HTMLDivElement>(null);

  const onEntered = () => {
    if (width && width >= 576) {
      lessonContainer.current?.offsetParent?.scrollTo({
        top: lessonContainer.current.offsetTop,
        behavior: 'smooth',
      });
    } else {
      lessonContainer.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div ref={lessonContainer} className={'lesson' + (open ? ' lesson-open' : '')}>
      <div className='lesson-header' onClick={decoratedOnClick}>
        <span className='lesson-progress'>{lessonId + 1}</span>
        <div className='lesson-title'>
          <p>{lesson.title}</p>
          <span className='video-length'>
            {Duration.fromObject({ seconds: lesson.video_length }).toFormat("mm 'm' ss 's'")}
          </span>
        </div>
        {open ? <BiChevronDown className='chevron' /> : <BiChevronUp className='chevron' />}
      </div>
      <Accordion.Collapse eventKey={String(lessonId)} appear={true} onEntered={onEntered}>
        <div className='lesson-content'>
          {lesson.chapters.map((chapter, chapterId) => (
            <div
              key={chapterId}
              className={`lesson-chapter${
                chapter.id === selectedChapter ? ' chapter-selected' : ''
              }`}
              onClick={() => onChapterSelected(chapter)}
            >
              {chapter.finished ? (
                <img src='assets/icons/progress-circle-finished.svg' />
              ) : (
                <img src='assets/icons/progress-circle.svg' />
              )}
              <div className='chapter-title'>
                <BiSolidChevronRight
                  style={{ visibility: chapter.id === selectedChapter ? 'visible' : 'hidden' }}
                />
                <p title={chapter.title}>{chapter.title}</p>
              </div>
              {chapter.video_length > 0 && (
                <div className='video-length'>
                  <img src='assets/icons/video-icon.svg' />
                  <span>
                    {Duration.fromObject({ seconds: chapter.video_length }).toFormat('mm:ss')}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default Lesson;

import { useIonRouter } from '@ionic/react';
import React, { useContext } from 'react';

import B1Button from '../../../components/buttons/B1Button';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import { AuthContext } from '../../../providers/AuthProvider';
import Layout from '../../Layout';

import '../../LoginRegister.scss';
import './RegisterDocuments.scss';

const RegisterDocuments: React.FC = () => {
  const { register } = useContext(AuthContext);
  const [registrationData] = useRegistrationDataState();
  const ionRouter = useIonRouter();

  async function sendRegistration() {
    if (!registrationData.code) {
      // TODO: Besseren Hinweis anzeigen, dass kein Code eingegeben wurde.
      alert('Es wurde kein Code eingegeben!');
      ionRouter.push('/login/code', 'back', 'pop');
      return;
    }

    const registrationResult = await register(registrationData.data, registrationData.code);

    if (registrationResult) {
      ionRouter.push('/register/success');
    } else {
      // TODO: Fehlermeldung anzeigen
    }
  }

  return (
    <Layout backButton='/register/driver-license'>
      <div className='register-page register-page-documents'>
        <h2 className='mb-3'>Jetzt loslegen</h2>
        <h4 className='mb-4 no-hyphens'>
          Für die Buchung der Praxisausbildung & offiziellen Prüfung, kannst du schon folgende
          Dokumente bereithalten.
        </h4>
        <div className='todo-list'>
          <ol>
            <li data-index='1'>
              <div>
                <h5 className='bold'>Ärztliches Attest</h5>
                <p className='description mb-0'>Im Willkommenspaket als Vordruck enthalten.</p>
              </div>
            </li>
            <li data-index='2'>
              <h5 className='bold'>Passfoto (35 mm x 45 mm)</h5>
            </li>
            <li data-index='3'>
              <h5 className='bold'>
                {registrationData.data.has_driver_license
                  ? 'Kopie des KFZ-Führerscheins'
                  : 'Führungszeugnis'}
              </h5>
            </li>
          </ol>
        </div>
        <B1Button className='button-block' click={sendRegistration}>
          Weiter
        </B1Button>
        <p className='description document-delivery-hint'>
          Die Dokumente werden später postalisch versendet.
        </p>
      </div>
    </Layout>
  );
};

export default RegisterDocuments;

export enum VideoQuality {
  AUTO = 'auto',
  HD = '720p',
  FullHD = '1080p',
  UHD = '2160p',
}

interface Video {
  // org_video_link: string;
  hls_link: string;
  mp4_720p_link: string;
  mp4_1080p_link: string;
  mp4_2160p_link: string;
  thumbnail_link: string;
  vtt_link?: string;
}

export default Video;

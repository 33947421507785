import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CourseType } from '../../../api/interfaces/course';
import UserCourse from '../../../api/interfaces/user-course';
import AllInOneCourseCard from '../../../components/course/AllInOneCourseCard';
import useWindowSize from '../../../hooks/window-size-hook';
import DashboardWrapper from '../dashboard-wrapper/DashboardWrapper';

const PartnerSRCDashboard: React.FC = () => {
  const [userCourses, setUserCourses] = useState<UserCourse[]>([]);
  const { width } = useWindowSize();

  return (
    <DashboardWrapper courseType={CourseType.PSRC} onUserCoursesLoaded={setUserCourses}>
      <Row className='g-3 course-cards'>
        <Col xl='4'>
          <AllInOneCourseCard
            icon='assets/icons/BS1_Icon_Device_Simulation.svg'
            title='Gerätesimulation'
            description={
              (width ?? 0) < 768
                ? 'Aktuell nur auf Desktop verfügbar.'
                : 'Übe hier an einem virtuellen Funkgerät.'
            }
            locked={
              (width ?? 0) < 768 ||
              userCourses.find((c) => c.identifier === CourseType.PSRC)?.expired
            }
            showCompactDescription
            hasChevronIcon
            isCompact
            link='/course/psrc/radio'
          />
        </Col>
        <Col xl='4'>
          <AllInOneCourseCard
            link='/course/psrc/smartmode'
            icon='assets/icons/learn-mode.svg'
            title='Smartmodus'
            description='Hier kannst Du alle Fragen, die in der theoretischen Prüfung vorkommen können, effizient lernen.'
            locked={userCourses.find((c) => c.identifier === CourseType.PSRC)?.expired}
          />
        </Col>
        <Col xl='4'>
          <AllInOneCourseCard
            link='/course/psrc/exam-training'
            icon='assets/icons/exam-simulation.svg'
            title='Theorie Prüfungssimulation'
            description='Hier kannst Du mit den offiziellen Prüfungsbögen in einem echten Prüfungsszenario lernen.'
            locked={userCourses.find((c) => c.identifier === CourseType.PSRC)?.expired}
          />
        </Col>
      </Row>
    </DashboardWrapper>
  );
};

export default PartnerSRCDashboard;

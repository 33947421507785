import React, { PropsWithChildren } from 'react';
import Col from 'react-bootstrap/Col';

import UserCourse from '../../api/interfaces/user-course';
import { CourseCardLink, handleCourseLinkClick } from '../../utils/course-link-util';
import { getCourseImage, getCourseSellupPageLink } from '../../utils/course-util';
import CustomLink from '../custom-link/CustomLink';

import './CourseShowcaseCard.scss';

interface CourseShowcaseCardWrapperProps extends PropsWithChildren {
  link?: CourseCardLink;
  image: string;
}

const CourseShowcaseCardWrapper: React.FC<CourseShowcaseCardWrapperProps> = ({
  link,
  image,
  children,
}) => {
  if (link) {
    return (
      <CustomLink
        href={link.url}
        className='card course-showcase-card scale-effect'
        style={{
          backgroundImage: `url('${image}')`,
        }}
        onClick={() => handleCourseLinkClick(link)}
      >
        {children}
      </CustomLink>
    );
  } else {
    return (
      <div
        className='card course-showcase-card locked-card'
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        {children}
      </div>
    );
  }
};

interface CourseShowcaseCardProps {
  course: UserCourse;
}

const CourseShowcaseCard: React.FC<CourseShowcaseCardProps> = ({ course }) => {
  const link: CourseCardLink = {
    url: getCourseSellupPageLink(course.identifier),
    courseIdentifier: course.identifier,
    type: 'upsell',
  };

  return (
    <Col xs={12} md={6}>
      <CourseShowcaseCardWrapper link={link} image={getCourseImage(course.identifier)}>
        <div className='course-title-container'>
          <h2 className='course-title'>{course.title}</h2>
          {link ? (
            <img className='arrow-button' src='assets/icons/pointer.svg' />
          ) : (
            <img className='arrow-button' src='assets/icons/pointer-inactive.svg' />
          )}
        </div>
      </CourseShowcaseCardWrapper>
    </Col>
  );
};

export default CourseShowcaseCard;

import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import { BsBarChartFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { ExamPaper, ExamPaperSubmission } from '../../api/interfaces/exam-paper';
import B1Button from '../../components/buttons/B1Button';
import ProgressBar from '../../components/progressbar/ProgressBar';
import { ExamSimulatorCourse } from './exam-simulator-reducer';

import './ExamPaperCard.scss';

interface ExamPaperCardProps {
  course: ExamSimulatorCourse;
  examPaper: ExamPaper;
  onMoreDetailsClick: () => void;
}

const ExamPaperCard: React.FC<ExamPaperCardProps> = ({ examPaper, course, onMoreDetailsClick }) => {
  const getPercentage = (examPaper: ExamPaper, submission: ExamPaperSubmission | undefined) => {
    if (!submission) {
      // Trying v1 submission
      if (examPaper.v1_submission) {
        return examPaper.v1_submission.correct_count / examPaper.questions_count;
      } else {
        return 0;
      }
    } else {
      return (
        submission.categories.reduce((a, b) => a + b.correct, 0) /
        submission.categories.reduce((a, b) => a + b.total, 0)
      );
    }
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const renderTooltip = (
    <Popover
      id='exam-paper-stats-tooltip'
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
      onClick={(event) => event.stopPropagation()}
    >
      <Popover.Body>
        <Row>
          <Col xs={9} style={{ color: '#0FA958' }}>
            Bestanden
          </Col>
          <Col xs={3}>{examPaper.submissions.filter((s) => s.passed).length}</Col>
        </Row>
        <Row>
          <Col xs={9} style={{ color: '#F00' }}>
            Durchgefallen
          </Col>
          <Col xs={3}>{examPaper.submissions.filter((s) => !s.passed).length}</Col>
        </Row>
        {examPaper.submissions.length > 0 && (
          <B1Button
            className='button-sm mt-2'
            click={() => {
              setTooltipOpen(false);
              onMoreDetailsClick();
            }}
          >
            Mehr Details
          </B1Button>
        )}
      </Popover.Body>
    </Popover>
  );

  const classes = ['exam-paper-card', 'scale-effect'];
  const lastAttempt: ExamPaperSubmission | undefined =
    examPaper.submissions[examPaper.submissions.length - 1];
  const percentage = getPercentage(examPaper, lastAttempt);
  let points = 0;
  let totalPoints = examPaper.total_points;
  let hasAttempted = false;

  if (lastAttempt) {
    points = lastAttempt.categories.reduce((a, b) => a + b.correct, 0);
    hasAttempted = true;

    if (lastAttempt.passed) {
      for (const category of lastAttempt.categories) {
        if (
          category.correct <= Math.round(category.correct_to_pass + category.correct_to_pass * 0.1)
        ) {
          classes.push('exam-marginal-passed');
          break;
        }
      }

      if (!classes.includes('exam-marginal-passed')) {
        classes.push('exam-passed');
      }
    } else {
      classes.push('exam-failed');
    }
  } else if (examPaper.v1_submission) {
    points = examPaper.v1_submission.correct_count;
    totalPoints = examPaper.questions_count;
    hasAttempted = true;
  }

  if (percentage === 1) {
    classes.push('exam-perfect');
  }

  return (
    <Link
      to={`/course/${course.identifier.toLowerCase()}/exam-training/${examPaper.id}`}
      className={classes.join(' ')}
      draggable={false}
    >
      <h6>
        {examPaper.nr}
        {percentage === 1 && <img src='assets/icons/check-icon-filled.svg' alt='Bestanden' />}
      </h6>
      <ProgressBar progress={percentage} />
      <p className='correct-answers'>
        {points} / {totalPoints}
      </p>
      <p className='last-attempt'>
        {hasAttempted ? (
          <>
            <strong>Letzter Versuch:</strong> {(percentage * 100).toFixed(0)} %
          </>
        ) : (
          <strong>Kein Versuch</strong>
        )}
      </p>
      <OverlayTrigger
        placement='bottom'
        overlay={renderTooltip}
        trigger={['hover', 'focus']}
        show={tooltipOpen}
        onToggle={setTooltipOpen}
      >
        <button
          className='stats'
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          Statistiken <BsBarChartFill />
        </button>
      </OverlayTrigger>
    </Link>
  );
};

export default ExamPaperCard;

import React from 'react';

import B1Button from '../../buttons/B1Button';
import B1Modal from '../B1Modal';

import './MigrationModal.scss';

interface MigrationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  email: string;
}

const MigrationModal: React.FC<MigrationModalProps> = ({ open, setOpen, email }) => {
  return (
    <B1Modal open={open} onModalClose={() => setOpen(false)} className='migration-modal'>
      <h1 className='emoji'>📣</h1>
      <h2>Neue Lernplattform freigeschaltet</h2>
      <p>Dein bestehender Account wurde von der alten Lernplattform umgezogen.</p>
      <p className='mb-4'>
        Um Dich hier einloggen oder registrieren zu können, musst Du Dein Passwort zurücksetzen.
      </p>
      <B1Button className='button-block' href={`/login/forgot-password/${email}`}>
        Passwort zurücksetzen
      </B1Button>
    </B1Modal>
  );
};

export default MigrationModal;

import { RouterOptions, useIonRouter } from '@ionic/react';
import React, { FormEvent, useContext, useEffect, useState } from 'react';

import { changeEmail } from '../../api/account/email';
import { AccountStatus } from '../../api/interfaces/account-info';
import B1Button from '../../components/buttons/B1Button';
import B1Input from '../../components/forms/B1Input';
import { FeedbackClass } from '../../components/forms/forms';
import { AuthContext } from '../../providers/AuthProvider';
import Layout from '../Layout';

const ChangeEmail: React.FC = () => {
  const { isAuthenticated, getUserData } = useContext(AuthContext);
  const [userState, setUserState] = useState<AccountStatus>();
  const ionRouter = useIonRouter();
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState<string>();
  const [feedbackClass, setFeedbackClass] = useState<FeedbackClass>();

  useEffect(() => {
    getUserData().then(setUserState);
  }, [isAuthenticated, getUserData]);

  const submitEmail = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email === userState?.email) {
      setFeedback('Die neue E-Mail-Adresse darf nicht identisch mit der alten sein.');
      setFeedbackClass('warning');
      return;
    }

    const res = await changeEmail(email);

    if (res === true) {
      // TODO: show success message

      ionRouter.push('/account', 'back', 'push', { from: 'change-email' } as RouterOptions);

      setEmail('');
      setFeedback('');
      setFeedbackClass(undefined);
    } else {
      switch (res.statusCode) {
        case 400:
          setFeedback('Diese E-Mail-Adresse ist ungültig.');
          setFeedbackClass('warning');
          break;
        case 409:
          setFeedback('Diese E-Mail-Adresse ist bereits vergeben.');
          setFeedbackClass('warning');
          break;
        default:
          setFeedback('Unbekannter Fehler.');
          setFeedbackClass('warning');
          break;
      }
    }
  };

  return (
    <Layout backButton='/account'>
      <div className='register-page register-page-password'>
        <h2 className='mb-3'>E-Mail-Adresse ändern</h2>
        <h4 className='mb-4'>
          Hier kannst du deine E-Mail-Adresse ändern. Gib dazu deine neue E-Mail-Adresse ein und
          bestätige sie im Anschluss.
        </h4>
        <form className='b1-form' onSubmit={submitEmail}>
          <B1Input
            id='old-email'
            type='email'
            autoComplete='email'
            readonly
            disabled
            value={userState?.email}
            label='Alte E-Mail-Adresse'
          />
          <B1Input
            id='new-email'
            type='email'
            placeholder='Bitte neue E-Mail-Adresse eingeben'
            autoComplete='email'
            label='Neue E-Mail-Adresse'
            value={email}
            onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
            feedback={feedback}
            feedbackClass={feedbackClass}
          />
          <B1Button type='submit' className='button-block'>
            Bestätigen
          </B1Button>
        </form>
      </div>
    </Layout>
  );
};

export default ChangeEmail;

import { TCountryCode, getEmojiFlag } from 'countries-list';
import phone from 'phone';
import React, { useEffect, useState } from 'react';

import Twemoji from '../../../components/twemoji/Twemoji';
import { escapeRegExp } from '../../../utils/misc-util';

import './PhoneNumber.scss';

interface PhoneNumberProps {
  prefixOptions: { value: string; prefix: number; label: string }[];
  phoneNumber: string;
  onPhoneNumberChange: (phoneNumber: string) => void;
  phoneNumberFeedback?: string;
  onPhoneNumberFeedbackChange: (phoneNumberFeedback?: string) => void;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({
  prefixOptions,
  phoneNumber,
  onPhoneNumberChange,
  phoneNumberFeedback,
  onPhoneNumberFeedbackChange,
}) => {
  const [number, setNumber] = useState('');
  const [countryCode, setCountryCode] = useState<TCountryCode>('DE');

  useEffect(() => {
    const result = phone(phoneNumber, {
      validateMobilePrefix: false,
    });

    if (result.phoneNumber) {
      setNumber(result.phoneNumber.replace(new RegExp('^' + escapeRegExp(result.countryCode)), ''));
      setCountryCode(result.countryIso2 as TCountryCode);
    }
  }, [phoneNumber]);

  const handlePhoneNumberBlur = () => {
    if (!number) {
      onPhoneNumberFeedbackChange(undefined);
      onPhoneNumberChange('');
      return;
    }

    const validatedPhoneNumber = phone(number, {
      country: countryCode,
      validateMobilePrefix: false,
    });

    if (validatedPhoneNumber.isValid) {
      onPhoneNumberFeedbackChange(undefined);
      onPhoneNumberChange(validatedPhoneNumber.phoneNumber);
    } else {
      onPhoneNumberFeedbackChange('Bitte gib eine gültige Telefonnummer an.');
      onPhoneNumberChange('');
    }
  };

  return (
    <div
      className={`b1-form-element-combined phone-number${phoneNumberFeedback ? ' warning' : ''}`}
    >
      <label htmlFor='phone_number'>Telefonnummer</label>
      <div className='combined-form'>
        {countryCode && <Twemoji emoji={getEmojiFlag(countryCode)} />}
        <select
          value={countryCode}
          onChange={(e) => setCountryCode(e.currentTarget.value as TCountryCode)}
        >
          {prefixOptions.map((prefix, index) => (
            <option key={index} value={prefix.value}>
              {prefix.label}
            </option>
          ))}
        </select>
        <input
          type='tel'
          id='phone_number'
          autoComplete='tel'
          value={number}
          onInput={(e) => setNumber(e.currentTarget.value)}
          onBlur={handlePhoneNumberBlur}
        />
      </div>
      {phoneNumberFeedback && <span className='form-feedback'>{phoneNumberFeedback}</span>}
    </div>
  );
};

export default PhoneNumber;

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import {
  RadioPracticalExercise,
  RadioText,
  RadioTextRecording,
  RadioTextSubmission,
  Vocabulary,
} from '../interfaces/radio-course';

export const getVocabulary = async (identifier: string) => {
  const res = await makeApiRequest<Vocabulary[]>('GET', `v1/courses/${identifier}/vocabulary`);

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getRadioTexts = async (identifier: string) => {
  const res = await makeApiRequest<RadioText[]>('GET', `v1/courses/${identifier}/radio-text`);

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getRadioTextSubmissions = async (identifier: string) => {
  const res = await makeApiRequest<RadioTextSubmission[]>(
    'GET',
    `v1/courses/${identifier}/radio-text-submissions`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getRadioTextRecordings = async (identifier: string) => {
  const res = await makeApiRequest<RadioTextRecording[]>(
    'GET',
    `v1/courses/${identifier}/radio-text-recordings`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getRadioPracticalExercises = async (identifier: string) => {
  const res = await makeApiRequest<RadioPracticalExercise[]>(
    'GET',
    `v1/courses/${identifier}/radio-practical-exercises`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

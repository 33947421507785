import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { getRadioPracticalExercises } from '../../api/courses/radio';
import { CourseType } from '../../api/interfaces/course';
import { RadioPracticalExercise } from '../../api/interfaces/radio-course';
import B1Button from '../../components/buttons/B1Button';
import B1Markdown from '../../components/markdown/B1Markdown';
import { isApiError } from '../../utils/api-util';
import Layout from '../Layout';

import './RadioPracticalExercises.scss';

const RadioPracticalExercises: React.FC = () => {
  const { identifier, exerciseId } = useParams<{ identifier: string; exerciseId: string }>();
  const [practicalExercises, setPracticalExercises] = useState<RadioPracticalExercise[]>([]);
  const [practicalExercise, setPracticalExercise] = useState<RadioPracticalExercise>();
  const practicalExercisesCircles = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getRadioPracticalExercises(identifier).then((res) => {
      if (isApiError(res)) {
        console.log(res);
      } else {
        setPracticalExercises(res);

        if (exerciseId) {
          setPracticalExercise(res.find((e) => e.id === Number(exerciseId)));
        } else {
          setPracticalExercise(res[0]);
        }
      }
    });
  }, [identifier, exerciseId]);

  const getCircleClass = (id: number) => {
    const classes = ['practical-exercise-circle', 'scale-effect'];

    if (id === Number(exerciseId) || (!exerciseId && id === practicalExercises[0].id)) {
      classes.push('practical-exercise-active');
    } else if (id != Number(exerciseId)) {
      classes.push('practical-exercise-inactive');
    }

    return classes.join(' ');
  };

  const getPrevExerciseId = () => {
    const index = exerciseId ? practicalExercises.findIndex((e) => e.id === Number(exerciseId)) : 0;

    if (index === -1 || index === 0) {
      return null;
    }

    return practicalExercises[index - 1].id;
  };

  const getNextExerciseId = () => {
    const index = exerciseId ? practicalExercises.findIndex((e) => e.id === Number(exerciseId)) : 0;

    if (index === -1 || index === practicalExercises.length - 1) {
      return null;
    }

    if (practicalExercises[index + 1]) {
      return practicalExercises[index + 1].id;
    } else {
      return null;
    }
  };

  return (
    <Layout
      contentClassName='radio-practical-exercises'
      backButton={`/course/${identifier}`}
      currentCourseType={identifier}
    >
      <div className='radio-practical-exercises-container'>
        <div className='simple-card card-light-gray mb-5'>
          <p>
            Hier findest Du nun Beispiele für die Fertigkeiten, die in der praktischen Prüfung zu
            erledigen sind. Dir werden in der Prüfung vier Pflichtaufgaben und drei sonstige
            Fertigkeiten gestellt.
          </p>
          <B1Button
            href='https://cdn.bootsschule1.app/courses/SRC/Vorlagen/Praxisaufgaben_vorlage.pdf'
            downloadAndOpen
            className='my-4 mx-auto'
          >
            PDF-Vorlage
          </B1Button>
          <p>
            Nutze diese Vorlage, um die Aufgaben zu lösen. Bestimme für jede Aufgabe den Rang, die
            Bedienung des DSC-Controllers, die Einstellung am Funkgerät und die zu sprechende
            Meldung.
          </p>
        </div>

        <div className='radio-practical-exercises-circles' ref={practicalExercisesCircles}>
          {practicalExercises.map((exercise, index) => (
            <Link
              key={index}
              className={getCircleClass(exercise.id)}
              to={`/course/${identifier}/radio-practical-exercises/${exercise.id}`}
            >
              {exercise.nr}
            </Link>
          ))}
        </div>
        <div className='simple-card card-white'>
          <h5 className='mb-3'>Praxisaufgabe {practicalExercise?.nr}</h5>
          <B1Markdown className='break-spaces'>{practicalExercise?.question_text}</B1Markdown>
        </div>
        <Accordion key={exerciseId} className='b1-accordion' defaultActiveKey={undefined}>
          <Accordion.Item eventKey={'0'}>
            <Accordion.Header>Lösung</Accordion.Header>
            <Accordion.Body>
              <Row className='mb-3'>
                <Col md='4' lg='3'>
                  <p>
                    <strong>Rang:</strong>
                  </p>
                </Col>
                <Col>
                  <B1Markdown className='break-spaces'>{practicalExercise?.answer.rank}</B1Markdown>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md='4' lg='3'>
                  <p>
                    <strong>
                      {identifier.toUpperCase() === CourseType.SRC
                        ? 'DSC-Controller'
                        : 'Vorbereitung'}
                      :
                    </strong>
                  </p>
                </Col>
                <Col>
                  <B1Markdown className='break-spaces'>
                    {practicalExercise?.answer.dsc_controller}
                  </B1Markdown>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md='4' lg='3'>
                  <p>
                    <strong>Funkgerät:</strong>
                  </p>
                </Col>
                <Col>
                  <B1Markdown className='break-spaces'>
                    {practicalExercise?.answer.radio}
                  </B1Markdown>
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col md='4' lg='3'>
                  <p>
                    <strong>Meldung:</strong>
                  </p>
                </Col>
                <Col>
                  <B1Markdown className='break-spaces'>
                    {practicalExercise?.answer.report}
                  </B1Markdown>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Row className='buttons gy-3'>
          <Col xs='12' lg='6'>
            {getPrevExerciseId() ? (
              <B1Button
                className='button-block button-reverse'
                href={`/course/${identifier}/radio-practical-exercises/${getPrevExerciseId()}`}
              >
                <BsArrowLeft />
                Zurück
              </B1Button>
            ) : (
              <B1Button className='button-block button-reverse' href={`/course/${identifier}`}>
                Zur Übersicht
              </B1Button>
            )}
          </Col>
          <Col xs='12' lg='6'>
            {getNextExerciseId() ? (
              <B1Button
                className='button-block'
                href={`/course/${identifier}/radio-practical-exercises/${getNextExerciseId()}`}
              >
                Weiter
                <BsArrowRight />
              </B1Button>
            ) : (
              <B1Button className='button-block' href={`/course/${identifier}`}>
                Zur Übersicht
              </B1Button>
            )}
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default RadioPracticalExercises;

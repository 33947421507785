import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { Trips } from '../interfaces/trip';

export const getTrips = async () => {
  const res = await makeApiRequest<Trips>('GET', 'v1/courses/sks/trips');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const submitTripRequest = async (trip_id: number) => {
  const res = await makeApiRequest('POST', 'v2/courses/sks/trips', undefined, {
    trip_id,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const requestNavSet = async () => {
  const res = await makeApiRequest('POST', 'v1/courses/sks/nav-set');

  if (isApiError(res)) {
    return res;
  }

  return true;
};

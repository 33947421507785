import { IonContent, IonFooter, IonModal, useIonViewWillLeave } from '@ionic/react';
import React, { PropsWithChildren, useRef } from 'react';

import './B1Modal.scss';

interface ContentWrapperProps extends PropsWithChildren {
  mode?: 'ios' | 'md';
  presentingElement?: HTMLElement;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ mode, presentingElement, children }) => {
  if (mode === 'ios' && presentingElement) {
    return <IonContent>{children}</IonContent>;
  } else {
    return <>{children}</>;
  }
};

interface B1ModalProps extends PropsWithChildren, React.HTMLAttributes<HTMLIonModalElement> {
  open: boolean;
  onModalClose: () => void;
  onModalOpen?: () => void;
  onModalWillClose?: () => void;
  onModalWillOpen?: () => void;
  topContent?: React.ReactNode;
  footer?: React.ReactNode;
  backdropDismiss?: boolean;
  mode?: 'ios' | 'md';
  presentingElement?: HTMLElement;
}

const B1Modal: React.FC<B1ModalProps> = ({
  open: modalOpen,
  onModalClose,
  onModalOpen,
  onModalWillClose,
  onModalWillOpen,
  topContent,
  children,
  backdropDismiss = true,
  footer,
  ...props
}) => {
  const modal = useRef<HTMLIonModalElement>(null);

  useIonViewWillLeave(() => {
    onModalClose();
  }, []);

  return (
    <IonModal
      {...props}
      ref={modal}
      className={
        (props.mode === 'ios' && props.presentingElement ? 'b1-sheet-modal' : 'b1-modal') +
        (props.className ? ' ' + props.className : '')
      }
      isOpen={modalOpen}
      onIonModalDidDismiss={onModalClose}
      onIonModalDidPresent={onModalOpen}
      onIonModalWillDismiss={onModalWillClose}
      onIonModalWillPresent={onModalWillOpen}
      backdropDismiss={backdropDismiss}
    >
      <ContentWrapper mode={props.mode} presentingElement={props.presentingElement}>
        {topContent}
        <button className='close-modal-top-button scale-effect' onClick={onModalClose}>
          <img src='assets/icons/close-icon-grey.svg' />
        </button>
        <div className='modal-container custom-scrollbar'>
          <div className='modal-content'>{children}</div>
        </div>
      </ContentWrapper>
      {footer && <IonFooter>{footer}</IonFooter>}
    </IonModal>
  );
};

export default B1Modal;

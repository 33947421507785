import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { BiChevronRight, BiInfoCircle } from 'react-icons/bi';

import { getChapterResources } from '../../../api/courses/learn-mode';
import Course, { Chapter, ChapterResources } from '../../../api/interfaces/course';
import VideoPlayer from '../../../components/video-player/VideoPlayer';
import { isApiError } from '../../../utils/api-util';
import NextButton from './NextButton';
import PdfViewer from './PdfViewer';

import './ChapterContent.scss';

interface ChapterContentProps {
  course?: Course;
  identifier: string;
  chapterId: number;
  hideNextButton?: boolean;
}

const ChapterContent: React.FC<ChapterContentProps> = ({
  course,
  identifier,
  chapterId,
  hideNextButton,
}) => {
  const [lessonId, setLessonId] = useState<number>(0);
  const [chapter, setChapter] = useState<Chapter>();
  const [chapterResources, setChapterResources] = useState<ChapterResources>();

  useEffect(() => {
    if (!isNaN(chapterId)) {
      getChapterResources(identifier, chapterId).then((res) => {
        if (isApiError(res)) {
          console.error(res);
        } else {
          setChapterResources(res);
        }
      });
    }
  }, [identifier, chapterId]);

  useEffect(() => {
    if (course) {
      for (let i = 0; i < course.lessons.length; i++) {
        const lesson = course.lessons[i];
        const chapter = lesson.chapters.find((c) => c.id === chapterId);

        if (chapter) {
          setLessonId(i);
          setChapter(chapter);
        }
      }
    }
  }, [course]);

  return (
    <div className='chapter-content'>
      <h1>
        Lektion {lessonId + 1}: {chapter?.title}
      </h1>
      {chapterResources?.videos?.map((video, id) => <VideoPlayer key={id} video={video} />)}
      {chapterResources?.pictures?.map((picture, id) => (
        <img key={id} className='chapter-picture' src={picture} />
      ))}
      <PdfViewer url={chapterResources?.doc_link} />
      {chapterResources?.signals && (
        <div className='chapter-signals'>
          {chapterResources?.signals?.map((signalGroup, signalGroupId) => (
            <div key={signalGroupId} className='chapter-signal-group'>
              <h3>{signalGroup.title}</h3>
              {signalGroup.signals.map((signal, signalId) => (
                <div key={signalId} className='chapter-signal-item'>
                  {signal.picture_link && <img src={signal.picture_link} />}
                  <div className='chapter-signal-content'>
                    <p>{signal.text}</p>
                    {signal.audio_link && <audio src={signal.audio_link} controls />}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
      {chapterResources?.beacons && (
        <div className='chapter-beacons'>
          <div className='info-text mb-2 d-flex d-sm-none align-items-center gap-2'>
            <BiInfoCircle />
            <p>Scrolle nach rechts, um die Darstellungen anzusehen.</p>
            <BiChevronRight />
          </div>
          <Table responsive>
            <thead>
              <tr>
                <th className='text-center'>Licht</th>
                <th>Abk. int</th>
                <th>Abk. deutsch</th>
                <th>Bezeichnung</th>
                <th>Darstellung</th>
              </tr>
            </thead>
            <tbody>
              {chapterResources?.beacons?.map((beacon, beaconId) => (
                <tr key={beaconId}>
                  <td>
                    <img src={beacon.light_link} />
                  </td>
                  <td>{beacon.abbreviation_int}</td>
                  <td>{beacon.abbreviation_german}</td>
                  <td className='beacon-description'>{beacon.title}</td>
                  <td>
                    <img className='representation-image' src={beacon.representation_link} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {!hideNextButton && (
        <NextButton
          identifier={identifier}
          course={course}
          chapter={chapter}
          chapterQuestions={chapterResources?.questions_count ?? 0}
        />
      )}
    </div>
  );
};

export default ChapterContent;

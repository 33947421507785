import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { getRadioTexts } from '../../../api/courses/radio';
import { RadioText } from '../../../api/interfaces/radio-course';
import B1Markdown from '../../../components/markdown/B1Markdown';
import NavigationCircles from '../../../components/navigation-circles/NavigationCircles';
import { isApiError } from '../../../utils/api-util';

import './RadioTexts.scss';

const RadioTextTranslation: React.FC<{ text: string; language: 'de' | 'en' }> = ({
  text,
  language,
}) => {
  return (
    <Accordion.Item eventKey={language}>
      <Accordion.Header>
        {language === 'de' ? 'Deutscher' : 'Englischer'} Text{' '}
        <img
          src={`assets/icons/${language === 'de' ? '1f1e9-1f1ea' : '1f1ec-1f1e7'}.svg`}
          alt={language === 'de' ? '🇩🇪' : '🇬🇧'}
          title={`Flagge von ${language === 'de' ? 'Deutschland' : 'Großbritannien'}`}
        />
      </Accordion.Header>
      <Accordion.Body>
        <B1Markdown>{text}</B1Markdown>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const RadioTexts: React.FC<{ identifier: string }> = ({ identifier }) => {
  const [radioTexts, setRadioTexts] = useState<RadioText[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    getRadioTexts(identifier).then((res) => {
      if (isApiError(res)) {
        console.error(res);
      } else {
        setRadioTexts(res);
      }
    });
  }, [identifier]);

  return (
    <div className='radio-texts'>
      <h1>Seefunktexte</h1>

      <div className='simple-card card-white'>
        <p>Hier findest Du die englischen Seefunktexte.</p>
        <p className='mb-3'>
          Höre sie Dir zuerst auf Englisch an, schreibe sie auf und übersetze sie dann ins Deutsche.
        </p>
        <p className='mb-3'>
          Darunter findest Du dann immer die deutsche Übersetzung als Lösung, mit der Du Deine
          Übersetzung vergleichen kannst.
        </p>
        <p className='mb-3'>
          In einem 2. Schritt nimmst Du dann die deutsche Version und übersetzt sie schriftlich ins
          Englische. Unter dem deutschen Text findest Du dann wieder den englischen Text, um Deine
          Übersetzung zu kontrollieren.
        </p>
        <p className='mb-3'>
          Tipp: Es gibt insgesamt 27 Seefunktexte, von denen jeweils zwei in der Prüfung vorkommen.
          Es empfiehlt sich also, alle 27 Texte durchzuarbeiten.
        </p>
        <p>Viel Erfolg!</p>
      </div>

      <NavigationCircles
        list={radioTexts}
        currentId={radioTexts[selectedIndex]?.id}
        onClick={(index) => setSelectedIndex(index)}
      />

      {radioTexts[selectedIndex] && (
        <>
          <div className='simple-card card-white'>
            <h3>Seefunktext {radioTexts[selectedIndex].nr}</h3>
            <audio src={radioTexts[selectedIndex].mp3_link} controls />
          </div>
          <Accordion
            className='b1-accordion b1-accordion-open-white'
            key={selectedIndex}
            defaultActiveKey={undefined}
            alwaysOpen
          >
            <RadioTextTranslation text={radioTexts[selectedIndex].text_english} language='en' />
            <RadioTextTranslation text={radioTexts[selectedIndex].text_german} language='de' />
          </Accordion>
        </>
      )}
    </div>
  );
};

export default RadioTexts;

import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { getNavigationTrainingExercises } from '../../../../api/courses/navigation-training';
import { requestNavSet } from '../../../../api/courses/sks';
import { CourseType } from '../../../../api/interfaces/course';
import { NavigationTrainingOverview } from '../../../../api/interfaces/navigation-training';
import B1Button from '../../../../components/buttons/B1Button';
import ChangeAddressModal from '../../../../components/modal/change-address-modal/ChangeAddressModal';
import { isApiError } from '../../../../utils/api-util';
import { getMainCourse } from '../../../../utils/course-util';
import Layout from '../../../Layout';

import './NavigationTraining.scss';

const NavigationTraining: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>();
  const [navigationTrainings, setNavigationTrainings] = useState<NavigationTrainingOverview[]>([]);
  const [changeAddressModalOpen, setChangeAddressModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getNavigationTrainingExercises(identifier).then((data) => {
      if (isApiError(data)) {
        console.error(data);
        return;
      }

      data.sort((a, b) => a.nr - b.nr);

      setNavigationTrainings(data);
    });
  }, [identifier]);

  const isSKS = identifier.toUpperCase() === CourseType.SKS;

  const handleRequestNavigationSet = async () => {
    setChangeAddressModalOpen(true);
  };

  const addressChanged = async () => {
    setChangeAddressModalOpen(false);

    const res = await requestNavSet();

    if (isApiError(res)) {
      if (res.statusCode === 400) {
        alert('Du hast bereits Navigationsmaterialien angefordert!');
      } else {
        console.error(res);
        alert('Es ist ein Fehler aufgetreten, bitte probiere es später erneut.');
      }
    } else {
      alert('Deine Navigationsmaterialien wurden erfolgreich angefordert!');
    }
  };

  return (
    <Layout
      backButton={`/course/${getMainCourse(identifier)}`}
      contentClassName='navigation-training'
      currentCourseType={identifier}
    >
      <div className='navigation-training-container'>
        <h1>{isSKS ? 'Kartenaufgaben' : 'Navigation Prüfungssimulation'}</h1>
        <Accordion className='b1-accordion'>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>
              Herzlich willkommen zu den{' '}
              {isSKS ? 'Kartenaufgaben' : 'Prüfungsaufgaben zur Navigation!'}
            </Accordion.Header>
            <Accordion.Body>
              {isSKS ? (
                <>
                  <p className='mb-3'>
                    Willkommen im spannenden Kapitel der Navigation! Um Dich dabei optimal zu
                    unterstützen, kannst Du hier Deine inkludierten Navigationsmaterialien
                    kostenfrei bestellen.
                  </p>

                  <B1Button className='button-centered mb-3' click={handleRequestNavigationSet}>
                    Navigationsmaterialien anfordern
                  </B1Button>

                  <p>
                    Hier erlernst Du die Kartenaufgaben {navigationTrainings[0]?.nr}-
                    {navigationTrainings[navigationTrainings.length - 1]?.nr}, wovon eine der
                    Aufgaben genauso in Deiner finalen Prüfung drankommt.
                  </p>
                  <p className='mb-3'>
                    Übe die Aufgaben mithilfe der großen D49 Seekarte. Zur Überprüfung schaust Du
                    Dir die Lösung in den Videos an. Im Kurs hast Du die Möglichkeit, die D49
                    Seekarte wie auch die Karte 1/INT 1 und das SKS Begleitheft kostenfrei
                    anzufordern, damit alles immer auf dem neusten Stand ist.
                  </p>

                  <p className='brand-blue mb-3'>
                    <strong>Erlaubte Hilfsmittel:</strong>
                  </p>
                  <p className='mb-3'>
                    Übungskarte 49 (INT 1463), Karte 1/INT 1, Begleitheft für die Kartenaufgaben im
                    Fach Navigation für den SKS (Ausgabe 2013), Taschenrechner (nicht programmiert
                    und nicht programmierbar), Navigationsbesteck und Schreibgeräte. Für die Prüfung
                    darf die Übungskarte 49 (INT 1463) keine Eintragungen und keine Markierungen
                    enthalten. Gegebenenfalls sind in die Übungskarte eingetragene Kurse, Peilungen
                    und Orte restlos auszuradieren. Die Karte 1/INT 1 und das Begleitheft dürfen
                    ebenfalls keine Markierungen, zusätzliche Eintragungen oder eingeklebte Reiter
                    enthalten.
                  </p>

                  <p className='brand-blue mb-3'>
                    <strong>Bestanden:</strong>
                  </p>
                  <p className='mb-3'>
                    Jede SKS-Kartenaufgabe umfasst meist 18 Teilaufgaben (außer Bogen 9 mit 16 und
                    Bogen 10 mit 17 Aufgaben). Jede Teilaufgabe ist ein bis drei Punkte wert, 30
                    Punkte sind erzielbar. Vereinzelt bauen Aufgaben aufeinander auf – dann kann ein
                    Fehler zu Beginn in großem Umfang Punkte kosten. Arbeite also sorgfältig,
                    insbesondere bei Aufgaben, deren Ergebnisse in die nächsten Fragen einfließen.
                  </p>
                  <p className='mb-3'>
                    Ab 20 Punkten hast Du bestanden, 19-17 führen zu einer mündlichen Nachprüfung,
                    16 und darunter ist ein Fehlversuch. Die SKS-Kartenaufgabe und der
                    SKS-Fragebogen sind zwei separate Prüfungsteile, die einzeln bestanden und
                    verfehlt werden können.
                  </p>
                  <p className='mb-3'>
                    Für die genauen Angaben, wie viele Punkte die jeweilige Teilaufgabe ergibt,
                    kannst Du folgende PDF herunterladen. Dort findest Du die Angabe mit “•”-Zeichen
                    markiert.
                  </p>
                  <B1Button
                    className='button-centered mb-3'
                    href='https://www.elwis.de/DE/Sportschifffahrt/Sportbootfuehrerscheine/Navigationsaufgaben-SKS.pdf?__blob=publicationFile&v=2'
                    downloadAndOpen
                  >
                    Navigationsaufgaben SKS
                  </B1Button>
                  <p className='mb-3'>
                    Zudem findest Du hier für Dein persönliches Wissen noch die offiziellen Vorgaben
                    für Prüfer im Bereich SKS Navigation:
                  </p>
                  <B1Button
                    className='button-centered mb-3'
                    href='https://cdn.bootsschule1.app/courses/SKS/Kartenaufgaben/Vorgaben_fuer_Pruefer_SKS.pdf'
                    downloadAndOpen
                  >
                    Vorgaben für Prüfer
                  </B1Button>

                  <p className='brand-blue mb-3'>
                    <strong>Folgefehler:</strong>
                  </p>

                  <p className='mb-3'>
                    Teilaufgaben, die auf einer falsch gelösten Teilaufgabe aufbauen, wer­den als
                    rich­tig be­wer­tet, wenn sie zwar eine fal­sche Lö­sung ha­ben, aber lo­gisch
                    rich­tig ge­löst wur­den.
                  </p>

                  <p>Wir wünschen Dir viel Erfolg!</p>
                </>
              ) : (
                <>
                  <p>
                    Hier erlernst Du die Navigationsaufgaben {navigationTrainings[0]?.nr}-
                    {navigationTrainings[navigationTrainings.length - 1]?.nr}, wovon eine der
                    Aufgaben genauso in Deiner finalen Prüfung drankommt.
                  </p>
                  <p className='mb-3'>
                    Übe die Aufgaben mithilfe der Kartenausschnitte, die Deinem Willkommenspaket
                    beiliegen. Zur Überprüfung schaust Du dir den jeweiligen Ausschnitt mit der
                    Lösung in den Videos an.
                  </p>

                  <p className='brand-blue mb-3'>
                    <strong>Achtung:</strong> Folgende <strong>Abweichungen</strong> werden noch als
                    richtig gewertet:
                  </p>
                  <ul className='mb-3'>
                    <li>Allgemein: bis zu 0,1 sm bzw. 0,1′ Abweichung</li>
                    <li>Kartenkurs: Bis zu +/- 1° Abweichung</li>
                    <li>
                      Besteckversetzung: +/- 5° Abweichung. Bei sehr kurzen Strecken (&gt; 1sm)
                      sogar bis zu 10°
                    </li>
                  </ul>

                  <p className='brand-blue mb-3'>
                    <strong>Folgefehler:</strong>
                  </p>

                  <p className='mb-3'>
                    Teilaufgaben, die auf einer falsch gelösten Teilaufgabe aufbauen, wer­den als
                    rich­tig be­wer­tet, wenn sie zwar eine fal­sche Lö­sung ha­ben, aber lo­gisch
                    rich­tig ge­löst wur­den.
                  </p>

                  <p className='brand-blue mb-3'>
                    <strong>Bestanden:</strong>
                  </p>
                  <p className='mb-3'>
                    Du bekommst in der Prüfung eine der 15 Navigationsaufgaben mit ihren neun
                    Unteraufgaben. Bestanden ist die Prüfung, wenn mindestens 7 der 9 Unteraufgaben
                    zu einer Navigationsaufgabe richtig gelöst sind.
                  </p>

                  <p>Wir wünschen Dir viel Erfolg!</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <h3>Inhaltsverzeichnis</h3>
        <div className='navigation-training-cards'>
          {navigationTrainings.map((exercise, index) => (
            <div key={index} className='navigation-training-card-container'>
              <Link
                to={`/course/${identifier}/navigation-training/${exercise.id}`}
                className='navigation-training-card scale-effect'
                draggable={false}
              >
                <h6>{exercise.nr}</h6>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <ChangeAddressModal
        open={changeAddressModalOpen}
        setOpen={setChangeAddressModalOpen}
        submitButtonText='Absenden'
        onAddressChanged={addressChanged}
      />
    </Layout>
  );
};

export default NavigationTraining;

import { useIonRouter } from '@ionic/react';
import React, { useContext } from 'react';

import AddressForm from '../../../components/forms/address-form/AddressForm';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import { AuthContext } from '../../../providers/AuthProvider';
import Layout from '../../Layout';

import '../../LoginRegister.scss';

const RegisterAddress: React.FC = () => {
  const { register } = useContext(AuthContext);
  const [registrationData] = useRegistrationDataState();
  const ionRouter = useIonRouter();

  async function submitAddress() {
    if (!registrationData.code) {
      alert('Es wurde kein Code eingegeben!');
      ionRouter.push('/login/code', 'back', 'pop');
      return;
    }

    if (registrationData.code.courses.find((course) => course.driver_license_needed)) {
      ionRouter.push('/register/driver-license');
      return;
    }

    const registrationResult = await register(registrationData.data, registrationData.code);

    if (registrationResult) {
      ionRouter.push('/register/success');
    } else {
      // TODO: Fehlermeldung anzeigen
    }
  }

  return (
    <Layout backButton='/register/personal-data'>
      <div className='register-page register-page-address'>
        <h2 className='mb-3'>Vervollständige dein Profil</h2>

        <AddressForm onSubmit={submitAddress} />
      </div>
    </Layout>
  );
};

export default RegisterAddress;

import { HttpParams, HttpResponse } from '@capacitor/core';
import axios, { AxiosResponse } from 'axios';

import { makeApiError } from '../utils/api-util';

const API_URL = import.meta.env.VITE_BASE_API_URL;

const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const makeApiRequest = async <T>(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  url: string,
  params?: HttpParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
) => {
  let res: HttpResponse | AxiosResponse<T>;

  try {
    res = await axiosInstance.request<T>({
      url: url,
      method: method,
      params: params,
      data: data,
    });
  } catch (err) {
    return makeApiError(err);
  }

  return {
    data: <T>res.data,
    status: res.status,
  };
};

import { IonSkeletonText } from '@ionic/react';
import React, { useEffect } from 'react';
import { Ratio } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

import { getExamBookingInfo } from '../../../../api/courses/exam-booking';
import { CourseType } from '../../../../api/interfaces/course';
import Video from '../../../../api/interfaces/video';
import B1Button from '../../../../components/buttons/B1Button';
import CustomLink from '../../../../components/custom-link/CustomLink';
import VideoPlayer from '../../../../components/video-player/VideoPlayer';
import { isApiError } from '../../../../utils/api-util';
import Layout from '../../../Layout';

import './ExamBookingFKN.scss';

const ExamBookingFKN: React.FC = () => {
  const [introVideo, setIntroVideo] = React.useState<Video | null>();

  useEffect(() => {
    getExamBookingInfo(CourseType.FKN).then((res) => {
      if (isApiError(res)) {
        setIntroVideo(null);
        console.error(res);
        return;
      }

      setIntroVideo(res.video ?? null);
    });
  }, []);

  return (
    <Layout
      contentClassName='exam-booking-fkn'
      backButton='/course/fkn'
      currentCourseType={CourseType.FKN}
    >
      <div className='exam-booking-container'>
        <h1>FKN-Prüfung buchen</h1>

        <div className='simple-card card-light-gray mb-1'>
          <h4 className='mb-4'>Anmeldung zur Prüfung</h4>
          {introVideo ? (
            <VideoPlayer video={introVideo} className='mb-5' />
          ) : introVideo === undefined ? (
            <Ratio aspectRatio='16x9' className='mb-5'>
              <IonSkeletonText animated />
            </Ratio>
          ) : (
            <></>
          )}
          <p className='mb-1 brand-blue'>
            <strong>Mindestens 14 Tage vor dem Prüfungstermin</strong> musst Du Dich für die Prüfung
            anmelden.
          </p>
          <p className='mb-3'>Dabei ist folgendes einzureichen:</p>
          <div className='exam-info-card-body'>
            <ul className='mb-4'>
              <li>
                Antrag auf Zulassung FKN
                <div className='download-applications-buttons'>
                  <B1Button
                    className='button-sm mr-2'
                    href='https://www.sportbootfuehrerscheine.org/downloads/zulassungsantraege/fkn-antrag-zulassung/'
                    downloadAndOpen
                  >
                    PDF beim DSV
                  </B1Button>
                  <B1Button
                    className='button-sm'
                    href='https://www.dmyv.de/fileadmin/content/pdf/downloads/fuehrerschein/fkn/FKN-Antrag_auf_Zulassung_zur_Pruefung_2022_4.pdf'
                    downloadAndOpen
                  >
                    PDF beim DMYV
                  </B1Button>
                </div>
              </li>
              <li>
                Sportbootführerschein oder Befähigungsnachweis zum Führen von Wassersportfahrzeugen
                in Fotokopie
              </li>
              <li>
                Reisepass oder Personalausweis in Kopie (
                <span className='brand-blue'>Original in der Prüfung vorlegen!</span>)
              </li>
              <li>Gebühren: Rund 28,89 €, abhängig vom Prüfungsausschuss</li>
            </ul>
          </div>
        </div>

        <div className='simple-card card-light-gray mb-5'>
          <h4 className='mb-4'>Standort und Termin der Prüfung</h4>
          <div className='exam-info-card-body'>
            <h5 className='mb-3'>DSV-Prüfung:</h5>
            <B1Button
              className='button-block mb-3'
              href='https://www.sportbootfuehrerscheine.org/pruefungen/pruefungstermine/'
              external
            >
              Prüfung beim DSV buchen
            </B1Button>
            <p className='mb-3'>
              Dort wählst Du in der Leiste als Befähigungsnachweis „FKN“ aus und gibst Deine
              Postleitzahl ein. Du findest nun eine Liste der Termine und kannst auf dem Button
              „Onlineantrag“ die Buchung durchführen. Trotz Online-Buchung müssen die oben stehenden
              Unterlagen verschickt werden.
            </p>
            <h5 className='mb-3'>DMYV-Prüfung:</h5>
            <p>
              Das Buchen der Prüfung beim DMYV ist etwas komplizierter. Wähle{' '}
              <CustomLink href='https://www.dmyv.de/fuehrerschein/-funk/pruefungen/pruefungsausschuesse'>
                hier
              </CustomLink>{' '}
              einen passenden Prüfungsausschuss aus, gehe dann auf dessen Website und nutze dort den
              jeweiligen Button, um die Termine anzeigen zu lassen. Teilweise kannst Du Dich hier
              auch online anmelden, teilweise geht dies leider nicht. Ein Anruf beim jeweiligen
              Prüfungsausschuss ist vor der Prüfung immer empfehlenswert.
            </p>
          </div>
        </div>

        <h3 className='mb-4'>FKN-Prüfung FAQ</h3>

        <Accordion className='b1-accordion' defaultActiveKey={undefined}>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Wie läuft die Prüfung ab?</Accordion.Header>
            <Accordion.Body>
              <p className='mb-3'>
                Die Prüfung besteht aus einem theoretischen und einem „praktischen“ Teil.
              </p>

              <h5 className='mb-3'>Theorie:</h5>
              <ul className='mb-5'>
                <li>In der Theorie muss ein Fragebogen mit 15 Fragen ausgefüllt werden.</li>
                <li>Jede Antwort wird mit 0 bis 2 Punkten bewertet.</li>
                <li>Eine Antwort wird mit 0 Punkten bewertet, wenn die Antwort falsch ist.</li>
                <li>
                  Eine Antwort wird mit 1 Punkt bewertet, wenn die Antwort korrekt ist, aber
                  wesentliche Teile der Musterantwort fehlen.
                </li>
                <li>
                  Eine Antwort wird mit 2 Punkten bewertet, wenn sie in den wesentlichen Teilen mit
                  der Musterantwort übereinstimmt.
                </li>
                <li>30 Punkte sind erreichbar, 24 müssen erreicht werden.</li>
                <li>
                  Die Prüfungsfragebogen sind im Online-Kurs enthalten, sodass jeder seinen
                  Wissensstand vor der Prüfung testen kann.
                </li>
                <li>Die Bearbeitungszeit beträgt 30 Minuten.</li>
              </ul>

              <h5 className='mb-3'>Praxis:</h5>
              <ul className='mb-5'>
                <li>
                  Im „praktischen“ Teil muss an Dummies die sichere Handhabung von
                  Seenotsignalmitteln im tatsächlichen Gebrauch vorgeführt werden.
                </li>
                <li>
                  <p className='mb-3'>
                    Von den folgenden fünf Aufgaben wählt der Prüfer vier aus. Du musst drei der
                    vier Aufgaben im ersten Anlauf korrekt absolvieren:
                  </p>
                  <ul>
                    <li>Handhabung einer Fallschirm-Signalrakete (rot)</li>
                    <li>Handhabung einer Rauchfackel (orange) bzw. Handfackel (rot)</li>
                    <li>Handhabung des Rauchsignals (orange/Dose)</li>
                    <li>Handhabung eines Signalgebers mit Magazin/Trommel</li>
                    <li>
                      Handhabung von nicht gezündeten Signalmitteln/Versagern (nur DSV-Prüfungen)
                    </li>
                  </ul>
                </li>
                <li>Die Bedienung aller Signalmittel wird im Kurs ausführlich erläutert.</li>
              </ul>

              <p>
                Viele Prüfungsausschüsse bieten die Möglichkeit an, die FKN-Prüfung am gleichen Tag
                nach der Prüfung zum SBF Binnen und/oder SBF See abzulegen, damit die Bewerber nicht
                erneut zum Prüfungsausschuss fahren müssen, um die FKN-Prüfung abzulegen.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Wie lange dauert die Prüfung?</Accordion.Header>
            <Accordion.Body>
              <ul className='mb-4'>
                <li>Für die Theoriefragen hast Du 30 Minuten Zeit.</li>
                <li>Der praktische Teil geht etwa 10 Minuten.</li>
              </ul>

              <p>
                Reserviere Dir jedoch am besten den ganzen Prüfungstag. Du wirst einen Großteil des
                Tages mit Warten verbringen, weil außer Dir noch weitere Kandidaten die Prüfung
                ablegen.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>Was passiert, wenn ich durchfalle?</Accordion.Header>
            <Accordion.Body>
              <p className='mb-5'>
                Wenn Du nur den praktischen Teil oder nur den theoretischen Teil der Prüfung nicht
                bestehst, musst Du{' '}
                <strong>den nicht bestandenen Teil innerhalb von 12 Monaten wiederholen</strong>.
                Versäumst Du diese Frist, muss die gesamte Prüfung erneut abgelegt werden.
              </p>
              <p className='mb-5'>
                Durchgefallen gilt die Theorieprüfung, wenn Du weniger als 24 Punkte erreichst. Die
                Praxisprüfung hingegen gilt als durchgefallen, wenn Du weniger als drei der vier
                gestellten Aufgaben nicht im ersten Anlauf korrekt absolvierst oder wenn Du eine
                Signalrakete oder einen Signalgeber auf Dich selbst oder eine andere Person
                richtest.
              </p>
              <p className='mb-5'>
                Beide Prüfungsteile können dabei beliebig oft wiederholt werden.
              </p>
              <p>
                Mach Dir jedoch keine Sorgen, bei unserem Kurs wirst Du optimal auf die Theorie- und
                Praxisprüfung vorbereitet.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='3'>
            <Accordion.Header>Welche Kosten kommen noch auf mich zu?</Accordion.Header>
            <Accordion.Body>
              <p>
                Auf Dich kommt nur noch die Prüfungsgebühr zu, diese beträgt für den FKN 28,89 €.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='4'>
            <Accordion.Header>Kann die Prüfung auch online absolviert werden?</Accordion.Header>
            <Accordion.Body>
              <p>
                Nein, die FKN-Prüfung ist ebenso wie die Prüfung zum Sportbootführerschein eine
                amtliche Prüfung, und diese wird unter Aufsicht von amtlich bestellten Prüfern
                abgelegt.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='5'>
            <Accordion.Header>
              Ab welchem Alter kann die Prüfung absolviert werden?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Um die Prüfung zum FKN zu absolvieren, muss man mind. 16 Jahre alt sein. Eine
                Absolvierung vor dem 16. Geburtstag, wie es beim SBF möglich ist, ist beim FKN
                ausgeschlossen.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='6'>
            <Accordion.Header>Brauche ich nicht noch eine praktische Ausbildung?</Accordion.Header>
            <Accordion.Body>
              <p className='mb-5'>
                Nein, der Online-Kurs widmet sich sehr ausführlich dem praktischen Einsatz und
                erklärt Dir Schritt für Schritt die Handhabung. Du lernst und siehst ganz genau, wie
                die Signalmittel eingesetzt werden. Zudem erfährst Du, welche Fragen die Prüfer in
                der praktischen Prüfung gerne stellen.
              </p>
              <p>
                Übrigens werden pyrotechnische Signale sowieso nicht abgeschossen. Das ist verboten.
                Es wird immer nur mit Dummies hantiert. Auf den Signalen ist die Bedienung in
                Piktogrammen aufgedruckt. Natürlich kannst Du Dir auch während der Prüfung die
                Piktogramme anschauen.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='7'>
            <Accordion.Header>Wofür brauche ich den Pyroschein?</Accordion.Header>
            <Accordion.Body>
              <p>
                Die amtliche Bezeichnung für den Pyroschein lautet „Fachkundenachweis (FKN) für
                Seenotsignalmittel gemäß § 1 Absatz 3.1 Sprengstoffverordnung“. Sobald jemand die
                Verfügungsgewalt über pyrotechnische Seenotsignale der Kategorie P2 (z.B.
                Fallschirmraketen) hat, benötigt er den Pyroschein. Für Charterboote sind solche
                Seenotsignale vorgeschrieben. Um ein Boot auszuleihen, muss der Pyroschein vorgelegt
                werden. Das kontrolliert auch die Wasserschutzpolizei; Verstöße werden hart
                geahndet. Die meisten Sportboote wurden freiwillig mit Fallschirmraketen ausgerüstet
                – auch wenn es für sie gar nicht vorgeschrieben ist. Auch für diese Boote wird ein
                Pyroschein gebraucht.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </Layout>
  );
};

export default ExamBookingFKN;

import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { CourseType } from '../../../../api/interfaces/course';
import B1Button from '../../../../components/buttons/B1Button';
import CustomLink from '../../../../components/custom-link/CustomLink';
import Layout from '../../../Layout';

import './ExamBookingSRC.scss';

const ExamBookingSRC: React.FC = () => {
  return (
    <Layout
      contentClassName='exam-booking-src'
      backButton='/course/src'
      currentCourseType={CourseType.SRC}
    >
      <div className='exam-booking-container'>
        <h1>SRC-Prüfung buchen</h1>

        <div className='simple-card card-light-gray mb-1'>
          <h4 className='mb-4'>Anmeldung zur Prüfung</h4>
          <p className='mb-1 brand-blue'>
            <strong>Mindestens 14 Tage vor dem Prüfungstermin</strong> musst Du Dich für die Prüfung
            anmelden.
          </p>
          <p className='mb-3'>Dabei ist folgendes einzureichen:</p>
          <div className='exam-info-card-body'>
            <ul className='mb-4'>
              <li>
                Antrag auf Zulassung SRC
                <div className='download-applications-buttons'>
                  <B1Button
                    className='button-sm mr-2'
                    href='https://www.sportbootfuehrerscheine.org/downloads/src-short-range-certificate/antrag-zulassung-src/'
                    downloadAndOpen
                  >
                    PDF beim DSV
                  </B1Button>
                  <B1Button
                    className='button-sm'
                    href='https://www.dmyv.de/fileadmin/content/pdf/downloads/fuehrerschein/src/Antrag_auf_Zulassung_zur_Pruefung_SRC_2022_3.pdf'
                    downloadAndOpen
                  >
                    PDF beim DMYV
                  </B1Button>
                </div>
              </li>
              <li>
                Reisepass oder Personalausweis in Kopie (
                <span className='brand-blue'>Original in der Prüfung vorlegen!</span>)
              </li>
              <li>
                ggf. bereits vorhandene Funkscheine in Kopie (
                <span className='brand-blue'>Original in der Prüfung vorlegen!</span>)
              </li>
              <li>Lichtbild (35×45 mm)</li>
              <li>Bei Minderjährigen: Einverständniserklärung eines Erziehungsberechtigten</li>
              <li>Gebühren: Rund 127,88 €, abhängig vom Prüfungsausschuss</li>
            </ul>
          </div>
        </div>

        <div className='simple-card card-light-gray mb-5'>
          <h4 className='mb-4'>Standort und Termin der Prüfung</h4>
          <div className='exam-info-card-body'>
            <h5 className='mb-3'>DSV-Prüfung:</h5>
            <B1Button
              className='button-block mb-3'
              href='https://www.sportbootfuehrerscheine.org/pruefungen/pruefungstermine/'
              external
            >
              Prüfung beim DSV buchen
            </B1Button>
            <p className='mb-3'>
              Dort wählst Du in der Leiste als Befähigungsnachweis „SRC“ aus und gibst Deine
              Postleitzahl ein. Du findest nun eine Liste der Termine und kannst auf dem Button
              „Onlineantrag“ die Buchung durchführen. Trotz Online-Buchung müssen die oben stehenden
              Unterlagen verschickt werden.
            </p>
            <h5 className='mb-3'>DMYV-Prüfung:</h5>
            <p>
              Das Buchen der Prüfung beim DMYV ist etwas komplizierter. Wähle{' '}
              <CustomLink href='https://www.dmyv.de/fuehrerschein/-funk/pruefungen/pruefungsausschuesse'>
                hier
              </CustomLink>{' '}
              einen passenden Prüfungsausschuss aus, gehe dann auf dessen Website und nutze dort den
              jeweiligen Button, um die Termine anzeigen zu lassen. Teilweise kannst Du Dich hier
              auch online anmelden, teilweise geht dies leider nicht. Ein Anruf beim jeweiligen
              Prüfungsausschuss ist vor der Prüfung immer empfehlenswert.
            </p>
          </div>
        </div>

        <h3 className='mb-4'>SRC-Prüfung FAQ</h3>

        <Accordion className='b1-accordion' defaultActiveKey={undefined}>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>Wie läuft die Prüfung ab?</Accordion.Header>
            <Accordion.Body>
              <h5 className='mb-3'>1. Mündliche Prüfung</h5>
              <ul className='mb-5'>
                <li>
                  Die SRC-Prüfung beginnt mit einem kurzen Diktat. Hier wird ein bekannter
                  Seefunktext auf Englisch diktiert. Dieser muss mitgeschrieben und schriftlich ins
                  Deutsche übersetzt werden.
                </li>
                <li>
                  Dann muss ein bekannter deutscher Seefunktext schriftlich ins Englische übersetzt
                  werden. Alle 27 in Prüfungen verwendeten Seefunktexte findest Du in der Lektion
                  „Praxis 1“.
                </li>
              </ul>

              <h5 className='mb-3'>2. Theorieprüfung</h5>
              <ul className='mb-5'>
                <li>Danach muss ein Fragebogen bearbeitet werden.</li>
                <li>Er besteht aus 24 (von 180 möglichen) Fragen.</li>
                <li>
                  Es ist immer nur eine von vier Antworten richtig – sie muss angekreuzt werden.
                </li>
                <li>Maximal fünf Fehler sind erlaubt.</li>
                <li>
                  Alle SRC Prüfungsbogen findest Du unter dem Button „Prüfungsvorbereitung Theorie“
                  weiter oben auf der Seite
                </li>
              </ul>

              <h5 className='mb-3'>3. Praxisprüfung</h5>
              <ul>
                <li>Zuletzt erfolgt eine praktische Prüfung am Funkgerät.</li>
                <li>
                  Sie besteht aus vier Pflichtaufgaben und höchstens drei „sonstigen Fertigkeiten”.
                </li>
                <li>Für jede Aufgabe bzw. sonstige Fertigkeit hat man zwei Versuche.</li>
                <li>
                  Die praktische Prüfung ist bestanden, wenn alle vier Pflichtaufgaben und zwei
                  sonstige Fertigkeiten spätestens im zweiten Versuch mit ausreichendem Erfolg
                  absolviert werden.
                </li>
                <li>
                  Einige Übungsaufgaben findest Du unter dem Button „Prüfungsvorbereitung Praxis“
                  weiter oben auf der Seite
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='1'>
            <Accordion.Header>Wie lange dauert die Prüfung?</Accordion.Header>
            <Accordion.Body>
              <ul className='mb-4'>
                <li>Für die Theoriefragen hast Du 30 Minuten Zeit.</li>
                <li>
                  Die mündliche Prüfung (Diktats- und Übersetzungsaufgabe) ist 2x 15 Minuten lang
                </li>
                <li>Die praktische Prüfung geht insgesamt 15 Minuten.</li>
              </ul>

              <p>
                Reserviere Dir jedoch am besten den ganzen Prüfungstag. Du wirst einen Großteil des
                Tages mit Warten verbringen, weil außer Dir noch weitere Kandidaten die Prüfung
                ablegen.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='2'>
            <Accordion.Header>Was wird alles abgefragt?</Accordion.Header>
            <Accordion.Body>
              <h5 className='mb-3'>1. Theorieprüfung</h5>
              <ul className='mb-4'>
                <li>
                  Die SRC-Standard-Theorieprüfung besteht aus <strong>24 Prüfungsfragen</strong>,
                  von denen mindestens <strong>19 korrekt</strong> beantwortet werden müssen.
                </li>
                <li>Du findest alle Prüfungsbögen unter „Prüfungsvorbereitung Theorie“</li>
                <li>
                  Inhaltlich ist dies alles, was Du in den Lektionen Theorie 1 bis Theorie 6 gelernt
                  hast
                </li>
              </ul>
              <h5 className='mb-3'>2. Mündliche Prüfung</h5>
              <ul className='mb-4'>
                <li>
                  Diktatsaufgabe: Bei der Diktat-Aufgabe liest der Prüfer einen Seefunktext in
                  Englisch langsam vor. Eigennamen, schwierige Begriffe und Zahlen werden anhand der
                  internationalen Buchstabiertafel buchstabiert. Du schreibst den Text in Englisch
                  mit und übersetzt ihn anschließend auf Deutsch.
                </li>
                <li>
                  Übersetzungsaufgabe: Bei der Übersetzungs-Aufgabe wird Dir einer der 27 Funktexte
                  schriftlich auf Deutsch vorgelegt. Du übersetzt ihn schriftlich ins Englische.
                </li>
                <li>Beide Aufgaben kannst Du in der Lektion „Praxis 1“ lernen</li>
              </ul>
              <h5 className='mb-3'>3. Praxisprüfung</h5>
              <ul>
                <li>
                  Die praktische Prüfung besteht aus einer Reihe von{' '}
                  <strong>Pflichtaufgaben</strong> und <strong>sonstiger Fähigkeiten</strong>, die
                  an einer <strong>UKW-Seefunkanlage mit DSC</strong> durchgeführt werden müssen.
                </li>
                <li>
                  <span>
                    Pflichtaufgaben: Von den folgenden <strong>Pflichtaufgaben</strong> müssen{' '}
                    <strong>maximal vier Aufgaben</strong> gezeigt werden.
                  </span>
                  <ul className='mt-3'>
                    <li>Editieren eines DSC Controllers und Senden eines Notalarms</li>
                    <li>Speicherabfrage und Bestätigung des Empfangs eines DSC-Notalarms</li>
                    <li>Aussenden einer Notmeldung</li>
                    <li>
                      Weiterleiten eines Notalarms bzw. einer Notmeldung per Sprechfunk (Distress
                      Relay)
                    </li>
                    <li>Beenden des Notverkehrs</li>
                    <li>Aufhebung eines Fehlalarms</li>
                    <li>
                      Senden eines Dringlichkeitsanrufes und Abgabe einer Dringlichkeitsmeldung
                    </li>
                    <li>Senden eines Sicherheitsanrufs und Abgabe der Sicherheitsmeldung</li>
                  </ul>
                </li>
                <li>
                  <span>
                    Sonstige Fertigkeiten: Von den folgenden <strong>sonstigen Fähigkeiten</strong>{' '}
                    müssen <strong>maximal drei Aufgaben</strong> gelöst werden.
                  </span>
                  <ul className='mt-3'>
                    <li>DSC-Speicherabfrage und Empfangsbestätigung</li>
                    <li>Abwicklung des Notverkehrs</li>
                    <li>Funkstille gebieten</li>
                    <li>Abwicklung des Funkverkehrs vor Ort</li>
                    <li>Aufhebung einer Dringlichkeitsmeldung</li>
                    <li>
                      DSC-Controller editieren und Senden eines Routineanrufs an eine Seefunkstelle
                    </li>
                    <li>Kanalwechsel</li>
                    <li>Abwicklung des Routinefunkverkehrs mit einer Seefunkstelle</li>
                    <li>
                      DSC-Controller editieren und Senden eines Routineanrufs an eine
                      Küstenfunkstelle
                    </li>
                    <li>Abwicklung des Routinefunkverkehrs mit einer Küstenfunkstelle</li>
                    <li>Einstellen des DSC-Controllers</li>
                  </ul>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='3'>
            <Accordion.Header>Wann falle ich durch?</Accordion.Header>
            <Accordion.Body>
              <h5 className='mb-3'>1. Theorieprüfung</h5>
              <ul className='mb-4'>
                <li>
                  Die SRC-Standard-Theorieprüfung besteht aus 24 Prüfungsfragen, von denen
                  mindestens 19 korrekt beantwortet werden müssen.
                </li>
                <li>Zur Beantwortung der Fragen stehen 30 Minuten zur Verfügung.</li>
              </ul>
              <h5 className='mb-3'>2. Mündliche Prüfung</h5>
              <ul className='mb-4'>
                <li>
                  Bei der Diktat-Aufgabe liest der Prüfer einen Seefunktext in Englisch langsam vor.
                  Du schreibst den Text in Englisch mit und übersetzt ihn anschließend auf Deutsch.
                  Die <strong>Grundlage für die Bewertung der Diktat-Aufgabe</strong> ist der{' '}
                  <strong>deutsche Text, den Du übersetzt hast</strong>.
                </li>
                <li>
                  Bei der Übersetzungs-Aufgabe wird Dir einer der 27 Funktexte schriftlich auf
                  Deutsch vorgelegt. Du übersetzt ihn schriftlich ins Englische
                </li>
                <li>
                  Bei beiden Aufgaben gilt: Die Rechtschreibung, die richtige Kommasetzung etc. ist
                  bei der Übersetzung nicht entscheidend. Wichtig ist, dass Dein Text sämtliche
                  Informationen korrekt enthält.
                </li>
              </ul>
              <h5 className='mb-3'>3. Praxisprüfung</h5>
              <ul>
                <li>
                  Pflichtaufgaben: Die Pflichtaufgaben müssen mindestens im zweiten Versuch mit
                  jeweils ausreichendem Ergebnis absolviert werden. Wird eine der Aufgaben auch im
                  zweiten Versuch mit „nicht ausreichend“ bewertet, so ist die praktische Prüfung
                  nicht bestanden.
                </li>
                <li>
                  Sonstige Fertigkeiten: Von den sonstigen Fertigkeiten dürfen höchstens drei
                  Aufgaben gestellt werden, von denen mindestens zwei mit ausreichend bewertet
                  werden müssen.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='4'>
            <Accordion.Header>Welche Kosten kommen noch auf mich zu?</Accordion.Header>
            <Accordion.Body>
              <p>
                Auf Dich kommt nur noch die Prüfungsgebühr zu, diese beträgt für das SRC 127,88 €.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='5'>
            <Accordion.Header>
              Was passiert, wenn ich die Prüfung wiederholen muss?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Wenn Du nur den praktischen Teil oder nur den theoretischen Teil der Prüfung nicht
                bestehst, musst Du{' '}
                <strong>den nicht bestandenen Teil innerhalb von 6 Monaten</strong> wiederholen.
                Versäumst Du diese Frist, muss die gesamte Prüfung erneut abgelegt werden.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='6'>
            <Accordion.Header>Kann die Prüfung auch online absolviert werden?</Accordion.Header>
            <Accordion.Body>
              <p>
                Nein, die SRC-Prüfung ist ebenso wie die Prüfung zum Sportbootführerschein eine
                amtliche Prüfung, und diese wird unter Aufsicht von amtlich bestellten Prüfern
                abgelegt.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </Layout>
  );
};

export default ExamBookingSRC;

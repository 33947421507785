import { RouterOptions, useIonRouter } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';

import { FeedbackClass } from '../../../components/forms/forms';
import PasswordForm from '../../../components/password-form/PasswordForm';
import { AuthContext } from '../../../providers/AuthProvider';
import Layout from '../../Layout';

import '../../LoginRegister.scss';

const LoginResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { resetPassword } = useContext(AuthContext);
  const ionRouter = useIonRouter();

  const [feedback, setFeedback] = useState<string>();
  const [feedbackClass, setFeedbackClass] = useState<FeedbackClass>();

  const submitPassword = async (password: string) => {
    const result = await resetPassword(token, password);

    if (result === true) {
      ionRouter.push('/', 'forward', 'replace', {
        unmount: true,
        from: 'reset-password',
      } as RouterOptions);

      return true;
    } else {
      switch (result.statusCode) {
        case 400:
          setFeedback('Passwort entspricht nicht den Mindestanforderungen.');
          setFeedbackClass('warning');
          break;
        case 401:
          setFeedback('Ungültiger Passwort-Zurücksetzen-Link.');
          setFeedbackClass('warning');
          break;
        case 409:
          setFeedback(
            'Dein eingegebenes Passwort ist identisch mit dem altem Passwort, bitte verwende ein anderes.'
          );
          setFeedbackClass('warning');
          break;
        default:
          setFeedback('Unbekannter Fehler.');
          setFeedbackClass('warning');
          break;
      }

      return false;
    }
  };

  return (
    <Layout backButton='/login'>
      <div className='login-page login-page-reset-password'>
        <h2 className='mb-3'>Passwort zurücksetzen</h2>
        <h4 className='mb-4'>
          Schütze dich und deine persönlichen Daten. Bitte vergib ein sicheres Passwort für deinen
          Account.
        </h4>
        <PasswordForm onSubmit={submitPassword} feedback={feedback} feedbackClass={feedbackClass} />
      </div>
    </Layout>
  );
};

export default LoginResetPassword;

import Video from './video';

export enum RequestTime {
  AM = 'am',
  PM = 'pm',
}

export interface ExamPlace {
  id: number;
  location: string;
  lat: number;
  lng: number;
  info: string;
  link: string;
}

export interface UserExam {
  exam_request_date: string;
  exam_request_time: RequestTime;
  temp_license_wanted: boolean;
  place: string;
}

export interface ExamBookingInfo {
  video?: Video;
  exam_places: ExamPlace[];
  user_exam?: UserExam;
}

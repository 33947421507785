import * as React from 'react';
import { WithHeader, WithSeeMore } from 'react-insta-stories';
import { Renderer, Tester } from 'react-insta-stories/dist/interfaces';
import ReactPlayer from 'react-player';

export const renderer: Renderer = ({ story, action, isPaused, config, messageHandler }) => {
  const player = React.useRef<ReactPlayer>(null);

  const [playing, setPlaying] = React.useState(false);
  const [buffering, setBuffering] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  // const [muted, setMuted] = React.useState(false);

  const { width, height, loader, storyStyles } = config;

  const computedStyles = {
    ...styles.storyContent,
    ...(storyStyles || {}),
  };

  React.useEffect(() => {
    if (isPaused) {
      if (!buffering) {
        setPlaying(false);
      }
    } else {
      setPlaying(true);
    }
  }, [isPaused]);

  const onBuffer = () => {
    setBuffering(true);
    action('pause', true);
  };

  const onBufferEnd = () => {
    setBuffering(false);
    action('play', true);
  };

  const videoLoaded = () => {
    setLoaded(true);
    action('play');

    if (!loaded) {
      // We need to set a temporary duration to avoid the story from closing before the video has started playing
      messageHandler('UPDATE_VIDEO_DURATION', { duration: 10 });
    }
  };

  const onDuration = (duration: number) => {
    messageHandler('UPDATE_VIDEO_DURATION', { duration });
  };

  return (
    <WithHeader {...{ story, globalHeader: config.header! }}>
      <WithSeeMore {...{ story, action }}>
        <div style={styles.videoContainer}>
          <ReactPlayer
            ref={player}
            style={computedStyles}
            url={story.url}
            controls={false}
            playsinline
            onReady={videoLoaded}
            onDuration={onDuration}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            // muted={muted}
            className='story-react-player'
            width='100%'
            height='auto'
            playing={playing}
            config={{
              file: {
                attributes: {
                  crossOrigin: 'use-credentials',
                },
                // tracks: getSubtitles(),
                hlsOptions: {
                  // https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning
                  xhrSetup: (xhr: XMLHttpRequest) => {
                    xhr.withCredentials = true;
                  },
                  testBandwidth: true,
                  startLevel: -1, // automatic start level selection, playback will start from level matching download bandwidth (determined from download of first segment).
                },
              },
            }}
          />
          {!loaded && (
            <div
              style={{
                width: width,
                height: height,
                position: 'absolute',
                left: 0,
                top: 0,
                background: 'rgba(0, 0, 0, 0.9)',
                zIndex: 9,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#ccc',
              }}
            >
              {loader || <img src='assets/icons/story-loader.svg' />}
            </div>
          )}
        </div>
      </WithSeeMore>
    </WithHeader>
  );
};

const styles = {
  storyContent: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
  videoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
};

export const tester: Tester = (story) => {
  return {
    condition: story.type === 'video',
    priority: 2,
  };
};

export default {
  renderer,
  tester,
};

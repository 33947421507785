import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { IonModal, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import ReactInstaStories from 'react-insta-stories';
import { Story as ReactInstaStory } from 'react-insta-stories/dist/interfaces';

import { Story } from '../../api/interfaces/story';
import { addStoryPageLinkView, addStoryPageView, getStories } from '../../api/stories';
import useWindowSize from '../../hooks/window-size-hook';
import { isApiError } from '../../utils/api-util';
import StoryVideoPlayer from '../video-player/StoryVideoPlayer';

import './Stories.scss';

const Stories: React.FC = () => {
  const { width } = useWindowSize();

  const [storyModalOpen, setStoryModalOpen] = useState<boolean>(false);
  const [stories, setStories] = useState<Story[]>([]);
  const storyThumbnailRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [selectedStory, setSelectedStory] = useState<Story | null>(null);
  const [currentStoryPage, setCurrentStoryPage] = useState<number>(0);

  useIonViewWillEnter(() => {
    getStories().then((res) => {
      if (isApiError(res)) {
        console.log(res);
      } else {
        setStories(res);
      }
    });
  }, []);

  useEffect(() => {
    storyThumbnailRefs.current.forEach((ref) => {
      if (ref) {
        const imageUrl = ref.getAttribute('data-bg-image');

        if (imageUrl) {
          ref.style.setProperty('--bg-image', `url(${imageUrl})`);
        }
      }
    });
  }, [stories]);

  const getStoryPages = (story: Story) => {
    const pages: ReactInstaStory[] = story.pages.map((page) => ({
      url:
        page.type === 'picture'
          ? page.picture_path
          : page.type === 'video'
            ? page.video?.hls_link
            : undefined,
      type: page.type === 'picture' ? 'image' : page.type === 'video' ? 'video' : undefined,
      preloadResource: true,
      duration: (page.duration ?? 10) * 1000,
      seeMore: () => <></>,
      seeMoreCollapsed: ({ action }) => {
        if (!page.link) return <></>;

        return (
          <a
            href='https://bootsschule1.de'
            target='_blank'
            rel='noreferrer'
            onClick={async (e) => {
              action('pause');

              addStoryPageLinkView(page.id);

              if (Capacitor.isNativePlatform()) {
                e.preventDefault();

                const deviceInfo = await Device.getInfo();

                Browser.open({
                  url: 'https://bootsschule1.de',
                  windowName: '_blank',
                  presentationStyle: deviceInfo.model.includes('iPad') ? 'fullscreen' : 'popover',
                });
              }
            }}
            className='see-more'
          >
            <BsChevronUp />
            Mehr erfahren
          </a>
        );
      },
    }));

    return pages;
  };

  const logStoryPageView = (story: Story, pageIndex: number) => {
    setCurrentStoryPage(pageIndex);

    const page = story.pages[pageIndex];

    addStoryPageView(page.id);
  };

  // const setStoryPageViewed = (story: Story, pageIndex: number) => {
  //   story.pages[pageIndex].viewed = true;
  // };

  const getStoryPageIndex = (story: Story) => {
    const index = story.pages.findIndex((page) => !page.viewed);

    if (index === -1) return 0;

    return index;
  };

  const closeStoryModal = () => {
    if (selectedStory) {
      selectedStory.pages.forEach((page, index) => {
        if (index <= currentStoryPage) {
          page.viewed = true;
        }
      });
    }

    setStoryModalOpen(false);
    setSelectedStory(null);
  };

  if (stories.length === 0) return <></>;

  return (
    <div className='stories-container'>
      <div className='stories'>
        {stories.map((story, index) => (
          <div
            key={index}
            className={`story-border scale-effect${story.pages.every((p) => p.viewed) ? ' viewed' : ''}`}
          >
            <div
              ref={(el) => (storyThumbnailRefs.current[index] = el)}
              className='story-thumbnail'
              data-bg-image={story.thumbnail_path ?? '/assets/images/stories/boot.jpeg'}
              onClick={() => {
                if (story.pages.length > 0) {
                  setSelectedStory(story);
                  setStoryModalOpen(true);
                }
              }}
            >
              <h3>{story.title}</h3>
            </div>
          </div>
        ))}
      </div>
      <IonModal id='story-modal' isOpen={storyModalOpen} onDidDismiss={() => closeStoryModal()}>
        <button className='close-button' onClick={() => closeStoryModal()}>
          <img src='assets/icons/close-icon-grey.svg' />
        </button>
        {selectedStory && selectedStory.pages.length > 0 && (
          <ReactInstaStories
            stories={getStoryPages(selectedStory)}
            defaultInterval={10000}
            width={(width ?? 0) < 576 ? '100vw' : 432}
            height={(width ?? 0) < 576 ? '100%' : 768}
            renderers={[StoryVideoPlayer]}
            isPaused={true}
            onAllStoriesEnd={() => closeStoryModal()}
            onStoryStart={(page: number) => logStoryPageView(selectedStory, page)}
            // onStoryEnd={(page: number) => setStoryPageViewed(selectedStory, page)}
            currentIndex={getStoryPageIndex(selectedStory)}
            progressWrapperStyles={{
              marginTop: 'var(--ion-safe-area-top, 0)',
            }}
          />
        )}
      </IonModal>
    </div>
  );
};

export default Stories;

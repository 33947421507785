import React from 'react';

import { FeedbackClass } from './forms';

import './B1Select.scss';
import './Forms.scss';

export interface SelectOption {
  value?: string;
  label?: string;
}

interface SelectProps {
  ref?: React.RefObject<HTMLSelectElement>;
  id?: string;
  name?: string;
  type?: React.HTMLInputTypeAttribute;
  label?: string;
  placeholder?: string;
  value?: string | number | readonly string[];
  defaultValue?: string | number | readonly string[];
  autoComplete?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  options: string[] | SelectOption[] | { [key: string]: string };
  onInput?: React.FormEventHandler<HTMLSelectElement>;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  onFocus?: React.FocusEventHandler<HTMLSelectElement>;
  feedback?: string;
  feedbackClass?: FeedbackClass;
}

const B1Select: React.FC<SelectProps> = (inputProps) => {
  const classes = ['b1-form-element'];

  if (inputProps.feedbackClass) {
    classes.push(inputProps.feedbackClass);
  }

  let options: SelectOption[] = [];

  if (Array.isArray(inputProps.options)) {
    options = inputProps.options.map((option) => {
      if (typeof option === 'string') {
        return { value: option, label: option };
      } else {
        return option;
      }
    });
  } else {
    options = Object.entries(inputProps.options).map((kv) => {
      return { value: kv[0], label: kv[1] };
    });
  }

  if (inputProps.placeholder) {
    options.unshift({ value: '', label: inputProps.placeholder });
  }

  return (
    <div className={classes.join(' ')}>
      {inputProps.label && <label htmlFor={inputProps.id}>{inputProps.label}</label>}
      <select
        ref={inputProps.ref}
        id={inputProps.id}
        placeholder={inputProps.placeholder}
        value={inputProps.value}
        defaultValue={inputProps.defaultValue}
        autoComplete={inputProps.autoComplete}
        onInput={inputProps.onInput}
        onChange={inputProps.onChange}
        onBlur={inputProps.onBlur}
        onFocus={inputProps.onFocus}
        disabled={inputProps.disabled}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label ?? option.value}
          </option>
        ))}
      </select>
      {inputProps.feedback && <span className='form-feedback'>{inputProps.feedback}</span>}
    </div>
  );
};

export default B1Select;

import { createRoot } from 'react-dom/client';
import App from './App';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<App />);

container?.addEventListener('touchstart', (event) => {
  const x = event.touches[0].pageX;

  if (x > 20 && x < window.innerWidth - 20) return;

  event.preventDefault();
});

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { EmailCheck } from '../interfaces/account-info';

export const checkEmail = async (code: string, email: string) => {
  const res = await makeApiRequest<EmailCheck>('POST', 'v1/account/email/check', undefined, {
    code,
    email,
  });

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const changeEmail = async (email: string) => {
  const res = await makeApiRequest('POST', 'v1/account/email/change', undefined, {
    email: email,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const verifyEmail = async (token: string) => {
  const res = await makeApiRequest('POST', 'v1/account/email/verify', undefined, { token: token });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const sendEmailVerification = async () => {
  const res = await makeApiRequest('POST', 'v1/account/email/send-verify');

  if (isApiError(res)) {
    return res;
  }

  return true;
};

import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CourseType } from '../../../api/interfaces/course';
import UserCourse from '../../../api/interfaces/user-course';
import AllInOneCourseCard from '../../../components/course/AllInOneCourseCard';
import useWindowSize from '../../../hooks/window-size-hook';
import DashboardWrapper from '../dashboard-wrapper/DashboardWrapper';

const SRCDashboard: React.FC = () => {
  const [userCourses, setUserCourses] = useState<UserCourse[]>([]);
  const { width } = useWindowSize();

  return (
    <DashboardWrapper courseType={CourseType.SRC} onUserCoursesLoaded={setUserCourses}>
      <Row className='g-3 course-cards'>
        <Col xl='8'>
          <Row className='g-3'>
            <Col xs='6'>
              <AllInOneCourseCard
                userCourse={userCourses.find((c) => c.identifier === CourseType.SRC)}
                hasPromotionImage
                hasChevronIcon
              />
            </Col>
            <Col xs='6'>
              <AllInOneCourseCard
                userCourse={userCourses.find((c) => c.identifier === CourseType.SRCP)}
                hasPromotionImage
                hasChevronIcon
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/BS1_Icon_Oral_Exam.svg'
                title='Mündliche Prüfung'
                description='Bereite Dich hier auf die mündliche Prüfung vor.'
                locked={userCourses.find((c) => c.identifier === CourseType.SRC)?.expired}
                hasChevronIcon
                isCompact
                link='/course/src/oral-exam-practice'
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/BS1_Icon_Device_Simulation.svg'
                title='Geräte&shy;simulation'
                description={
                  (width ?? 0) < 768
                    ? 'Aktuell nur auf Desktop verfügbar.'
                    : 'Übe hier an einem virtuellen Funkgerät.'
                }
                locked={
                  (width ?? 0) < 768 ||
                  userCourses.find((c) => c.identifier === CourseType.SRC)?.expired
                }
                showCompactDescription
                hasChevronIcon
                isCompact
                link='/course/src/radio'
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/BS1_Icon_Exam_Booking.svg'
                title='Prüfung buchen'
                description='Hier kannst Du Deine Prüfung buchen.'
                locked={userCourses.find((c) => c.identifier === CourseType.SRC)?.expired}
                link='/course/src/exam-booking'
                hasChevronIcon
                isCompact
              />
            </Col>
          </Row>
        </Col>
        <Col xl='4'>
          <Row className='g-3'>
            <Col sm='12'>
              <AllInOneCourseCard
                link='/course/src/smartmode'
                icon='assets/icons/learn-mode.svg'
                title='Smartmodus'
                description='Hier kannst Du alle Fragen, die in der theoretischen Prüfung vorkommen können, effizient lernen.'
                locked={userCourses.find((c) => c.identifier === CourseType.SRC)?.expired}
              />
            </Col>
            <Col sm='12'>
              <AllInOneCourseCard
                link='/course/src/exam-training'
                icon='assets/icons/exam-simulation.svg'
                title='Theorie Prüfungssimulation'
                description='Hier kannst Du mit den offiziellen Prüfungsbögen in einem echten Prüfungsszenario lernen.'
                locked={userCourses.find((c) => c.identifier === CourseType.SRC)?.expired}
              />
            </Col>
            <Col sm='12'>
              <AllInOneCourseCard
                link='/course/src/radio-practical-exercises'
                icon='assets/icons/exam-simulation.svg'
                title='Praxis Prüfungssimulation'
                description='Hier findest Du Beispiele für die Fertigkeiten, die in der praktischen Prüfung zu erledigen sind.'
                locked={userCourses.find((c) => c.identifier === CourseType.SRC)?.expired}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardWrapper>
  );
};

export default SRCDashboard;

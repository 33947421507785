import React, { useEffect, useState } from 'react';

import { getCourse } from '../../../api/courses/learn-mode';
import { CourseType } from '../../../api/interfaces/course';
import Video from '../../../api/interfaces/video';
import { isApiError } from '../../../utils/api-util';
import VideoPlayer from '../../video-player/VideoPlayer';
import B1Modal from '../B1Modal';

import './IntroModal.scss';

interface IntroModalProps {
  open: boolean;
  onModalClose: () => void;
  courseType?: CourseType;
  onIntroVideoFound: () => void;
}

const IntroModal: React.FC<IntroModalProps> = ({
  open,
  onModalClose,
  courseType,
  onIntroVideoFound,
}) => {
  const [introVideo, setIntroVideo] = useState<Video>();

  useEffect(() => {
    if (!courseType) return;

    getCourse(courseType).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      if (res.intro_video) {
        setIntroVideo(res.intro_video);
        onIntroVideoFound();
      }
    });
  }, [courseType]);

  return (
    <B1Modal className='intro-modal' open={open} onModalClose={onModalClose}>
      <div className='intro-modal-content'>
        <VideoPlayer video={introVideo} />
      </div>
    </B1Modal>
  );
};

export default IntroModal;

/**
 * Underline plugin for Remark.
 */
import { Transformer } from 'unified';
import { Node, Parent } from 'unist';
import { visit } from 'unist-util-visit';

interface Literal<T> extends Node {
  /**
   * Plain value.
   */
  value: T;
}

const underline: () => Transformer = () => {
  return (tree) => {
    visit(tree, ['text'], (node, i, parent: Parent) => {
      if (node.type !== 'text') {
        return;
      }

      const { value } = node as Literal<string>;

      const values = value.split(/~~/);

      if (values.length === 1 || values.length % 2 === 0) {
        return;
      }

      const children: Node[] = values.map((str, i) => {
        if (i % 2 === 0) {
          return {
            type: 'text',
            value: str,
          };
        } else {
          return {
            type: 'underline',
            data: {
              hName: 'span',
              hProperties: {
                style: 'text-decoration: underline',
              },
            },
            children: [
              {
                type: 'text',
                value: str,
              },
            ],
          };
        }
      });

      if (i === undefined) return;

      parent.children.splice(i, 1, ...children);
    });
  };
};

export default underline;

import { Storage } from '@ionic/storage';

let storage: Storage;

export async function createStorage() {
  const store = new Storage();

  storage = await store.create();
}

export function getStorage() {
  return storage;
}

import { useIonRouter } from '@ionic/react';
import React, { useEffect } from 'react';

const Redirect: React.FC<{ to: string }> = ({ to }) => {
  const router = useIonRouter();

  useEffect(() => {
    router.push(to, 'root', 'replace', { unmount: true });
  }, [to]);

  return <></>;
};

export default Redirect;

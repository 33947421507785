import React from 'react';
import Markdown from 'react-markdown';
import supersub from 'remark-supersub';

import underline from './plugins/underline';

interface MarkdownProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string | null;
}

const B1Markdown: React.FC<MarkdownProps> = ({ children, ...props }) => (
  <div {...props}>
    <Markdown remarkPlugins={[underline, supersub]}>{children}</Markdown>
  </div>
);

export default B1Markdown;

import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { requestPasswordReset } from '../../../api/account/password';
import B1Button from '../../../components/buttons/B1Button';
import B1Input from '../../../components/forms/B1Input';
import { FeedbackClass } from '../../../components/forms/forms';
import Layout from '../../Layout';

import '../../LoginRegister.scss';
import './LoginForgotPassword.scss';

const LoginForgotPassword: React.FC = () => {
  const { email: emailParam } = useParams<{ email?: string }>();

  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [feedbackClass, setFeedbackClass] = useState<FeedbackClass>();
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  useEffect(() => {
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [emailParam]);

  const submitEmail = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!email) {
      setFeedback('Bitte gib deine E-Mail ein');
      setFeedbackClass('warning');
      return;
    }

    const result = await requestPasswordReset(email);

    if (result === true) {
      setEmail('');
      setFeedback('');
      setFeedbackClass(undefined);
      setPasswordResetSuccess(true);
    } else {
      switch (result.statusCode) {
        case 400:
          setFeedback('Ungültige E-Mail-Adresse');
          setFeedbackClass('warning');
          break;
        case 404:
          setFeedback('Benutzer nicht gefunden');
          setFeedbackClass('warning');
          break;
        default:
          setFeedback('Unbekannter Fehler');
          setFeedbackClass('warning');
          break;
      }
    }
  };

  return (
    <Layout backButton='/login'>
      <div className='login-page login-page-forgot-password'>
        <span className='emoji'>🔑</span>
        <h2 className='mb-3'>Passwort zurücksetzen</h2>
        {passwordResetSuccess ? (
          <>
            <h4 className='mb-4'>Schau in deinem Postfach!</h4>
            <div className='b1-alert'>
              <img src='assets/icons/check-icon-filled-2.svg' />
              <p>
                Wir haben dir eine E-Mail geschickt. Falls du sie nicht finden solltest, schaue auch
                im Spam-Ordner nach.
              </p>
            </div>
            <B1Button className='button-block' href='/login'>
              Zurück zum Login
            </B1Button>
          </>
        ) : (
          <>
            <h4 className='mb-4'>Wie lautet deine E-Mail?</h4>
            <form className='b1-form' onSubmit={submitEmail}>
              <B1Input
                id='email'
                type='email'
                placeholder='Bitte E-Mail eingeben'
                autoComplete='email'
                label='E-Mail-Adresse'
                onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
                value={email}
                feedback={feedback}
                feedbackClass={feedbackClass}
              ></B1Input>
              <B1Button type='submit' className='button-block'>
                Passwort anfordern
              </B1Button>
            </form>
          </>
        )}
      </div>
    </Layout>
  );
};

export default LoginForgotPassword;

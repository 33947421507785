import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { ExamPaperData, ExamPaperQuestion } from '../interfaces/exam-paper';

export const getExamPapers = async (identifier: string) => {
  const res = await makeApiRequest<ExamPaperData>('GET', `v2/courses/${identifier}/exam-simulator`);

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getExamPaperQuestions = async (identifier: string, examPaperId: number) => {
  const res = await makeApiRequest<ExamPaperQuestion[]>(
    'GET',
    `v2/courses/${identifier}/exam-simulator/${examPaperId}`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const updateExamPaperProgress = async (
  identifier: string,
  examPaperId: number,
  passed: boolean,
  categories: { id: string; correct: number; total: number }[]
) => {
  const res = await makeApiRequest(
    'POST',
    `v2/courses/${identifier}/exam-simulator/${examPaperId}`,
    undefined,
    { passed, categories }
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

import React from 'react';
import { useParams } from 'react-router';

import Layout from '../Layout';

import './RadioEmbed.scss';

const RadioEmbed: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>();

  return (
    <Layout
      contentClassName='radio-embed'
      backButton={`/course/${identifier}`}
      currentCourseType={identifier}
    >
      <h1>Gerätesimulation</h1>
      <p className='mb-3'>
        Dieses Feature befindet sich noch in Entwicklung – wir freuen uns jedoch, Dir eine
        Vorabversion zur Verfügung stellen zu können.
      </p>
      <iframe src='https://funk.bootsschule1.de' />
    </Layout>
  );
};

export default RadioEmbed;

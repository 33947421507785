import React from 'react';
import { useParams } from 'react-router';

import RedeemCodeForm from '../../../components/redeem-code-form/RedeemCodeForm';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import Layout from '../../Layout';

import '../../LoginRegister.scss';
import './LoginCode.scss';

const LoginCode: React.FC = () => {
  const { code } = useParams<{ code?: string }>();
  const [, dispatchRegistrationData] = useRegistrationDataState();

  return (
    <Layout backButton='/login'>
      <div className='login-page login-page-code'>
        <span className='emoji'>👋</span>
        <h2 className='mb-4 no-hyphens'>
          Willkommen bei Bootsschule1<span>®</span>
        </h2>
        <h4 className='mb-5'>Gib bitte deinen Aktivierungscode ein.</h4>
        <RedeemCodeForm
          continueTo='/login/email'
          initialCode={code}
          onCodeChecked={(code) => dispatchRegistrationData({ type: 'SET_CODE', code: code })}
        />
      </div>
    </Layout>
  );
};

export default LoginCode;

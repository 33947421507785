import { AddressData } from '../../reducers/registration-data-reducer';
import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import AddressCheckResult from '../interfaces/address-check';

export const checkAddress = async (addressData: AddressData) => {
  const res = await makeApiRequest<AddressCheckResult>(
    'POST',
    'v2/account/address/check',
    undefined,
    addressData
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

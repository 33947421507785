import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import Course, { ChapterResources } from '../interfaces/course';
import { Question } from '../interfaces/quiz';

const getIdentifier = (identifier: string) => {
  switch (identifier.toLowerCase()) {
    case 'sbfbsp':
    case 'bspdsp':
      return 'sp';
    default:
      return identifier;
  }
};

export const getCourse = async (identifier: string) => {
  const res = await makeApiRequest<Course>(
    'GET',
    `v1/courses/${getIdentifier(identifier)}/learn-mode`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const updateChapterProgress = async (
  identifier: string,
  chapterId: number,
  finished = true
) => {
  const res = await makeApiRequest(
    'POST',
    `v1/courses/${getIdentifier(identifier)}/learn-mode/${chapterId}/progress`,
    undefined,
    { finished: finished }
  );

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const resetCourseProgress = async (identifier: string) => {
  const res = await makeApiRequest(
    'POST',
    `v1/courses/${getIdentifier(identifier)}/learn-mode/reset-progress`
  );

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const getChapterResources = async (identifier: string, chapterId: number) => {
  const res = await makeApiRequest<ChapterResources>(
    'GET',
    `v1/courses/${getIdentifier(identifier)}/learn-mode/${chapterId}/resources`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getChapterQuestions = async (identifier: string, chapterId: number) => {
  const res = await makeApiRequest<Question[]>(
    'GET',
    `v1/courses/${getIdentifier(identifier)}/learn-mode/${chapterId}/questions`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

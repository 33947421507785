import { useIonViewWillEnter } from '@ionic/react';
import { DateTime } from 'luxon';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';

import { getUserCourses } from '../../../api/courses/courses';
import { AccountStatus } from '../../../api/interfaces/account-info';
import { CourseType } from '../../../api/interfaces/course';
import UserCourse from '../../../api/interfaces/user-course';
import B1Button from '../../../components/buttons/B1Button';
import B1Modal from '../../../components/modal/B1Modal';
import IntroModal from '../../../components/modal/intro-modal/IntroModal';
import RatingModal from '../../../components/modal/rating-modal/RatingModal';
import { AuthContext } from '../../../providers/AuthProvider';
import { isApiError } from '../../../utils/api-util';
import { CourseCardLink, handleCourseLinkClick } from '../../../utils/course-link-util';
import {
  getCourseName,
  getCourseOrderIndex,
  getCourseUpgradeShopLink,
  getMainCourse,
  getRelatedCourseTypes,
  getShortCourseName,
} from '../../../utils/course-util';
import { getStorage } from '../../../utils/storage';
import Layout from '../../Layout';

import './DashboardWrapper.scss';

interface ValidUntilNoticeProps {
  userCourses: UserCourse[];
  courseType?: CourseType;
}

const ValidUntilNotice: React.FC<ValidUntilNoticeProps> = ({ userCourses, courseType }) => {
  if (!courseType) return <></>;

  const courseTypes = getRelatedCourseTypes(courseType);
  const courses = userCourses.filter(
    (c) =>
      courseTypes.includes(c.identifier) && c.claimed && !c.identifier.toUpperCase().startsWith('P')
  );

  courses.sort((a, b) => {
    if (a.valid_until && b.valid_until) {
      const diff = DateTime.fromISO(a.valid_until).diff(
        DateTime.fromISO(b.valid_until)
      ).milliseconds;

      if (diff === 0) {
        return getCourseOrderIndex(a.identifier) - getCourseOrderIndex(b.identifier);
      } else {
        return diff;
      }
    } else {
      return 0;
    }
  });

  let icon = 'warning.svg';
  const validUntilNotices: {
    courseType: CourseType;
    daysValid: number;
    upgradeLink: CourseCardLink;
  }[] = [];

  for (const course of courses) {
    if (!course.valid_until) continue;

    const date = DateTime.fromISO(course.valid_until);
    const daysValid = Math.round(date.diffNow('days').days);

    if (daysValid < 0) {
      icon = 'cross-circle.svg';
    }

    if (daysValid <= 90) {
      const upgradeLink = getCourseUpgradeShopLink(
        courses.filter((c) => c.claimed).map((c) => c.identifier)
      );

      validUntilNotices.push({
        courseType: course.identifier,
        daysValid,
        upgradeLink: {
          url: upgradeLink,
          courseIdentifier: course.identifier,
          type: 'upgrade',
        },
      });
    }
  }

  validUntilNotices.sort((a, b) => {
    return a.daysValid - b.daysValid;
  });

  const getValidUntilText = (daysValid: number, courseTypes: CourseType[]) => {
    const courseNames = courseTypes
      .sort((a, b) => getCourseOrderIndex(a) - getCourseOrderIndex(b))
      .map((courseType) => getShortCourseName(courseType));

    if (daysValid < 0) {
      return `Dein Zugang für ${
        courseNames.length === 1 ? 'den Kurs' : 'die Kurse'
      } ${courseNames.join(', ')} ist abgelaufen.`;
    } else if (daysValid === 0) {
      return `Dein Zugang für ${
        courseNames.length === 1 ? 'den Kurs' : 'die Kurse'
      } ${courseNames.join(', ')} läuft heute ab.`;
    } else if (daysValid <= 90) {
      return `Dein Zugang für ${
        courseNames.length === 1 ? 'den Kurs' : 'die Kurse'
      } ${courseNames.join(', ')} läuft in ${daysValid} Tag${daysValid === 1 ? '' : 'en'} ab.`;
    }

    return '';
  };

  if (validUntilNotices.length > 0) {
    return (
      <div className='b1-alert mb-4 justify-content-start'>
        <img src={`assets/icons/${icon}`} />
        <div className='d-flex flex-column row-gap-2'>
          {validUntilNotices.map(
            (notice, i, originalArray) =>
              originalArray.findIndex((t) => t.upgradeLink.url === notice.upgradeLink.url) >= i && (
                <React.Fragment key={i}>
                  <div className='renewal-info'>
                    <span>
                      {getValidUntilText(
                        notice.daysValid,
                        originalArray
                          .filter((n) => n.upgradeLink.url === notice.upgradeLink.url)
                          .map((n) => n.courseType)
                      )}
                    </span>
                    {notice.upgradeLink.url && (
                      <B1Button
                        external
                        href={notice.upgradeLink.url}
                        className='button-sm'
                        click={() => handleCourseLinkClick(notice.upgradeLink)}
                      >
                        Zugang verlängern
                      </B1Button>
                    )}
                  </div>
                </React.Fragment>
              )
          )}
        </div>
      </div>
    );
  }

  return <></>;
};

interface DashboardWrapperProps extends PropsWithChildren {
  courseType?: CourseType;
  onUserCoursesLoaded: (userCourses: UserCourse[]) => void;
}

const DashboardWrapper: React.FC<DashboardWrapperProps> = ({
  courseType,
  onUserCoursesLoaded,
  children,
}) => {
  const { isAuthenticated, getUserData } = useContext(AuthContext);
  const [userState, setUserState] = useState<AccountStatus>();
  const [introVideoFound, setIntroVideoFound] = useState<boolean>(false);
  const [introModalOpen, setIntroModalOpen] = useState<boolean>(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [finishedCourseModalOpen, setFinishedCourseModalOpen] = useState(false);
  const [courses, setCourses] = useState<UserCourse[]>([]);
  const [finishedCourseModalShown, setFinishedCourseModalShown] = useState<string[]>([]);
  const [ratingModalShown, setRatingModalShown] = useState<boolean>();

  useEffect(() => {
    getUserData().then(setUserState);
  }, [isAuthenticated, getUserData]);

  useIonViewWillEnter(() => {
    getUserCourses().then((res) => {
      if (isApiError(res)) {
        console.error(res);
      } else {
        setCourses(res);
        onUserCoursesLoaded(res);
      }
    });
  }, [courseType]);

  useEffect(() => {
    const storage = getStorage();
    storage
      .get('finished-course-modal-shown')
      .then((value) => setFinishedCourseModalShown(value || []));
    storage.get('rating-modal-shown').then((value) => setRatingModalShown(value));
  }, []);

  useEffect(() => {
    const course = courses.find((c) => c.identifier === courseType);
    const storage = getStorage();
    const coursesFinishedModalShown = [...finishedCourseModalShown];

    if (course?.finished && !coursesFinishedModalShown.includes(course.identifier)) {
      coursesFinishedModalShown.push(course.identifier);
      storage.set('finished-course-modal-shown', coursesFinishedModalShown);
      setFinishedCourseModalShown(coursesFinishedModalShown);
      setFinishedCourseModalOpen(true);
    } else if (!ratingModalShown && course && course.progress >= 0.8) {
      storage.set('rating-modal-shown', true);
      setRatingModalShown(true);
      setRatingModalOpen(true);
    }
  }, [courseType, courses, finishedCourseModalShown, ratingModalShown]);

  return (
    <Layout contentClassName='dashboard-wrapper' backButton='/' currentCourseType={courseType}>
      <div className='dashboard-heading'>
        <h1>
          {courseType &&
            (getMainCourse(courseType) === 'sbf' || getMainCourse(courseType) === 'psbf'
              ? 'Sportbootführerschein (SBF)'
              : getCourseName(courseType))}
        </h1>
        {introVideoFound && (
          <B1Button className='button-md' click={() => setIntroModalOpen(true)}>
            Einleitung abspielen
            <img src='assets/icons/play-white.svg' />
          </B1Button>
        )}
      </div>
      <ValidUntilNotice userCourses={courses} courseType={courseType} />
      {children}
      <IntroModal
        open={introModalOpen}
        onModalClose={() => setIntroModalOpen(false)}
        courseType={courseType}
        onIntroVideoFound={() => setIntroVideoFound(true)}
      />
      <RatingModal open={ratingModalOpen} setOpen={setRatingModalOpen} />
      <B1Modal
        className='rating-modal'
        open={finishedCourseModalOpen}
        onModalClose={() => setFinishedCourseModalOpen(false)}
      >
        <h1 className='emoji'>🏆</h1>
        <h2 className='no-hyphens'>Herzlichen Glückwunsch</h2>
        <p>
          Liebe{userState?.sex === 'm' ? 'r' : userState?.sex === 'w' ? '' : ':r'}{' '}
          {userState?.firstname}, wir wünschen Dir nach Deiner bestandenen Prüfung viel Freude &
          schöne Momente mit Deinem Sportbootführerschein.
        </p>
      </B1Modal>
    </Layout>
  );
};

export default DashboardWrapper;

import React, { memo } from 'react';
import twemoji from 'twemoji';

const U200D = String.fromCharCode(0x200d);
const UFE0Fg = /\uFE0F/g;

const Twemoji: React.FC<{ emoji: string; ext?: string }> = ({ emoji, ext = 'svg' }) => {
  const HEXCodePoint = twemoji.convert.toCodePoint(
    emoji.indexOf(U200D) < 0 ? emoji.replace(UFE0Fg, '') : emoji
  );

  return (
    <img
      src={`https://cdn.jsdelivr.net/gh/jdecked/twemoji@latest/assets/${
        ext === 'png' ? '72x72' : 'svg'
      }/${HEXCodePoint}.${ext}`}
      alt={emoji}
      loading='lazy'
      draggable={false}
    />
  );
};

export default memo(Twemoji);

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useContext, useMemo } from 'react';

import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import {
  IonRouterOutlet,
  RouteInfo,
  RouteManagerContext,
  RouteManagerContextState,
  ViewItem,
} from '@ionic/react';

import { matchRoute } from './utils';

type PatchedViewItem = ViewItem & { initialRouteProps?: any };

const IonRouterOutletPatched = (
  { children, ...otherProps }: React.PropsWithChildren<any>,
  ref: React.Ref<any>
) => {
  const routeManagerContextValue: RouteManagerContextState & {
    findViewItemByRouteInfoOriginal?: () => ViewItem;
    createViewItemOriginal?: () => ViewItem;
  } = useContext(RouteManagerContext);

  const findViewItemByRouteInfoOriginal =
    routeManagerContextValue.findViewItemByRouteInfoOriginal ||
    routeManagerContextValue.findViewItemByRouteInfo;
  const createViewItemOriginal =
    routeManagerContextValue.createViewItemOriginal || routeManagerContextValue.createViewItem;
  const newValue = useMemo(
    () => ({
      ...routeManagerContextValue,
      findViewItemByRouteInfo(routeInfo: RouteInfo, outletId?: string, updateMatch?: boolean) {
        const viewItem: PatchedViewItem | undefined = findViewItemByRouteInfoOriginal(
          routeInfo,
          outletId,
          updateMatch
        );
        // `matchRoute` is a function from Ionic StackManager
        // https://github.com/ionic-team/ionic-framework/blob/main/packages/react-router/src/ReactRouter/StackManager.tsx#L433
        // unfortunately it is not exported, so we just copy it to our utils file
        const routeElement = matchRoute(children, routeInfo);
        if (
          viewItem &&
          routeElement &&
          isEqual(viewItem.initialRouteProps, omit(routeElement.props, 'children'))
          // Folgende Variante funktioniert nicht, es entsteht eine Endlosschleife
          // isEqual(
          //   viewItem.initialRouteProps,
          //   pick(routeElement.props, ['path', 'isExact', 'strict', 'sensitive'])
          // )
        ) {
          return viewItem;
        }
        // otherwise undefined will be returned and Ionic will create new viewItem
      },
      createViewItem(
        outletId: string,
        routeElement: React.ReactElement,
        routeInfo: RouteInfo,
        page?: HTMLElement
      ): PatchedViewItem {
        const viewItem: PatchedViewItem = createViewItemOriginal(
          outletId,
          routeElement,
          routeInfo,
          page
        );
        // we want to know the route props associated to current viewItem
        // so we can find correct viewItem later
        viewItem.initialRouteProps = omit(routeElement.props, 'children');
        return viewItem;
      },
      // save original context methods to ensure that we never lead to recursion if you use nested routes
      findViewItemByRouteInfoOriginal,
      createViewItemOriginal,
    }),
    [routeManagerContextValue]
  );

  return (
    <RouteManagerContext.Provider value={newValue}>
      <IonRouterOutlet ref={ref} {...otherProps}>
        {children}
      </IonRouterOutlet>
    </RouteManagerContext.Provider>
  );
};

// forwarding ref to ensure that IonRouterOutlet will work as expected in any scenario
const IonRouterOutletPatchedWithForwardedRef: React.ForwardRefExoticComponent<any> & {
  isRouterOutlet?: boolean;
} = forwardRef(IonRouterOutletPatched);

// IonTabs children should be IonRouterOutlet or element with isRouterOutlet=true
// https://github.com/ionic-team/ionic-framework/blob/71a7af0f52fe62937b1dea1ca2739e78801a2a6d/packages/react/src/components/navigation/IonTabs.tsx#L106
IonRouterOutletPatchedWithForwardedRef.isRouterOutlet = true;

export default IonRouterOutletPatchedWithForwardedRef;

import { Question } from './quiz';

export interface ExamPaperSubmission {
  created_at: string;
  passed: boolean;
  categories: {
    identifier: string;
    title: string;
    correct: number;
    total: number;
    correct_to_pass: number;
  }[];
}

export interface ExamPaper {
  id: number;
  nr: number;
  questions_count: number;
  total_points: number;
  submissions: ExamPaperSubmission[];
  v1_submission?: {
    correct_count: number;
  };
}

export interface ExamPaperData {
  exam_paper_processing_time: number;
  exam_papers_path?: string;
  exam_papers: ExamPaper[];
}

export interface ExamPaperQuestionCategory {
  identifier: string;
  title: string;
  correct_to_pass: number;
}

export interface ExamPaperQuestion extends Question {
  nr: number;
  points: number;
  category?: ExamPaperQuestionCategory;
}

export const isExamPaperQuestion = (question: Question): question is ExamPaperQuestion => {
  const q = question as ExamPaperQuestion;
  return q.nr !== undefined && q.points !== undefined;
};

export const hasMarginalPassed = (category: {
  correct: number;
  correct_to_pass: number;
}): boolean => {
  return category.correct <= Math.round(category.correct_to_pass + category.correct_to_pass * 0.1);
};

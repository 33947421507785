import { useIonRouter, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router';

import {
  getNavigationTrainingExercises,
  getNavigationTrainings,
} from '../../../../api/courses/navigation-training';
import {
  NavigationTrainingExercise,
  NavigationTrainingOverview,
  NavigationTrainingQuestion,
} from '../../../../api/interfaces/navigation-training';
import B1Button from '../../../../components/buttons/B1Button';
import NavigationCircles from '../../../../components/navigation-circles/NavigationCircles';
import VideoPlayer from '../../../../components/video-player/VideoPlayer';
import { isApiError } from '../../../../utils/api-util';
import Layout from '../../../Layout';

import './NavigationQuestions.scss';

interface NavigationQuestionsBodyProps {
  question: NavigationTrainingQuestion;
}

const NavigationQuestionBody: React.FC<NavigationQuestionsBodyProps> = ({ question }) => {
  const [videoPlaying, setVideoPlaying] = React.useState<boolean>();
  const [calculationVideoPlaying, setCalculationVideoPlaying] = React.useState<boolean>();
  const [opened, setOpened] = React.useState<boolean>(false);

  return (
    <Accordion.Body
      onExiting={() => {
        setVideoPlaying(false);
        setCalculationVideoPlaying(false);
      }}
      onEnter={() => setOpened(true)}
    >
      <p>
        <strong>Antwort:</strong>
      </p>
      <p className='mb-3'>{question.answer}</p>
      <p className='mb-3'>
        <strong>Lösung:</strong>
      </p>
      {question.video &&
        (opened ? (
          <VideoPlayer
            video={question.video}
            play={videoPlaying}
            onPlayingStateChange={(state) => setVideoPlaying(state)}
          />
        ) : (
          <Ratio aspectRatio='16x9'>
            <div style={{ backgroundColor: '#000' }}></div>
          </Ratio>
        ))}
      {question.calculation_video && (
        <>
          <p className='mb-3'>
            <strong>Rechenhilfe:</strong>
          </p>
          {opened ? (
            <VideoPlayer
              video={question.calculation_video}
              play={calculationVideoPlaying}
              onPlayingStateChange={(state) => setCalculationVideoPlaying(state)}
            />
          ) : (
            <Ratio aspectRatio='16x9'>
              <div style={{ backgroundColor: '#000' }}></div>
            </Ratio>
          )}
        </>
      )}
    </Accordion.Body>
  );
};

const NavigationQuestions: React.FC = () => {
  const { identifier, exerciseId } = useParams<{
    identifier: string;
    exerciseId: string;
  }>();
  const [navigationTrainings, setNavigationTrainings] = useState<NavigationTrainingOverview[]>([]);
  const [navigationTrainingExercise, setNavigationTrainingExercise] =
    useState<NavigationTrainingExercise>();
  const navigationQuestionsCircles = useRef<HTMLDivElement>(null);
  const ionRouter = useIonRouter();

  useEffect(() => {
    getNavigationTrainingExercises(identifier).then((data) => {
      if (isApiError(data)) {
        console.error(data);
        return;
      }

      setNavigationTrainings(data);
    });
  }, [identifier]);

  useIonViewWillEnter(() => {
    getNavigationTrainings(identifier, Number(exerciseId)).then((data) => {
      if (isApiError(data)) {
        console.error(data);
        ionRouter.push(`/course/${identifier}/navigation-training`, 'back', 'pop');
        return;
      }

      setNavigationTrainingExercise(data);
    });
  }, [identifier, exerciseId]);

  useIonViewDidEnter(() => {
    navigationQuestionsCircles.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [identifier, exerciseId]);

  const getPrevExerciseId = () => {
    const index = navigationTrainings.findIndex((e) => e.id === Number(exerciseId));

    if (index === -1 || index === 0) {
      return null;
    }

    return navigationTrainings[index - 1].id;
  };

  const getNextExerciseId = () => {
    const index = navigationTrainings.findIndex((e) => e.id === Number(exerciseId));

    if (index === -1 || index === navigationTrainings.length - 1) {
      return null;
    }

    return navigationTrainings[index + 1].id;
  };

  return (
    <Layout
      backButton={`/course/${identifier}/navigation-training`}
      contentClassName='navigation-questions'
      currentCourseType={identifier}
    >
      <div className='navigation-questions-container'>
        <NavigationCircles
          navigationCirclesRef={navigationQuestionsCircles}
          list={navigationTrainings}
          link={`/course/${identifier}/navigation-training`}
          currentId={Number(exerciseId)}
        />
        <div className='simple-card card-white'>
          <p>{navigationTrainingExercise?.text}</p>
        </div>
        <Accordion key={exerciseId} className='b1-accordion' defaultActiveKey={undefined}>
          {navigationTrainingExercise?.questions?.map((question, index) => (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header>
                <div>
                  {question.info && <p className='mb-2'>{question.info}</p>}
                  <p>
                    <strong>{question.text}</strong>
                  </p>
                </div>
              </Accordion.Header>
              <NavigationQuestionBody question={question} />
            </Accordion.Item>
          ))}
        </Accordion>

        <Row className='buttons gy-3'>
          <Col xs='12' lg='6'>
            {getPrevExerciseId() ? (
              <B1Button
                className='button-block button-reverse'
                href={`/course/${identifier}/navigation-training/${getPrevExerciseId()}`}
                routerDirection='back'
              >
                Zurück
              </B1Button>
            ) : (
              <B1Button
                className='button-block button-reverse'
                href={`/course/${identifier}/navigation-training`}
                routerDirection='back'
              >
                Zur Übersicht
              </B1Button>
            )}
          </Col>
          <Col xs='12' lg='6'>
            {getNextExerciseId() ? (
              <B1Button
                className='button-block'
                href={`/course/${identifier}/navigation-training/${getNextExerciseId()}`}
                routerDirection='forward'
              >
                Weiter
              </B1Button>
            ) : (
              <B1Button
                className='button-block'
                href={`/course/${identifier}/navigation-training`}
                routerDirection='back'
              >
                Zur Übersicht
              </B1Button>
            )}
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default NavigationQuestions;

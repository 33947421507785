import Video from './video';

export enum CourseType {
  /** Sportbootführerschein See */
  SBFS = 'SBFS',
  /** Sportbootführerschein Binnen */
  SBFB = 'SBFB',
  /** Sportbootführerschein Navigation */
  SBFN = 'SBFN',
  /** Sportbootführerschein Binnen Basisfragen */
  SBFBB = 'SBFBB',
  /** Sportbootführerschein Binnen Segel */
  SBFBS = 'SBFBS',
  /** Sportbootführerschein Binnen Segel Praxis */
  SBFBSP = 'SBFBSP',
  /** Bodenseeschifferpatent */
  BSP = 'BSP',
  /** Bodenseeschifferpatent Kat. D */
  BSPD = 'BSPD',
  /** Bodenseeschifferpatent Kat. D Segelpraxis */
  BSPDSP = 'BSPDSP',
  /** Short Range Certificate */
  SRC = 'SRC',
  /** SRC Praxis */
  SRCP = 'SRCP',
  /** UKW-Sprechfunkzeugnis für den Binnenschifffahrtsfunk */
  UBI = 'UBI',
  /** UBI Praxis */
  UBIP = 'UBIP',
  /** Sportküstenschifferschein */
  SKS = 'SKS',
  /** Fachkundenachweis für Seenotsignalmittel */
  FKN = 'FKN',
  /** Sachkundenachweis für Seenotsignalmittel */
  SKN = 'SKN',

  // Partner courses
  /** Partner Sportbootführerschein See */
  PSBFS = 'PSBFS',
  /** Partner Sportbootführerschein Binnen */
  PSBFB = 'PSBFB',
  /** Partner Sportbootführerschein Navigation */
  PSBFN = 'PSBFN',
  /** Partner Sportbootführerschein Binnen Basisfragen */
  PSBFBB = 'PSBFBB',
  /** Partner Short Range Certificate */
  PSRC = 'PSRC',
  /** Partner UKW-Sprechfunkzeugnis für den Binnenschifffahrtsfunk */
  PUBI = 'PUBI',
  /** Partner UBI Praxis */
  PUBIP = 'PUBIP',

  // Special courses
  /** Segelpraxis ("real" CourseType for SBFBSP and BSPDSP) */
  SP = 'SP',
}

interface Course {
  identifier: CourseType;
  title: string;
  progress: number;
  has_documents: boolean;
  lessons: Lesson[];
  summary_link?: string;
  intro_video?: Video;
}

export interface Lesson {
  title: string;
  chapters: Chapter[];
  video_length: number;
}

export interface Chapter {
  id: number;
  title: string;
  finished: boolean;
  video_length: number;
}

export interface SignalGroup {
  id: number;
  title: string;
  signals: {
    id: number;
    text: string;
    picture_link?: string;
    audio_link?: string;
  }[];
}

interface Beacon {
  id: number;
  nr: number;
  title: string;
  abbreviation_int: string;
  abbreviation_german: string;
  light_link?: string;
  representation_link?: string;
}

export interface ChapterResources {
  videos?: Video[];
  pictures?: string[];
  doc_link?: string;
  questions_count: number;
  signals?: SignalGroup[];
  beacons?: Beacon[];
}

export interface CourseDocument {
  path: string;
}

export default Course;

import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CourseType } from '../../../api/interfaces/course';
import UserCourse from '../../../api/interfaces/user-course';
import AllInOneCourseCard from '../../../components/course/AllInOneCourseCard';
import DashboardWrapper from '../dashboard-wrapper/DashboardWrapper';

const PartnerSBFDashboard: React.FC = () => {
  const [userCourses, setUserCourses] = useState<UserCourse[]>([]);
  const [allCoursesExpired, setAllCoursesExpired] = useState(false);

  const getCourseType = () => {
    if (userCourses.find((c) => c.identifier === CourseType.PSBFN && c.active && c.claimed)) {
      return CourseType.PSBFS;
    } else if (
      userCourses.find((c) => c.identifier === CourseType.PSBFB && c.active && c.claimed)
    ) {
      return CourseType.PSBFB;
    } else {
      return CourseType.PSBFS;
    }
  };

  useEffect(() => {
    setAllCoursesExpired(
      !userCourses.some(
        (c) =>
          (c.identifier === CourseType.PSBFN || c.identifier === CourseType.PSBFB) &&
          c.active &&
          c.claimed &&
          !c.expired
      )
    );
  }, [userCourses]);

  return (
    <DashboardWrapper courseType={getCourseType()} onUserCoursesLoaded={setUserCourses}>
      <Row className='g-3 course-cards'>
        <Col xl='4'>
          <AllInOneCourseCard
            icon='assets/icons/knots.svg'
            wideIcon
            title='Knoten'
            description='Übe hier die Knoten.'
            locked={allCoursesExpired}
            link={`/course/${getCourseType()?.toLowerCase()}/knots`}
          />
        </Col>
        <Col xl='4'>
          <AllInOneCourseCard
            // locked
            link='/course/psbf/smartmode'
            icon='assets/icons/learn-mode.svg'
            title='Smartmodus'
            description='Hier kannst du alle Fragen, die in der Prüfung vorkommen können, effizient lernen.'
            locked={allCoursesExpired}
          />
        </Col>
        <Col xl='4'>
          <AllInOneCourseCard
            // locked
            link='/course/psbf/exam-training'
            icon='assets/icons/exam-simulation.svg'
            title='Prüfungssimulation'
            description='Hier kannst du mit den offiziellen Prüfungsbögen in einem echten Prüfungsszenario lernen.'
            locked={allCoursesExpired}
          />
        </Col>
      </Row>
    </DashboardWrapper>
  );
};

export default PartnerSBFDashboard;

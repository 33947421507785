import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';

export const updateLicense = async (hasDriverLicense: boolean) => {
  const res = await makeApiRequest('POST', 'v1/account/license', undefined, {
    has_driver_license: hasDriverLicense,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import PracticalTrainingCategory from '../interfaces/practical-training';

export const getPracticalTrainings = async (identifier: string) => {
  const res = await makeApiRequest<PracticalTrainingCategory[]>(
    'GET',
    `v1/courses/${identifier}/practical-trainings`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

import { Capacitor } from '@capacitor/core';
import React, { useEffect, useRef } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { useKeyboard } from '../../hooks/keyboard-hook';
import { FeedbackClass } from './forms';

import './B1Input.scss';
import './Forms.scss';

interface TextareaProps {
  ref?: React.RefObject<HTMLTextAreaElement>;
  className?: string;
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string | number | readonly string[];
  defaultValue?: string | number | readonly string[];
  autoComplete?: string;
  readonly?: boolean;
  disabled?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  maxLength?: number;
  onInput?: React.FormEventHandler<HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  feedback?: string;
  feedbackClass?: FeedbackClass;
}

const B1Textarea: React.FC<TextareaProps> = (textareaProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isOpen } = useKeyboard();
  const [isFocused, setIsFocused] = React.useState(false);
  const classes = ['b1-form-element'];

  if (textareaProps.className) {
    classes.push(textareaProps.className);
  }

  if (textareaProps.feedbackClass) {
    classes.push(textareaProps.feedbackClass);
  }

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(true);

    if (textareaProps.onFocus) {
      textareaProps.onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocused(false);

    if (textareaProps.onBlur) {
      textareaProps.onBlur(e);
    }
  };

  useEffect(() => {
    if (isOpen && isFocused && wrapperRef.current && Capacitor.getPlatform() === 'ios') {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isOpen, isFocused]);

  return (
    <div className={classes.join(' ')} ref={wrapperRef}>
      {textareaProps.label && <label htmlFor={textareaProps.id}>{textareaProps.label}</label>}
      <ReactTextareaAutosize
        ref={textareaProps.ref}
        id={textareaProps.id}
        name={textareaProps.name}
        className='custom-scrollbar'
        placeholder={textareaProps.placeholder}
        value={textareaProps.value}
        defaultValue={textareaProps.defaultValue}
        autoComplete={textareaProps.autoComplete}
        readOnly={textareaProps.readonly}
        disabled={textareaProps.disabled}
        rows={textareaProps.rows}
        minRows={textareaProps.minRows ?? textareaProps.rows}
        maxRows={textareaProps.maxRows}
        maxLength={textareaProps.maxLength}
        onInput={textareaProps.onInput}
        onChange={textareaProps.onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {textareaProps.feedback && <span className='form-feedback'>{textareaProps.feedback}</span>}
    </div>
  );
};

export default B1Textarea;

import { RouterOptions, useIonRouter } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';

import { changePassword } from '../../api/account/password';
import { AccountStatus } from '../../api/interfaces/account-info';
import { FeedbackClass } from '../../components/forms/forms';
import PasswordForm from '../../components/password-form/PasswordForm';
import { AuthContext } from '../../providers/AuthProvider';
import Layout from '../Layout';

const ChangePassword: React.FC = () => {
  const { isAuthenticated, getUserData } = useContext(AuthContext);
  const [userState, setUserState] = useState<AccountStatus>();
  const ionRouter = useIonRouter();

  const [feedback, setFeedback] = useState<string>();
  const [feedbackClass, setFeedbackClass] = useState<FeedbackClass>();

  useEffect(() => {
    getUserData().then(setUserState);
  }, [isAuthenticated, getUserData]);

  const submitPassword = async (password: string, oldPassword: string) => {
    const result = await changePassword(oldPassword, password);

    if (result === true) {
      // TODO: show success message
      ionRouter.push('/account', 'back', 'push', { from: 'change-password' } as RouterOptions);

      setFeedback('');
      setFeedbackClass(undefined);

      return true;
    } else {
      switch (result.statusCode) {
        case 400:
          setFeedback('Passwort entspricht nicht den Mindestanforderungen.');
          setFeedbackClass('warning');
          break;
        case 401:
          setFeedback('Ungültiges altes Passwort.');
          setFeedbackClass('warning');
          break;
        case 409:
          setFeedback(
            'Dein eingegebenes Passwort ist identisch mit dem altem Passwort, bitte verwende ein anderes.'
          );
          setFeedbackClass('warning');
          break;
        default:
          setFeedback('Unbekannter Fehler.');
          setFeedbackClass('warning');
          break;
      }

      return false;
    }
  };

  return (
    <Layout backButton='/account'>
      <div className='register-page register-page-password'>
        <h2 className='mb-3'>Passwort ändern</h2>
        <h4 className='mb-4'>
          Schütze dich und deine persönlichen Daten. Bitte vergib ein sicheres Passwort für deinen
          Account.
        </h4>
        <PasswordForm
          email={userState?.email}
          onSubmit={submitPassword}
          changePassword
          feedback={feedback}
          feedbackClass={feedbackClass}
        />
      </div>
    </Layout>
  );
};

export default ChangePassword;

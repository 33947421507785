import { useIonViewWillEnter } from '@ionic/react';
import React, { useEffect, useReducer, useState } from 'react';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { BsArrowRight, BsCheck } from 'react-icons/bs';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { getLessons, resetQuestionProgress } from '../../api/courses/smart-mode';
import { CourseType } from '../../api/interfaces/course';
import { SmartmodeLesson } from '../../api/interfaces/smartmode';
import B1Button from '../../components/buttons/B1Button';
import B1Modal from '../../components/modal/B1Modal';
import ProgressBar from '../../components/progressbar/ProgressBar';
import { isApiError } from '../../utils/api-util';
import { getCourseOrderIndex, getShortCourseName } from '../../utils/course-util';
import Layout from '../Layout';

import './Smartmode.scss';

interface SmartmodeCourse {
  identifier: string;
  lessons: SmartmodeLesson[];
  progress: number;
}

type SmartmodeData = {
  courses: SmartmodeCourse[];
};
type SmartmodeAction =
  | { type: 'RESET_COURSES' }
  | { type: 'ADD_COURSE'; identifier: string; lessons: SmartmodeLesson[]; progress: number }
  | { type: 'SORT_COURSES' };

const examSimulatorReducer = (state: SmartmodeData, action: SmartmodeAction): SmartmodeData => {
  switch (action.type) {
    case 'RESET_COURSES':
      return { ...state, courses: [] };
    case 'ADD_COURSE':
      return {
        ...state,
        courses: [
          ...state.courses,
          { identifier: action.identifier, lessons: action.lessons, progress: action.progress },
        ],
      };
    case 'SORT_COURSES':
      return {
        ...state,
        courses: state.courses.sort((a, b) => {
          return getCourseOrderIndex(a.identifier) - getCourseOrderIndex(b.identifier);
        }),
      };
    default:
      return state;
  }
};

interface SmartmodeQuizButtonProps {
  lessonNo: number;
  lesson: SmartmodeLesson;
  course: SmartmodeCourse;
  handleLessonCompletedButtonClick: () => void;
}

const SmartmodeQuizButton: React.FC<SmartmodeQuizButtonProps> = ({
  lessonNo,
  lesson,
  course,
  handleLessonCompletedButtonClick,
}) => {
  const renderTooltip = (props: OverlayInjectedProps, questionCount: number) => {
    return (
      <Tooltip className='b1-tooltip' {...props}>
        Es existieren ingesamt {questionCount} Prüfungsfragen in der Lektion. Eine Prüfungsfrage
        wird als gelernt markiert, sobald diese 3-mal in Folge richtig beantwortet wurde.
      </Tooltip>
    );
  };

  const lessonButtonContent = (
    <>
      <span className='lesson-no'>{lessonNo}</span>
      <p>{lesson.title}</p>

      <OverlayTrigger
        placement='bottom'
        overlay={(props) => renderTooltip(props, lesson.questions_count)}
        trigger={['hover', 'focus']}
      >
        <div className={'lesson-questions'} onClick={(e) => e.preventDefault()} tabIndex={0}>
          <span className='lesson-questions-help'>
            {lesson.questions_count === lesson.level_3_count ? <BsCheck /> : '?'}
          </span>
          <span className='lesson-questions-count'>{lesson.questions_count}</span>
          <div className='lesson-questions-divider'></div>
          <span className='lesson-questions-answered'>{lesson.level_3_count}</span>
        </div>
      </OverlayTrigger>
    </>
  );

  if (lesson.questions_count === lesson.level_3_count) {
    return (
      <button
        className='smartmode-lesson scale-effect lesson-completed'
        onClick={handleLessonCompletedButtonClick}
      >
        {lessonButtonContent}
      </button>
    );
  } else {
    return (
      <Link
        className='smartmode-lesson scale-effect'
        to={`/course/${course.identifier.toLowerCase()}/smartmode/${lesson.id}`}
      >
        {lessonButtonContent}
      </Link>
    );
  }
};

const Smartmode: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>();
  const [smartmodeData, dispatchSmartmodeData] = useReducer(examSimulatorReducer, {
    courses: [],
  });
  const [lessonCompletedModalOpen, setLessonCompletedModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<string>();
  const [selectedLesson, setSelectedLesson] = useState<number>();
  const [confirmResetProgress, setConfirmResetProgress] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [sortCourses, setSortCourses] = useState(false);
  const courses: string[] = [];

  if (identifier.toLowerCase() === 'sbf') {
    courses.push(CourseType.SBFS);
    courses.push(CourseType.SBFB);
    // todo: check if user has sbfs or only sbfbb, currently one runs into 401 error
    courses.push(CourseType.SBFN);
    courses.push(CourseType.SBFBB);
  } else if (identifier.toLowerCase() === 'psbf') {
    courses.push(CourseType.PSBFS);
    courses.push(CourseType.PSBFB);
    courses.push(CourseType.PSBFN);
    courses.push(CourseType.PSBFBB);
  } else {
    courses.push(identifier);
  }

  const loadCourses = () => {
    setForceReload(false);
    dispatchSmartmodeData({ type: 'RESET_COURSES' });

    courses.forEach((course) => {
      getLessons(course).then((res) => {
        if (isApiError(res)) {
          console.error(res);
          return;
        }

        res.sort((a, b) => {
          return a.id - b.id;
        });

        const questionCount = res.reduce((a, b) => a + b.questions_count, 0);
        const level1Count = res.reduce((a, b) => a + b.level_1_count, 0);
        const level2Count = res.reduce((a, b) => a + b.level_2_count, 0);
        const level3Count = res.reduce((a, b) => a + b.level_3_count, 0);
        const progress = (level3Count * 3 + level2Count * 2 + level1Count) / (questionCount * 3);

        dispatchSmartmodeData({
          type: 'ADD_COURSE',
          identifier: course,
          lessons: res,
          progress: progress,
        });

        setSortCourses(true);
      });
    });
  };

  useIonViewWillEnter(() => {
    loadCourses();
  }, [identifier]);

  useEffect(() => {
    if (!forceReload) return;

    loadCourses();
  }, [forceReload]);

  useEffect(() => {
    if (!sortCourses) return;

    setSortCourses(false);
    dispatchSmartmodeData({ type: 'SORT_COURSES' });
  }, [sortCourses]);

  const getLinkToFirstLessonWithNotCompletedQuestions = (course: SmartmodeCourse) => {
    const lesson = course.lessons.find((lesson) => lesson.level_3_count !== lesson.questions_count);

    if (lesson === undefined) {
      return <></>;
    }

    return (
      <B1Button
        className='button-sm'
        href={`/course/${course.identifier.toLowerCase()}/smartmode/${lesson.id}`}
      >
        <span>Weiter lernen</span>
        <BsArrowRight />
      </B1Button>
    );
  };

  const handleResetLessonProgress = () => {
    if (selectedCourse && selectedLesson) {
      resetQuestionProgress(selectedCourse, selectedLesson).then((res) => {
        if (isApiError(res)) {
          console.error(res);
          return;
        }

        // TODO: Add success toast
        alert('Der Fortschritt wurde zurückgesetzt.');

        setLessonCompletedModalOpen(false);
        setConfirmResetProgress(false);
        setForceReload(true);
      });
    }
  };

  return (
    <Layout
      backButton={`/course/${identifier}`}
      contentClassName='smartmode'
      currentCourseType={courses[0]}
    >
      <div
        className={`smartmode-container${
          smartmodeData.courses.length % 2 === 0 ? '' : ' condensed'
        }`}
      >
        <h1>Smartmodus</h1>
        <p className='smartmode-description'>
          Lerne die originalen Prüfungsfragen nach den Lektionen des Kurses, und bereite Dich so auf
          den Prüfungsmodus vor.
        </p>

        <Row className='gy-4'>
          {smartmodeData.courses.map((course, i) => (
            <Col key={i} xs={12} lg={smartmodeData.courses.length % 2 === 0 ? 6 : 12}>
              <div className='smartmode-course-header'>
                <h5>{getShortCourseName(course.identifier)}</h5>
                {getLinkToFirstLessonWithNotCompletedQuestions(course)}
              </div>
              <div className='smartmode-course-progress'>
                <p className='description'>Fortschritt:</p>
                <ProgressBar progress={course.progress} showProgressNumber />
              </div>
              {course.lessons.map((lesson, i) => (
                <SmartmodeQuizButton
                  key={i}
                  lessonNo={i + 1}
                  lesson={lesson}
                  course={course}
                  handleLessonCompletedButtonClick={() => {
                    setLessonCompletedModalOpen(true);
                    setSelectedCourse(course.identifier);
                    setSelectedLesson(lesson.id);
                  }}
                />
              ))}
            </Col>
          ))}
        </Row>
      </div>
      <B1Modal
        className='lesson-completed-modal'
        open={lessonCompletedModalOpen}
        onModalClose={() => setLessonCompletedModalOpen(false)}
      >
        {!confirmResetProgress ? (
          <>
            <h1 className='emoji'>🤓</h1>
            <h2>Du hast diese Lektion bereits gelernt</h2>
            <B1Button className='button-block' click={() => setLessonCompletedModalOpen(false)}>
              Weiter lernen
              <BsArrowRight />
            </B1Button>
            <B1Button className='button-obscure' click={() => setConfirmResetProgress(true)}>
              Lektion zurücksetzen
            </B1Button>
          </>
        ) : (
          <>
            <h1 className='emoji'>😨</h1>
            <h2>Möchtest du deinen Fortschritt wirklich zurücksetzen?</h2>
            <B1Button className='button-block button-red' click={handleResetLessonProgress}>
              Fortschritt zurücksetzen
            </B1Button>
            <B1Button
              className='button-block button-reverse'
              click={() => setConfirmResetProgress(false)}
            >
              Zurück
            </B1Button>
          </>
        )}
      </B1Modal>
    </Layout>
  );
};

export default Smartmode;

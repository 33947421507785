import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { getRadioTextRecordings } from '../../../api/courses/radio';
import { RadioTextRecording } from '../../../api/interfaces/radio-course';
import B1Markdown from '../../../components/markdown/B1Markdown';
import NavigationCircles from '../../../components/navigation-circles/NavigationCircles';
import { isApiError } from '../../../utils/api-util';

import './RadioTextRecordings.scss';

const Recording: React.FC<{ recording: RadioTextRecording }> = ({ recording }) => {
  return (
    <>
      <div className='simple-card card-white'>
        <h3>Aufgabe {recording.nr}</h3>
        <audio src={recording.mp3_link} controls></audio>
      </div>

      <Accordion
        key={recording.id}
        className='b1-accordion b1-accordion-open-white'
        defaultActiveKey={undefined}
      >
        <Accordion.Item eventKey={'0'}>
          <Accordion.Header>Lösung</Accordion.Header>
          <Accordion.Body>
            <B1Markdown>{recording.answer_text}</B1Markdown>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

const RadioTextRecordings: React.FC<{ identifier: string }> = ({ identifier }) => {
  const [radioTextRecordings, setRadioTextRecordings] = useState<RadioTextRecording[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    getRadioTextRecordings(identifier).then((res) => {
      if (isApiError(res)) {
        console.error(res);
      } else {
        setRadioTextRecordings(res);
      }
    });
  }, [identifier]);

  return (
    <div className='radio-text-recordings'>
      <h1>Textaufnahme</h1>
      <div className='simple-card card-white'>
        <p className='mb-3'>
          Bei der <strong>Textaufnahme</strong> liest der Prüfer einen Text vor, den Du mitschreiben
          musst. Für Schiffsnamen, Ortsnamen und komplizierte Wörter benutzt der Prüfer die
          internationale Buchstabiertafel, um die Wörter zu buchstabieren.
        </p>

        <p>
          Du findest hier Beispiele von Funktexten, die bei der Textaufnahme verwendet werden
          können.
        </p>
        <p className='mb-3'>Höre Dir diese an und schreibe sie mit.</p>

        <p>
          Darunter findest Du dann immer den ausgeschriebenen Funktext als Lösung, mit dem Du Deinen
          Text vergleichen kannst.
        </p>
      </div>

      <NavigationCircles
        list={radioTextRecordings}
        currentId={radioTextRecordings[selectedIndex]?.id}
        onClick={(index) => setSelectedIndex(index)}
      />

      {radioTextRecordings[selectedIndex] && (
        <Recording recording={radioTextRecordings[selectedIndex]} key={selectedIndex} />
      )}
    </div>
  );
};

export default RadioTextRecordings;

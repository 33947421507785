import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import Ratio from 'react-bootstrap/Ratio';
import { TfiDownload } from 'react-icons/tfi';

import { useParams } from 'react-router';
import { getKnots } from '../../api/courses/knots';
import Knot from '../../api/interfaces/knot';
import B1Button from '../../components/buttons/B1Button';
import VideoPlayer from '../../components/video-player/VideoPlayer';
import { isApiError } from '../../utils/api-util';
import { getMainCourse } from '../../utils/course-util';
import Layout from '../Layout';

import './Knots.scss';

interface KnotBodyProps {
  knot: Knot;
  defaultActive: boolean;
}

const KnotBody: React.FC<KnotBodyProps> = ({ knot, defaultActive }) => {
  const [playing, setPlaying] = React.useState<boolean>();
  const [opened, setOpened] = React.useState<boolean>(defaultActive);

  const getInfo = (knot: Knot) => {
    if (knot.knot_pics_links && knot.knot_pics_links.length > 0) {
      return knot.info;
    } else {
      return knot.info.split('\n').slice(1).join('\n');
    }
  };

  return (
    <Accordion.Body onExiting={() => setPlaying(false)} onEnter={() => setOpened(true)}>
      <p className='mb-3'>{getInfo(knot)}</p>
      {knot.video &&
        (opened ? (
          <VideoPlayer
            video={knot.video}
            play={playing}
            onPlayingStateChange={(state) => setPlaying(state)}
          />
        ) : (
          <Ratio aspectRatio='16x9'>
            <div style={{ backgroundColor: '#000' }}></div>
          </Ratio>
        ))}
      {knot.video_yt &&
        (opened ? (
          <VideoPlayer
            url={knot.video_yt}
            play={playing}
            onPlayingStateChange={(state) => setPlaying(state)}
          />
        ) : (
          <Ratio aspectRatio='16x9'>
            <div style={{ backgroundColor: '#000' }}></div>
          </Ratio>
        ))}
    </Accordion.Body>
  );
};

const Knots: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>();
  const [knots, setKnots] = React.useState<Knot[]>([]);

  useEffect(() => {
    getKnots(identifier).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      } else {
        setKnots(res);
      }
    });
  }, [identifier]);

  const getAccordionTitle = (knot: Knot) => {
    if (knot.knot_pics_links && knot.knot_pics_links.length > 0) {
      return 'Verwendung';
    } else {
      return knot.info.split('\n')[0];
    }
  };

  return (
    <Layout
      backButton={`/course/${getMainCourse(identifier)}`}
      contentClassName='knots'
      currentCourseType={identifier}
    >
      <div className='knots-container'>
        <Accordion className='b1-accordion' defaultActiveKey='0'>
          {knots.map((knot, index) => (
            <div key={index} className='knot-item'>
              <div className='knot-header'>
                <h1>{knot.title}</h1>
                {knot.knot_pdf && (
                  <B1Button
                    className='button-sm button-transparent'
                    downloadAndOpen
                    href={knot.knot_pdf}
                  >
                    <TfiDownload />
                    <span>PDF</span>
                  </B1Button>
                )}
              </div>
              {knot.knot_pics_links && knot.knot_pics_links.length > 0 && (
                <Carousel
                  interval={null}
                  prevIcon={<img src='assets/icons/arrow-back.svg' />}
                  nextIcon={<img src='assets/icons/arrow-forward.svg' />}
                >
                  {knot.knot_pics_links.map((pic, id) => (
                    <Carousel.Item key={id}>
                      <img src={pic} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
              <Accordion.Item key={index} eventKey={String(index)}>
                <Accordion.Header>{getAccordionTitle(knot)}</Accordion.Header>
                <KnotBody knot={knot} defaultActive={index === 0} />
              </Accordion.Item>
              {knots.length - 1 > index && <hr />}
            </div>
          ))}
        </Accordion>
      </div>
    </Layout>
  );
};

export default Knots;

import { IonSpinner, RouterOptions, useIonRouter, useIonViewDidLeave } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import { checkCode } from '../../api/codes';
import CodeInterface, { CodeCourse } from '../../api/interfaces/code';
import { isApiError } from '../../utils/api-util';
import { sendRedeemCodeRequest } from '../../utils/code-util';
import { formatDuration, wait } from '../../utils/misc-util';
import B1Button from '../buttons/B1Button';
import Code from '../code/Code';
import B1Modal from '../modal/B1Modal';

import './RedeemCodeForm.scss';

interface RedeemCodeFormProps {
  continueTo: string;
  shouldRedeemCode?: boolean;
  initialCode?: string;
  onCodeChecked?: (code: CodeInterface) => void;
}

const RedeemCodeForm: React.FC<RedeemCodeFormProps> = ({
  continueTo,
  shouldRedeemCode,
  initialCode,
  onCodeChecked,
}) => {
  const [codeKey, setCodeKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [courses, setCourses] = useState<CodeCourse[]>([]);
  const [valid, setValid] = useState<boolean>();
  const [code, setCode] = useState<CodeInterface>();
  const [codeHelpModalOpen, setCodeHelpModalOpen] = useState(false);

  const ionRouter = useIonRouter();

  useEffect(() => {
    if (initialCode) {
      submitCode(initialCode);
    }
  }, [initialCode]);

  useIonViewDidLeave(() => {
    setCodeKey(codeKey + 1);
    setValid(undefined);
    setLoading(false);
    setCode(undefined);
    setCourses([]);
    setErrorText('');
  });

  const handleRedeemCode = async () => {
    const redeemCodeResult = await sendRedeemCodeRequest(code);

    if (typeof redeemCodeResult === 'string') {
      setErrorText(redeemCodeResult);
    } else {
      ionRouter.push(continueTo, 'forward', 'push', { from: 'redeem-code' } as RouterOptions);
    }
  };

  async function submitCode(code: string) {
    setLoading(true);
    const time = Date.now();
    const codeResult = await checkCode(code);
    const duration = Date.now() - time;

    // Artificial delay, so that it looks not "too fast".
    await wait(500 - duration);

    if (codeResult) {
      if (isApiError(codeResult)) {
        setValid(false);

        if (codeResult.statusCode === 400) {
          setErrorText('Code ungültig.');
        } else if (codeResult.statusCode === 429) {
          const duration = formatDuration(codeResult.retryAfter ?? 0);
          setErrorText(`Zu viele Anfragen. Versuche es erneut in ${duration}`);
        } else {
          setErrorText('Unbekannter Fehler bei Server-Kommunikation.');
        }
      } else if (codeResult.courses.length > 0) {
        setCourses(codeResult.courses);
        setCode(codeResult);
        setValid(true);

        if (onCodeChecked) {
          onCodeChecked(codeResult);
        }
      } else {
        // Code für keine Kurse gültig
        setValid(false);
        setErrorText('Code ungültig.');
      }
    } else {
      setValid(false);
      setErrorText('Unbekannter Fehler bei Server-Kommunikation.');
    }

    setLoading(false);
    await wait(100);

    if (!valid) {
      return false;
    }

    return true;
  }

  return (
    <>
      <div className='redeem-code-form'>
        <Code
          key={codeKey}
          initialCode={initialCode}
          fields={6}
          valid={valid}
          submitCode={submitCode}
        />
        {valid === undefined && (
          <p className='activation-code-help' onClick={() => setCodeHelpModalOpen(true)}>
            Wo finde ich meinen Code?
          </p>
        )}
        {loading && <IonSpinner name='circular' />}
        {!loading && valid !== undefined && valid && (
          <div className='code-result'>
            <div className='code-status'>
              <img src='assets/icons/check-icon-filled.svg' />
              <span className='code-status-text'>Code gültig für:</span>
            </div>
            <p className='courses'>{courses.map((course) => course.title).join(' & ')}</p>
            {shouldRedeemCode ? (
              <B1Button className='button-block continue-button' click={handleRedeemCode}>
                Code einlösen
              </B1Button>
            ) : (
              <B1Button className='button-block continue-button' href={continueTo}>
                Weiter
              </B1Button>
            )}
          </div>
        )}
        {!loading && valid !== undefined && !valid && (
          <div className='code-result'>
            <div className='code-status'>
              <img src='assets/icons/warning.svg' />
              <span className='code-status-text'>{errorText}</span>
            </div>
            <p className='activation-code-help' onClick={() => setCodeHelpModalOpen(true)}>
              Wo finde ich meinen Code?
            </p>
            <p className='code-support'>
              Support:
              <br />
              <a href='tel:+49 581 97093000'>+49 581 97093000</a> oder{' '}
              <a href='mailto:info@bootsschule1.de'>info@bootsschule1.de</a>
            </p>
          </div>
        )}
      </div>
      <B1Modal
        className='code-help-modal'
        open={codeHelpModalOpen}
        onModalClose={() => setCodeHelpModalOpen(false)}
      >
        <h4 className='mb-3'>Aktivierungscode</h4>
        <p className='mb-2'>
          Der Aktivierungscode zur Freischaltung wurde bei Kauf an Deine E-Mail geschickt.
        </p>
        <p className='mb-4'>Du hast noch keinen Kurs gebucht?</p>
        <B1Button href='https://bootsschule1.de/bootsfuehrerschein/' external>
          Kurse entdecken
        </B1Button>
      </B1Modal>
    </>
  );
};

export default RedeemCodeForm;

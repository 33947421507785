import { isApiError } from '../utils/api-util';
import { makeApiRequest } from './api';
import Code from './interfaces/code';

export const checkCode = async (code: string) => {
  const res = await makeApiRequest<Code>('GET', 'v1/codes/' + code);

  if (isApiError(res)) {
    return res;
  }

  if (res.status === 200) {
    return res.data;
  } else {
    return null;
  }
};

export const redeemCode = async (code: string) => {
  const res = await makeApiRequest('POST', 'v1/codes/redeem', undefined, {
    code: code,
  });

  if (isApiError(res)) {
    return res;
  }

  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
};

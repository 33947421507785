import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import React, { useEffect, useRef, useState } from 'react';
import { BsArrowsAngleContract, BsArrowsAngleExpand } from 'react-icons/bs';
import { TfiDownload } from 'react-icons/tfi';
import { Document, Page } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import screenfull from 'screenfull';

import B1Button from '../../../components/buttons/B1Button';

import './PdfViewer.scss';

const pdfOptions = {
  withCredentials: true,
};

interface PdfViewerProps {
  url?: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ url }) => {
  const pdfViewerRef = useRef<HTMLDivElement>(null);
  const [numPages, setNumPages] = useState<number>();
  const [fullscreen, setFullscreen] = useState(false);
  const [file, setFile] = useState<ArrayBuffer | string>();
  const [filePath, setFilePath] = useState<string>();

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        setFullscreen(screenfull.isFullscreen);
      });
    }
  }, []);

  useEffect(() => {
    if (url) {
      if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
        const fileNameMatch = url.match(/.*\/(\S*?)\.pdf/);
        let path = `file-${Date.now()}.pdf`;

        if (fileNameMatch && fileNameMatch.length > 1) {
          path = `${fileNameMatch[1]}.pdf`;
        }

        Filesystem.downloadFile({
          url: url,
          path: path,
          directory: Directory.Cache,
          recursive: true,
          webFetchExtra: {
            credentials: 'include',
            mode: 'cors',
          },
        }).then((downloadedFile) => {
          if (downloadedFile.path) {
            setFilePath(downloadedFile.path);

            Filesystem.readFile({ path: path, directory: Directory.Cache }).then((res) => {
              if (typeof res.data === 'string') {
                const binaryString = atob(res.data);
                const bytes = new Uint8Array(binaryString.length);

                for (let i = 0; i < binaryString.length; i++) {
                  bytes[i] = binaryString.charCodeAt(i);
                }

                setFile(bytes.buffer);
              }
            });
          }
        });
      } else {
        setFile(url);
      }
    }
  }, [url]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const toggleFullscreen = () => {
    if (pdfViewerRef.current && screenfull.isEnabled) {
      screenfull.toggle(pdfViewerRef.current);
    }
  };

  const getDownloadButton = () => {
    if (Capacitor.isNativePlatform()) {
      return (
        <B1Button
          className='button-grey button-md'
          click={() => filePath && FileOpener.open({ filePath: filePath, openWithDefault: true })}
        >
          <TfiDownload /> PDF
        </B1Button>
      );
    } else {
      return (
        <B1Button className='button-grey button-md' href={url} external>
          <TfiDownload /> PDF
        </B1Button>
      );
    }
  };

  if (!url) return <></>;

  return (
    <div className='pdf-viewer' ref={pdfViewerRef}>
      <div className='pdf-header'>
        {getDownloadButton()}
        {screenfull.isEnabled && (
          <B1Button className='button-grey button-square' click={toggleFullscreen}>
            {fullscreen ? <BsArrowsAngleContract /> : <BsArrowsAngleExpand />}
          </B1Button>
        )}
      </div>
      <div className='pdf-content'>
        <SizeMe monitorHeight refreshRate={128} refreshMode={'debounce'}>
          {({ size }) => (
            <div>
              <Document
                file={file}
                options={pdfOptions}
                onLoadSuccess={onDocumentLoadSuccess}
                noData='Keine PDF angegeben.'
                loading='PDF wird geladen...'
                error='PDF konnte nicht geladen werden.'
              >
                {numPages !== undefined &&
                  Array.from({ length: numPages }, (_, i) => i + 1).map((i) => (
                    <Page
                      key={i}
                      pageNumber={i}
                      renderAnnotationLayer={false}
                      width={size.width ?? 0}
                      className={'pdf-page'}
                      noData='Seite wurde nicht gefunden.'
                      loading='Seite wird geladen...'
                      error='Seite konnte nicht geladen werden.'
                    />
                  ))}
              </Document>
            </div>
          )}
        </SizeMe>
      </div>
    </div>
  );
};

export default PdfViewer;

import { IonSkeletonText, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Ratio } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import { useParams } from 'react-router';

import { getUserCourses } from '../../api/courses/courses';
import { addExamBooking, getExamBookingInfo } from '../../api/courses/exam-booking';
import { CourseType } from '../../api/interfaces/course';
import { ExamPlace, RequestTime, UserExam } from '../../api/interfaces/exam-booking-info';
import Video from '../../api/interfaces/video';
import B1Button from '../../components/buttons/B1Button';
import B1Input from '../../components/forms/B1Input';
import B1Select from '../../components/forms/B1Select';
import ChangeAddressModal from '../../components/modal/change-address-modal/ChangeAddressModal';
import ChangePersonalDataModal from '../../components/modal/change-personal-data-modal/ChangePersonalDataModal';
import VideoPlayer from '../../components/video-player/VideoPlayer';
import useWindowSize from '../../hooks/window-size-hook';
import MAP_SETTINGS from '../../resources/map-settings';
import { isApiError } from '../../utils/api-util';
import {
  SBF_COURSE_TYPES,
  SKS_COURSE_TYPES,
  getCoursesProgress,
  getMainCourse,
} from '../../utils/course-util';
import Layout from '../Layout';

import './ExamBookingMap.scss';

const PROGRESS_THRESHOLD = [
  {
    course: 'sbf',
    // threshold: 85,
    threshold: 0,
  },
  {
    course: 'sks',
    threshold: 0,
  },
];
const MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
// https://developers.google.com/maps/documentation/javascript/geolocation?hl=de

const ExamBookingMap: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>();
  const { width } = useWindowSize();
  const [introVideo, setIntroVideo] = useState<Video | null>();
  const [places, setPlaces] = useState<ExamPlace[]>([]);
  const [userExam, setUserExam] = useState<UserExam>();
  const [courseProgress, setCourseProgress] = useState<number>(0);
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [selectedLocationId, setSelectedLocationId] = useState<number>();
  const [desiredDate, setDesiredDate] = useState('');
  const [requestTime, setRequestTime] = useState<RequestTime>();
  const [tempLicenseWanted, setTempLicenseWanted] = useState<boolean>(false);
  const [confirmedRequest, setConfirmedRequest] = useState<boolean>(false);
  const [changeAddressModalOpen, setChangeAddressModalOpen] = useState<boolean>(false);
  const [changePersonalDataModalOpen, setChangePersonalDataModalOpen] = useState<boolean>(false);
  const ionRouter = useIonRouter();

  useIonViewWillEnter(() => {
    getUserCourses().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      const mainCourse = getMainCourse(identifier);
      let course_types: CourseType[] = [];

      if (mainCourse === 'sbf') {
        course_types = SBF_COURSE_TYPES;
      } else if (mainCourse === 'sks') {
        course_types = SKS_COURSE_TYPES;
      }

      const progress = getCoursesProgress(
        res.filter((c) => c.active && c.claimed),
        course_types
      );

      setCourseProgress(progress * 100);
    });
  }, [identifier]);

  useEffect(() => {
    getExamBookingInfo(identifier).then((res) => {
      if (isApiError(res)) {
        setIntroVideo(null);
        console.error(res);
        return;
      }

      setIntroVideo(res.video ?? null);
      setPlaces(res.exam_places.sort((a, b) => a.location.localeCompare(b.location)));
      setUserExam(res.user_exam);
    });
  }, [identifier]);

  const fitBounds = () => {
    if (!map) return;

    const bounds = new google.maps.LatLngBounds();

    places.map((place) => {
      bounds.extend({ lat: place.lat, lng: place.lng });
      return place.id;
    });

    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (places.length > 0) {
      fitBounds();
    }
  }, [map, places, width]);

  const { isLoaded } = useJsApiLoader({
    id: 'practical-training-locations-map',
    googleMapsApiKey: MAPS_API_KEY,
    language: 'de',
    region: 'DE',
  });

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  const getThreshold = () => {
    const course = PROGRESS_THRESHOLD.find((t) => t.course === getMainCourse(identifier));

    return course ? course.threshold : 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (userExam) {
      alert('Du hast bereits eine Buchungsanfrage für diesen Kurs gestellt.');
      return;
    } else if (courseProgress < getThreshold()) {
      alert(
        `Eine Buchungsanfrage ist erst ab einem Lernstand von ${PROGRESS_THRESHOLD} % möglich.`
      );
      return;
    } else if (
      selectedLocationId === undefined ||
      !desiredDate ||
      (!requestTime && getMainCourse(identifier) !== 'skn') ||
      !confirmedRequest
    ) {
      alert('Bitte fülle alle Felder aus.');
      return;
    }

    setChangeAddressModalOpen(true);
  };

  const addressChanged = () => {
    setChangeAddressModalOpen(false);
    setChangePersonalDataModalOpen(true);
  };

  const personalDataChanged = async () => {
    setChangePersonalDataModalOpen(false);

    if (selectedLocationId === undefined) return;
    if (!requestTime && getMainCourse(identifier) !== 'skn') return;

    const res = await addExamBooking(
      identifier,
      selectedLocationId,
      new Date(desiredDate),
      requestTime ?? RequestTime.AM,
      getMainCourse(identifier) === 'sbf' ? tempLicenseWanted : undefined
    );

    if (isApiError(res)) {
      // TODO: Show error toast
      if (res.statusCode === 409) {
        alert('Du hast bereits eine Buchungsanfrage für diesen Kurs gestellt.');
      } else {
        alert('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.');
        console.error(res);
      }
      return;
    }

    // TODO: Show success toast
    alert('Deine Buchungsanfrage wurde erfolgreich gesendet.');
    ionRouter.push('/course/' + identifier, 'back', 'pop');
  };

  return (
    <Layout
      backButton={
        getMainCourse(identifier) === 'sbf'
          ? `/course/${identifier}/practical-training`
          : `/course/${getMainCourse(identifier)}`
      }
      contentClassName='exam-booking-map'
      currentCourseType={identifier}
    >
      <div className='exam-booking-container'>
        {getMainCourse(identifier) === 'sbf' && (
          <>
            <h1>Praxisausbildung und Prüfung</h1>
            <div className='practical-training-explanation simple-card card-light-gray'>
              {introVideo ? (
                <VideoPlayer video={introVideo} />
              ) : introVideo === undefined ? (
                <Ratio aspectRatio='16x9'>
                  <IonSkeletonText animated />
                </Ratio>
              ) : (
                <></>
              )}
              <p className='mb-3'>
                Endlich geht es los – bald sitzt Du selbst hinter dem Steuer! Auf der Karte weiter
                unten kannst Du nachschauen, an welchen Standorten unsere qualifizierten Partner
                eine Fahrstunde anbieten und wo die Prüfung stattfinden kann.
              </p>
              <p className='mb-3'>
                Du kannst nun unten einen Termin und Wunschort auswählen, der uns zur terminlichen
                Einordnung Deiner Praxisausbildung und Prüfung hilft. Auf dessen Grundlagen schlagen
                wir Dir Prüfungstermine vor, stimmen sie mit Dir ab und machen im Anschluss
                Fahrtermine aus, um die wichtigen Manöver der Praxisprüfung zu erlernen.
              </p>
              <p className='mb-3'>
                In der Regel findet die Theorie- und Praxisprüfung am selben Tag statt, die
                Praxisausbildung einige Tage davor – mehr dazu aber im persönlichen Austausch mit
                Dir.
              </p>
              <p className='brand-blue'>
                In der Fahrstunde lernst Du dann alle wichtigen Manöver, die Du in der Prüfung
                beherrschen musst. Zudem zeigt Dir der Fahrlehrer, an welchem Standort Du zur
                Prüfung erscheinen musst.
              </p>
            </div>

            <hr />
          </>
        )}

        {getMainCourse(identifier) === 'sbf' ? (
          <h3>Buchungsanfrage senden</h3>
        ) : (
          <h1>Buchungsanfrage senden</h1>
        )}
        <div className='simple-card card-light-gray'>
          {getMainCourse(identifier) === 'sks' && (
            <p className='mb-4'>
              Auf der Karte weiter unten kannst Du nachschauen, an welchen Standorten Du die SKS
              Theorie- und Navigationsprüfung ablegen kannst. Gleich{' '}
              {(width ?? 0) < 576 ? 'darunter' : 'daneben'} kannst Du eine entsprechende Prüfung
              anfragen.
            </p>
          )}
          {userExam ? (
            <p className='booking-note'>
              Du hast bereits eine Buchungsanfrage für diesen Kurs gestellt. Bei Änderungswünschen
              kontaktiere bitte den Support oder schreibe eine E-Mail an{' '}
              <a href='mailto:info@bootsschule1.de'>info@bootsschule1.de</a>.
            </p>
          ) : (
            getMainCourse(identifier) === 'sbf' && (
              <p className='booking-note'>
                Um Dich für Deine Prüfungen und Deine Fahrausbildung anzumelden, gib bitte unten den
                gewünschen Ort, Deinen Wunschtermin sowie Deinen Wunschzeitraum an. Wir werden uns
                dann mit Dir in Verbindung setzen und Termine auf dieser Basis vorschlagen.
              </p>
            )
          )}
          <form className='b1-form form-white' onSubmit={handleSubmit}>
            {!userExam && courseProgress < getThreshold() && (
              <p className='booking-note-learning-level bold'>
                Achtung: Die Buchung der Fahrstunde ist erst ab einem Lernstand von {getThreshold()}{' '}
                % möglich.
              </p>
            )}
            <div className='booking-map-container'>
              <div className='map-container'>
                {isLoaded && (
                  <GoogleMap
                    mapContainerClassName='exam-booking-map'
                    center={MAP_SETTINGS.center}
                    zoom={5}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={{
                      fullscreenControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                      zoomControl: true,
                      styles: MAP_SETTINGS.styles,
                    }}
                  >
                    <MarkerClusterer minimumClusterSize={4}>
                      {(clusterer) => (
                        <>
                          {places.map((place, index) => (
                            <Marker
                              key={index}
                              position={{
                                lat: place.lat,
                                lng: place.lng,
                              }}
                              icon={MAP_SETTINGS.icon}
                              clusterer={clusterer}
                              onClick={() => setSelectedLocationId(place.id)}
                              title={place.location}
                            />
                          ))}
                        </>
                      )}
                    </MarkerClusterer>
                  </GoogleMap>
                )}
              </div>
              <div className='booking-formular'>
                {userExam ? (
                  <>
                    <h5>Deine Buchungsanfrage</h5>
                    <B1Input
                      label='Ort'
                      type='text'
                      id='desired-location'
                      value={userExam.place}
                      disabled
                    />
                    <B1Input
                      label='Datum'
                      type='date'
                      id='desired-date'
                      value={DateTime.fromISO(userExam.exam_request_date).toFormat('yyyy-MM-dd')}
                      disabled
                    />
                    <label htmlFor='desired-time'>Wunschzeit</label>
                    <div className='mb-3'>
                      <FormCheck
                        inline
                        type='radio'
                        name='desired-time'
                        id='desired-time-morning'
                        label='Vormittags'
                        checked={userExam.exam_request_time === RequestTime.AM}
                        disabled
                      />
                      <FormCheck
                        inline
                        type='radio'
                        name='desired-time'
                        id='desired-time-afternoon'
                        label='Nachmittags'
                        checked={userExam.exam_request_time === RequestTime.PM}
                        disabled
                      />
                    </div>
                    {getMainCourse(identifier) === 'sbf' && (
                      <FormCheck
                        className='mb-3'
                        type='checkbox'
                        name='temp-license-wanted'
                        id='temp-license-wanted'
                        label='Vorläufigen Führerschein beantragen. Dieser wird am Tage der Prüfung ausgehändigt oder zugesandt, er hat eine Gültigkeit von drei Monaten und kostet 23,83 €.'
                        checked={userExam.temp_license_wanted}
                        disabled
                      />
                    )}
                  </>
                ) : (
                  <>
                    <B1Select
                      label='Ort'
                      placeholder='Ort auswählen'
                      type='text'
                      id='desired-location'
                      value={selectedLocationId}
                      options={places.map((m) => {
                        return { value: String(m.id), label: m.location };
                      })}
                      onChange={(e) => {
                        if (e.currentTarget.value === '') {
                          setSelectedLocationId(undefined);
                        } else {
                          setSelectedLocationId(Number(e.currentTarget.value));
                        }
                      }}
                      disabled={courseProgress < getThreshold()}
                    />
                    <B1Input
                      label='Datum'
                      type='date'
                      id='desired-date'
                      value={desiredDate}
                      min={DateTime.now().plus({ week: 2 }).toFormat('yyyy-MM-dd')}
                      max={DateTime.now().plus({ months: 6 }).toFormat('yyyy-MM-dd')}
                      onInput={(e) => setDesiredDate((e.target as HTMLInputElement).value)}
                      disabled={courseProgress < getThreshold()}
                    />
                    {getMainCourse(identifier) !== 'skn' && (
                      <>
                        <label htmlFor='desired-time'>Wunschzeit</label>
                        <div className='mb-3'>
                          <FormCheck
                            inline
                            type='radio'
                            name='desired-time'
                            id='desired-time-morning'
                            label='Vormittags'
                            checked={requestTime === RequestTime.AM}
                            onChange={(e) =>
                              e.currentTarget.checked && setRequestTime(RequestTime.AM)
                            }
                            disabled={courseProgress < getThreshold()}
                          />
                          <FormCheck
                            inline
                            type='radio'
                            name='desired-time'
                            id='desired-time-afternoon'
                            label='Nachmittags'
                            checked={requestTime === RequestTime.PM}
                            onChange={(e) =>
                              e.currentTarget.checked && setRequestTime(RequestTime.PM)
                            }
                            disabled={courseProgress < getThreshold()}
                          />
                        </div>
                      </>
                    )}
                    {getMainCourse(identifier) === 'sbf' && (
                      <FormCheck
                        className='mb-3'
                        type='checkbox'
                        name='temp-license-wanted'
                        id='temp-license-wanted'
                        label='Vorläufigen Führerschein beantragen. Dieser wird am Tage der Prüfung ausgehändigt oder zugesandt, er hat eine Gültigkeit von drei Monaten und kostet 23,83 €.'
                        checked={tempLicenseWanted}
                        onChange={(e) => setTempLicenseWanted(e.currentTarget.checked)}
                        disabled={courseProgress < getThreshold()}
                      />
                    )}
                    <FormCheck
                      type='checkbox'
                      name='confirm-request'
                      id='confirm-request'
                      label='Ich bestätige meine Buchungsanfrage verbindlich.'
                      checked={confirmedRequest}
                      onChange={(e) => setConfirmedRequest(e.currentTarget.checked)}
                      disabled={courseProgress < getThreshold()}
                    />
                  </>
                )}
              </div>
            </div>
            {getMainCourse(identifier) === 'sbf' && (
              <div className='fitness-proof-container d-flex justify-content-center align-items-center'>
                <p>
                  Information: Du hast vor dem 01.01.2023 bestellt? - Lade Dir hier die aktuellste
                  Version vom ärztlichen Zeugnis / Tauglichkeitsnachweis herunter.
                </p>
                <B1Button
                  href='https://cdn.bootsschule1.app/courses/SBF_Praxistrainer/Pruefungsbuchung/Aerztlicher_Nachweis_medizinische_Tauglichkeit.pdf'
                  downloadAndOpen
                >
                  Formular herunterladen
                </B1Button>
              </div>
            )}
            {!userExam && (
              <div className='submit-button-container'>
                <B1Button
                  className='button-block'
                  type='submit'
                  disabled={courseProgress < getThreshold()}
                >
                  Buchungsanfrage stellen
                </B1Button>
              </div>
            )}
          </form>
        </div>
      </div>
      <ChangeAddressModal
        open={changeAddressModalOpen}
        setOpen={setChangeAddressModalOpen}
        onAddressChanged={addressChanged}
      />
      <ChangePersonalDataModal
        open={changePersonalDataModalOpen}
        setOpen={setChangePersonalDataModalOpen}
        onPersonalDataChanged={personalDataChanged}
      />
    </Layout>
  );
};

export default ExamBookingMap;

import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { CourseType } from '../../../api/interfaces/course';
import UserCourse from '../../../api/interfaces/user-course';
import AllInOneCourseCard from '../../../components/course/AllInOneCourseCard';
import DashboardWrapper from '../dashboard-wrapper/DashboardWrapper';

const SBFBSDashboard: React.FC = () => {
  const [userCourses, setUserCourses] = useState<UserCourse[]>([]);

  return (
    <DashboardWrapper courseType={CourseType.SBFBS} onUserCoursesLoaded={setUserCourses}>
      <Row className='g-3 course-cards'>
        <Col xl='8'>
          <Row className='g-3'>
            <Col xs='6'>
              <AllInOneCourseCard
                userCourse={userCourses.find((c) => c.identifier === CourseType.SBFBS)}
                hasPromotionImage
                hasChevronIcon
              />
            </Col>
            <Col xs='6'>
              <AllInOneCourseCard
                userCourse={
                  {
                    ...userCourses.find((c) => c.identifier === CourseType.SP),
                    identifier: CourseType.SBFBSP,
                  } as UserCourse
                }
                hasPromotionImage
                hasChevronIcon
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/BS1_Icon_Trips.svg'
                title='Praxis buchen'
                // description='Buche hier deine Praxisstunden.'
                description='Diese Funktion steht leider noch nicht zur Verfügung.'
                // locked={userCourses.find((c) => c.identifier === CourseType.SBFBS)?.expired}
                locked
                hasChevronIcon
                isCompact
                link='/course/sbfbs/practice-booking'
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/knots.svg'
                wideIcon
                title='Knoten'
                description='Übe hier die Knoten.'
                locked={userCourses.find((c) => c.identifier === CourseType.SBFBS)?.expired}
                hasChevronIcon
                isCompact
                link='/course/sbfbs/knots'
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/BS1_Icon_Exam_Booking.svg'
                title='Prüfung buchen'
                // description='Hier kannst Du Deine Prüfung buchen.'
                description='Diese Funktion steht leider noch nicht zur Verfügung.'
                // locked={userCourses.find((c) => c.identifier === CourseType.SBFBS)?.expired}
                locked
                hasChevronIcon
                isCompact
                link='/course/sbfbs/exam-booking'
              />
            </Col>
          </Row>
        </Col>
        <Col xl='4'>
          <Row className='g-3'>
            <Col sm='12'>
              <AllInOneCourseCard
                locked={userCourses.find((c) => c.identifier === CourseType.SBFBS)?.expired}
                link='/course/sbfbs/smartmode'
                icon='assets/icons/learn-mode.svg'
                title='Smartmodus'
                description='Hier kannst du alle Fragen, die in der Prüfung vorkommen können, effizient lernen.'
              />
            </Col>
            <Col sm='12'>
              <AllInOneCourseCard
                locked={userCourses.find((c) => c.identifier === CourseType.SBFBS)?.expired}
                link='/course/sbfbs/exam-training'
                icon='assets/icons/exam-simulation.svg'
                title='Prüfungssimulation'
                description='Hier kannst du mit den offiziellen Prüfungsbögen in einem echten Prüfungsszenario lernen.'
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardWrapper>
  );
};

export default SBFBSDashboard;

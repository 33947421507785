import { HttpResponse } from '@capacitor/core';
import { isAxiosError } from 'axios';

import ApiError from '../api/interfaces/api-error';

export function makeApiError(err: unknown): ApiError {
  if (isAxiosError(err)) {
    return {
      statusCode: err.response?.status ?? -1,
      statusMessage: err.response?.data.message,
      retryAfter: err.response?.headers['ratelimit-reset'],
    };
  } else if (isHttpResponse(err)) {
    return {
      statusCode: err.status,
      statusMessage: err.data.message,
      retryAfter: Number(err.headers['ratelimit-reset']),
    };
  }

  return {
    statusCode: -1,
    statusMessage: 'Unknown error',
    errorObject: err,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isHttpResponse(err: any): err is HttpResponse {
  return err['data'] !== undefined && err['headers'] !== undefined && err['status'] !== undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiError(err: any): err is ApiError {
  return err['statusCode'] !== undefined;
}

import { QuestionType, QuizQuestion } from '../api/interfaces/quiz';

export const isAnswerCorrect = (question: QuizQuestion): boolean => {
  if (question.type === QuestionType.TF) {
    return question.textQuestionAnswer
      ? question.textQuestionAnswer.user_points === question.textQuestionAnswer.total_points
      : false;
  } else {
    if (question.choice_answers) {
      return question.choice_answers.every((a) =>
        question.checkedAnswers?.includes(a.id) ? a.correct : !a.correct
      );
    }
  }

  return false;
};

export const isAnswerPartiallyCorrect = (question: QuizQuestion): boolean => {
  if (
    question.type === QuestionType.TF &&
    question.textQuestionAnswer &&
    question.textQuestionAnswer.user_points > 0 &&
    question.textQuestionAnswer.user_points < question.textQuestionAnswer.total_points
  ) {
    return true;
  } else {
    return false;
  }
};

import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { Avatar } from '../interfaces/account-info';

export const getAvatars = async () => {
  const res = await makeApiRequest<Avatar[]>('GET', 'v1/account/avatar');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const setAvatar = async (avatarId: number) => {
  const res = await makeApiRequest('POST', `v1/account/avatar/${avatarId}`);

  if (isApiError(res)) {
    return res;
  }

  return true;
};

import Code from '../api/interfaces/code';

export interface AddressData {
  street: string;
  house_nbr: string;
  zip: string;
  place: string;
  country_code: string;
}

export interface ContactData extends AddressData {
  phone_number: string;
}

export interface PersonalData {
  nationality_code: string;
  date_of_birth: Date | null;
  birth_place: string;
  sex: 'm' | 'w' | 'd' | null;
  has_driver_license?: boolean;
}

export interface RegistrationData extends PersonalData, ContactData {
  code: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export type TRegistrationData = keyof RegistrationData;

/**
 * A type which reflects the structure of a RegistrationDataAction.
 */
export type RegistrationDataAction =
  | { type: 'SET_DATA_KEY'; key: TRegistrationData; value: RegistrationData[TRegistrationData] }
  | { type: 'SET_CODE'; code: Code }
  | { type: 'SET_REGISTRATION_DATA'; registrationData: RegistrationData }
  | { type: 'SET_CONTACT_DATA'; contactData: ContactData }
  | { type: 'SET_PERSONAL_DATA'; personalData: PersonalData }
  | { type: 'RESET' };

export type RegistrationDataStateType = {
  code: Code | null;
  data: RegistrationData;
};

/**
 * Reducer for the registration data.
 *
 * @param state The current state of the registration data
 * @param action The action to perform
 * @returns The new state of the registration data
 */
export const registrationDataReducer = (
  state: RegistrationDataStateType,
  action: RegistrationDataAction
): RegistrationDataStateType => {
  switch (action.type) {
    case 'SET_DATA_KEY':
      return {
        ...state,
        data: { ...state.data, [action.key]: action.value },
      };
    case 'SET_CODE':
      return {
        code: action.code,
        data: { ...state.data, code: action.code.code },
      };
    case 'SET_REGISTRATION_DATA':
      return { ...state, data: action.registrationData };
    case 'SET_CONTACT_DATA':
      return { ...state, data: { ...state.data, ...action.contactData } };
    case 'SET_PERSONAL_DATA':
      return { ...state, data: { ...state.data, ...action.personalData } };
    case 'RESET':
      return {
        code: null,
        data: {
          code: '',
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          street: '',
          house_nbr: '',
          zip: '',
          place: '',
          country_code: '',
          nationality_code: '',
          date_of_birth: new Date(),
          birth_place: '',
          sex: 'm',
          phone_number: '',
          has_driver_license: false,
        },
      };
    default:
      return state;
  }
};

import { useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router';

import { getPracticalTrainings } from '../../../../api/courses/practical-trainings';
import PracticalTrainingCategory, {
  PracticalTraining as IPracticalTraining,
} from '../../../../api/interfaces/practical-training';
import B1Button from '../../../../components/buttons/B1Button';
import VideoPlayer from '../../../../components/video-player/VideoPlayer';
import { isApiError } from '../../../../utils/api-util';
import { getMainCourse } from '../../../../utils/course-util';
import Layout from '../../../Layout';

import './PracticalTraining.scss';

interface TrainingBodyProps {
  training: IPracticalTraining;
  defaultActive: boolean;
}

const TrainingBody: React.FC<TrainingBodyProps> = ({ training, defaultActive }) => {
  const [playing, setPlaying] = React.useState<boolean>();
  const [opened, setOpened] = React.useState<boolean>(defaultActive);

  return (
    <Accordion.Body onExiting={() => setPlaying(false)} onEnter={() => setOpened(true)}>
      {training.video &&
        (opened ? (
          <VideoPlayer
            video={training.video}
            play={playing}
            onPlayingStateChange={(state) => setPlaying(state)}
          />
        ) : (
          <Ratio aspectRatio='16x9'>
            <div style={{ backgroundColor: '#000' }}></div>
          </Ratio>
        ))}
      {training.text && <p>{training.text}</p>}
    </Accordion.Body>
  );
};

const PracticalTraining: React.FC = () => {
  const { identifier } = useParams<{ identifier: string }>();
  const [practicalTrainings, setPracticalTrainings] = React.useState<PracticalTrainingCategory[]>(
    []
  );

  useIonViewWillEnter(() => {
    getPracticalTrainings(identifier.toUpperCase()).then((data) => {
      if (isApiError(data)) {
        console.error(data);
      } else {
        setPracticalTrainings(data);
      }
    });
  }, [identifier]);

  const defaultActiveKey =
    practicalTrainings.length > 0 && practicalTrainings[0].trainings.length > 0
      ? String(practicalTrainings[0].trainings[0].id)
      : null;

  return (
    <Layout
      backButton={`/course/${getMainCourse(identifier)}`}
      contentClassName='practical-training'
      currentCourseType={identifier}
    >
      <div className='practical-training-container'>
        {practicalTrainings?.length > 0 && practicalTrainings[0].trainings.length > 0 && (
          <Accordion className='b1-accordion' defaultActiveKey={defaultActiveKey}>
            {practicalTrainings.map((practicalTraining, practicalTrainingId) => (
              <div key={practicalTrainingId} className='practical-training-item'>
                <h1>{practicalTraining.title}</h1>
                {practicalTraining.trainings.map((training, trainingId) => (
                  <Accordion.Item key={trainingId} eventKey={String(training.id)}>
                    <Accordion.Header>{training.title}</Accordion.Header>
                    <TrainingBody
                      training={training}
                      defaultActive={defaultActiveKey === String(training.id)}
                    />
                  </Accordion.Item>
                ))}
                {practicalTrainings.length - 1 > practicalTrainingId && <hr />}
              </div>
            ))}
          </Accordion>
        )}
        <Row className='buttons gy-3'>
          <Col xs='12' lg='6'>
            <B1Button className='button-block button-reverse' href='/course/sbf'>
              Zurück
            </B1Button>
          </Col>
          <Col xs='12' lg='6'>
            <B1Button className='button-block' href={`/course/${identifier}/exam-booking`}>
              Termine buchen
            </B1Button>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default PracticalTraining;

import React, { FormEvent, useState } from 'react';

import { sendFeedback } from '../../../api/support';
import { isApiError } from '../../../utils/api-util';
import B1Button from '../../buttons/B1Button';
import B1Textarea from '../../forms/B1Textarea';
import { FeedbackClass } from '../../forms/forms';

interface FeedbackFormProps {
  positiveRating?: boolean;
  onFeedbackSent: () => void;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ positiveRating, onFeedbackSent }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackTextFeedback, setFeedbackTextFeedback] = useState('');
  const [feedbackTextFeedbackClass, setFeedbackTextFeedbackClass] = useState<FeedbackClass>();

  const handleSendFeedback = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!feedbackText) {
      setFeedbackTextFeedback('Bitte gib Dein Feedback ein.');
      setFeedbackTextFeedbackClass('warning');
      return;
    }

    setFeedbackTextFeedback('');
    setFeedbackTextFeedbackClass(undefined);

    sendFeedback(feedbackText, positiveRating).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setFeedbackText('');

      onFeedbackSent();
    });
  };

  return (
    <form className='b1-form' onSubmit={handleSendFeedback}>
      <B1Textarea
        id='feedback'
        label='Schreibe uns Dein Feedback'
        placeholder='Teile uns hier Deine Ideen, Wünsche und Kritik mit.'
        value={feedbackText}
        onInput={(e) => {
          const text = (e.target as HTMLInputElement).value;

          setFeedbackText(text);

          if (text) {
            setFeedbackTextFeedback('');
            setFeedbackTextFeedbackClass(undefined);
          }
        }}
        feedback={feedbackTextFeedback}
        feedbackClass={feedbackTextFeedbackClass}
        rows={5}
      />
      <B1Button type='submit' className='button-block'>
        Absenden
      </B1Button>
    </form>
  );
};

export default FeedbackForm;

import React, { FormEvent, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {
  PASSWORD_CRITERIA,
  getPasswordSecurityBarColor,
  getPasswordSecurityBarLength,
} from '../../utils/password-util';
import B1Button from '../buttons/B1Button';
import B1Input from '../forms/B1Input';
import { FeedbackClass } from '../forms/forms';

import './PasswordForm.scss';

interface PasswordFormProps {
  email?: string;
  onSubmit: (password: string, oldPassword: string) => boolean | Promise<boolean>;
  changePassword?: boolean;
  feedback?: string;
  feedbackClass?: FeedbackClass;
}

const PasswordForm: React.FC<PasswordFormProps> = ({
  email,
  onSubmit,
  changePassword,
  feedback,
  feedbackClass,
}) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [passwordSecurity, setPasswordSecurity] = useState([...PASSWORD_CRITERIA]);
  const [passwordFeedback, setPasswordFeedback] = useState('');

  function inputPassword(password: string, repetition: boolean) {
    setPasswordFeedback('');

    if (!repetition) {
      setPassword(password);

      for (const criteria of passwordSecurity) {
        criteria.fulfilled = criteria.regex.test(password);
      }

      setPasswordSecurity(passwordSecurity);
    } else {
      setPassword2(password);
    }
  }

  async function submitPassword(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (passwordSecurity.every((c) => c.fulfilled)) {
      if (password === password2) {
        setPasswordFeedback('');

        const result = await onSubmit(password, oldPassword);

        if (result) {
          setPassword('');
          setPassword2('');
          setOldPassword('');
          setPasswordFeedback('');

          for (const criteria of passwordSecurity) {
            criteria.fulfilled = false;
          }
          setPasswordSecurity(passwordSecurity);
        }
      } else {
        setPasswordFeedback('Die Passwörter stimmen nicht überein.');
      }
    } else {
      setPasswordFeedback('Das Passwort erfüllt nicht die Mindestkriterien.');
    }
  }

  return (
    <form className='password-form b1-form' onSubmit={submitPassword}>
      {email && (
        <input
          readOnly={true}
          type='text'
          autoComplete='username'
          defaultValue={email}
          title='E-Mail-Adresse'
          style={{ display: 'none' }}
        />
      )}
      {changePassword && (
        <B1Input
          id='old-password'
          type='password'
          placeholder='Bitte altes Passwort eingeben'
          autoComplete='current-password'
          label='Altes Passwort'
          value={oldPassword}
          onInput={(e) => setOldPassword((e.target as HTMLInputElement).value)}
          feedbackClass={passwordFeedback ? 'warning' : undefined}
        />
      )}
      <B1Input
        id='new-password'
        type='password'
        placeholder={`Bitte${changePassword ? ' neues' : ''} Passwort eingeben`}
        autoComplete='new-password'
        label={`${changePassword ? 'Neues ' : ''}Passwort`}
        value={password}
        onInput={(e) => inputPassword((e.target as HTMLInputElement).value, false)}
        feedbackClass={passwordFeedback ? 'warning' : undefined}
      ></B1Input>
      <B1Input
        id='repeat-password'
        type='password'
        placeholder={`Bitte${changePassword ? ' neues' : ''} Passwort wiederholen`}
        autoComplete='new-password'
        label={`${changePassword ? 'Neues ' : ''}Passwort wiederholen`}
        value={password2}
        onInput={(e) => inputPassword((e.target as HTMLInputElement).value, true)}
        feedback={passwordFeedback}
        feedbackClass={passwordFeedback ? 'warning' : undefined}
      ></B1Input>
      <div className='password-security-bar-container'>
        <div
          style={{
            width: getPasswordSecurityBarLength(passwordSecurity),
            backgroundColor: getPasswordSecurityBarColor(passwordSecurity),
          }}
        ></div>
      </div>
      <Row className='password-security-criteria'>
        {passwordSecurity.map((criteria, index) => (
          <Col key={index} md='3' xs='6'>
            <div className='criterion'>
              <img src={`assets/icons/check-icon-${criteria.fulfilled ? 'green' : 'white'}.svg`} />
              <span>{criteria.name}</span>
            </div>
          </Col>
        ))}
      </Row>
      {feedback && (
        <span className={`password-feedback${feedbackClass ? ' ' + feedbackClass : ''}`}>
          {feedback}
        </span>
      )}
      <B1Button type='submit' className='button-block'>
        Bestätigen
      </B1Button>
    </form>
  );
};

export default PasswordForm;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { matchPath } from 'react-router';

import { RouteInfo } from '@ionic/react';

export const matchRoute = (
  node: React.ReactNode,
  routeInfo: RouteInfo
): (React.ReactNode & { props?: any }) | null | undefined => {
  let matchedNode: React.ReactNode;
  React.Children.forEach(node as React.ReactElement, (child: React.ReactElement) => {
    const matchProps = {
      exact: child.props.exact,
      path: child.props.path || child.props.from,
      component: child.props.component,
    };
    const match = matchPath(routeInfo.pathname, matchProps);
    if (match) {
      matchedNode = child;
    }
  });

  if (matchedNode) {
    return matchedNode;
  }
  // If we haven't found a node
  // try to find one that doesn't have a path or from prop, that will be our not found route
  React.Children.forEach(node as React.ReactElement, (child: React.ReactElement) => {
    if (!(child.props.path || child.props.from)) {
      matchedNode = child;
    }
  });

  return matchedNode;
};

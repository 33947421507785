import React, { PropsWithChildren, useReducer } from 'react';

import {
  RegistrationDataDispatchContext,
  RegistrationDataStateContext,
  initialRegistrationDataState,
} from '../contexts/registration-data-context';
import { registrationDataReducer } from '../reducers/registration-data-reducer';

const RegistrationDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(registrationDataReducer, initialRegistrationDataState);

  return (
    <RegistrationDataStateContext.Provider value={state}>
      <RegistrationDataDispatchContext.Provider value={dispatch}>
        {children}
      </RegistrationDataDispatchContext.Provider>
    </RegistrationDataStateContext.Provider>
  );
};

export default RegistrationDataProvider;

import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import React, { PropsWithChildren, StyleHTMLAttributes } from 'react';

interface CustomLinkProps extends PropsWithChildren, StyleHTMLAttributes<HTMLAnchorElement> {
  href: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({ href, onClick, children, ...props }) => {
  const handleClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (Capacitor.isNativePlatform()) {
      e.preventDefault();

      const deviceInfo = await Device.getInfo();

      Browser.open({
        url: href,
        windowName: '_blank',
        presentationStyle: deviceInfo.model.includes('iPad') ? 'fullscreen' : 'popover',
      });
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <a href={href} onClick={handleClick} target='_blank' rel='noreferrer' {...props}>
      {children}
    </a>
  );
};

export default CustomLink;
